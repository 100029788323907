import React, { useEffect, useMemo, memo, useRef } from "react";
import { CSVLink } from "react-csv";
import Helmet from "react-helmet";
import { AgingInvoicesState } from "../../../slices";
import UserState from "../../../slices/user/UserState";
import { useSelector, useDispatch } from "react-redux";
import { userSelector } from "../../../slices/user/userSlice";
import Table from "../../../ui-components/table/table";
import ContentContainer from "../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import { useParams, useHistory } from "react-router-dom";
import { PathParams, AgingInvoicesPath } from "./route";
import Invoice, { InvoiceFilterModel } from "../../../app/data/invoice/models";
import { Routes } from "../../../app/route/RoutesConfig";
import getAgingInvoicesColumns from "./agingInvoicesColumns";
import { getAging } from "../../../slices/aging/agingSlice";
import {
  getAgingInvoices,
  getAgingInvoicesForCSV,
  agingInvoicesSelector,
} from "../../../slices/aging/agingInvoicesSlice";
import InvoicePayment from "../invoice-payment/invoicePayment";
import AgingInvoicesHeader from "./agingInvoicesHeader";
import AgingInvoicesSelectedBar from "./agingInvoicesSelectedBar";
import { SortOrder, SortParams } from "../../../app/data/common/models";
import DownloadButton from "../../../ui-components/download-button/downloadButton";
import { agingPeriods } from "../../../app/data/aging/models";

import "./agingInvoices.scss";

const AgingInvoices: React.FC<{}> = memo((props) => {
  const userState: UserState = useSelector(userSelector);
  const agingInvoicesState: AgingInvoicesState = useSelector(agingInvoicesSelector);
  const dispatch = useDispatch();
  const params = useParams() as PathParams;
  const history = useHistory();
  const appliedFilter = useRef<InvoiceFilterModel | null>(null);
  const sortingRef = useRef<SortParams | null>(null);
  
  const agingPeriod = useMemo(() => {
    return params[AgingInvoicesPath.period];
  }, [params]);

  const csvLinkRef = React.useRef<any>();

  let clearSelection: (() => void) | null = null;

  const assignClearSelection = (f: () => void) => {
    clearSelection = f;
  };

  useEffect(() => {     
    appliedFilter.current = null;
  }, [ agingPeriod ]);

  useEffect(() => {
    dispatch(getAging(userState.activeSubAccount?.id));
    dispatch(getAgingInvoices(userState.activeSubAccount?.id, agingPeriod, appliedFilter.current));
    clearSelection?.();
  }, [
    dispatch,
    userState.activeSubAccount,
    agingPeriod,
    agingInvoicesState.needToReload,
    clearSelection,
  ]);

  const onRowClick = (row: Invoice) => {
    history.push(getInvoiceDetailsUrl(row));
  };

  const getInvoiceDetailsUrl = (row: Invoice) => {
    return `/${userState.activeSubAccount?.accountNumber}${Routes.invoices}/${row.invoiceNumber}`;
  };

  const csvHeaders = [
    { label: "Invoice Number", key: "invoiceNumber" },
    { label: "BOL Number", key: "bolNumber" },
    { label: "PO Number", key: "poNumber" },
    { label: "Invoice Date", key: "dateInvoice" },
    { label: "Due Date", key: "dateDue" },
    { label: "Invoice Amount", key: "invoiceAmount" },
    { label: "Open Amount", key: "openAmount" }
  ];

  const fetchCSV = () => {
    dispatch(getAgingInvoicesForCSV(userState.activeSubAccount?.id, agingPeriod, downloadCSV));
  };

  const preparedCSV = () => {
    return (agingInvoicesState.invoicesCSV.length > 0)
      ? agingInvoicesState.invoicesCSV.filter(invoice => !invoice.paid).map(({ subAccountId, paid, ...csvColumns }) => csvColumns)
      : [];
  }

  const downloadCSV = () => {
    if (csvLinkRef?.current) {
      csvLinkRef.current.link.click();
    }
  }

  const searchInvoices = (filter: InvoiceFilterModel) => {
    dispatch(getAgingInvoices(userState.activeSubAccount?.id, agingPeriod, filter, null ));
    appliedFilter.current = filter;
    sortingRef.current = null;
    clearSelection?.();
  };

  const onInfiniteScroll = () => {
    dispatch(getAgingInvoices(userState.activeSubAccount?.id, agingPeriod, appliedFilter.current, sortingRef.current, agingInvoicesState.lastIds || undefined, true));
  };

  const renderInvoicesBar = (rows: any[], toggleAllRowsSelected: (selected: boolean) => void) => {
    const invoiceNumbers = rows.map(row => row.original.invoiceNumber);
    return <AgingInvoicesSelectedBar selectedInvoices={invoiceNumbers} onReset={() => {toggleAllRowsSelected(false)}}/>
  }

  const columns = getAgingInvoicesColumns(getInvoiceDetailsUrl);

  const onSortByColumn = (index: number, order: SortOrder) => {
    const sortBy = columns[index].id;
    
    if (!sortBy) return;

    sortingRef.current = { sortBy, order };
    dispatch(getAgingInvoices(userState.activeSubAccount?.id, agingPeriod, appliedFilter.current, { sortBy, order }));
  }

  return (
    <ContentContainer
      className="xgs-list xgs-list--full-width"
      mods={{ "full-width": true }}
      titleComponent={
        <ContentContainerToolbar
          pageId={`aging-invoices${agingPeriod}`}
          title={`Aging Invoices (${Object.values(agingPeriods).find((item: any) => item.period === agingPeriod)?.title})`}
          mods={{ "full-width": true }}
        >
          <>
            <Helmet title="Invoices" />

            <DownloadButton
              title="Download CSV"
              spinner={agingInvoicesState.loadingCSV}
              onClick={fetchCSV}
            />

            <CSVLink
              ref={csvLinkRef}
              filename={`invoices_${userState.activeSubAccount?.accountNumber}${agingPeriod}.csv`}
              headers={csvHeaders}
              style={{ display: "none" }}
              data={preparedCSV()}
              />
          </>
        </ContentContainerToolbar>
      }
    >
      <div className="xgs-aging-invoices">
        <AgingInvoicesHeader agingPeriod={agingPeriod} onSearch={searchInvoices}/>        
        <Table
          key={agingPeriod}
          isLoading={agingInvoicesState.loading}
          columns={columns}
          data={agingInvoicesState.invoices}
          onRowClicked={onRowClick}
          ignoreCellClick="selection"
          cursorPointer={true}
          infiniteScroll={true}
          infiniteScrollLoading={agingInvoicesState.loadingPortion}
          infiniteScrollHasNext={!agingInvoicesState.loadedAll}
          onInfiniteScroll={onInfiniteScroll}
          enableRowSelection={(row) => !row.original.paid}
          renderSelectedBar={renderInvoicesBar}
          responsive
          sorting={true}
          onSortByColumn={onSortByColumn}
          clearSelection={assignClearSelection}
        />
      </div>
      <InvoicePayment />
    </ContentContainer>
  );
});

export default AgingInvoices;
