import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { getUnitTag } from "./getUnitTag";

const columnHelper = createColumnHelper<any>();

export const customerInventoryColumns = [
  columnHelper.accessor("sku", {
    id: "sku",
    header: "SKU #",
    cell: (cellProps) => (
      <div className="xgs-warehouse-summary__item-cell">
        <span><b>{cellProps.row.original.sku}</b></span>
      </div>
    ),
    size: 162,
  }),
  columnHelper.accessor("description", {
    id: "description",
    header: "Item Description",
    cell: (cellProps) => (
      <span>{cellProps.row.original.description}</span>
    ),
    size: 268,
  }),
  columnHelper.accessor("unitOfMeasure", {
    id: "unitOfMeasure",
    header: "Measure Unit",
    cell: (cellProps) => (
      getUnitTag(cellProps.getValue())
    ),
    size: 108,
  }),
  columnHelper.accessor("qtyAvailableToOrder", {
    id: "qtyAvailableToOrder",
    header: "Quantity",
    size: 90
  }),
  columnHelper.accessor("rollNumber", {
    id: "rollNumber",
    header: "Roll #",
    size: 110
  }),
  columnHelper.accessor("palletLpNumber", {
    id: "palletLpNumber",
    header: "Pallet #",
    size: 110
  }),
  columnHelper.accessor("subPart1Number", {
    id: "subPart1Number",
    header: "Sub Part 1",
    size: 112
  }),
  columnHelper.accessor("subPart2Number", {
    id: "subPart2Number",
    header: "Sub Part 2",
    size: 112
  }),
  columnHelper.accessor("terminal", {
    id: "terminal",
    header: "Terminal",
    cell: (cellProps) => (
      <div className="xgs-lmi__trips__address-cell">
        {cellProps.row.original.locationAirportCode} - {cellProps.row.original.locationTerminalName} <span style={{ display: "block", float: "right", marginRight: 10 }}>{cellProps.row.original.locationTerminalNumber}</span>
      </div>
    ),
    size: 216,
  })
];
