import React from "react";
// import { useSelector } from "react-redux";
import XGSPopup from "../../ui-components/xgs-popup/xgsPopup";
// import { userSelector } from "../../slices/user/userSlice";
// import UserState from "../../slices";
import "./contactUs.scss";

export interface ContactUsProps {
  onClose: () => void;
  showPopup: boolean;
}

const ContactUs: React.FC<ContactUsProps> = (props) => {  
  // const userState: UserState = useSelector(userSelector);

  return (
    <XGSPopup
      title="Contact Us"
      opened={props.showPopup}
      onClose={props.onClose}
      className="xgs-contact-us__popup"
    >
      <>
        {/*{userState.profile?.salesManager && <p className="xgs-contact-us__representative">Your sales representative is {userState.profile.salesManager}</p>}*/}
        <div className="xgs-contact-us__columns">
          <div className="xgs-contact-us__column">
            <div className="xgs-contact-us__header">GENERAL INQUIRIES &amp; INFORMATION CHANGE REQUESTS</div>
            <div className="xgs-contact-us__text">
              Monday – Friday, 8:00am – 5:00pm EST<br />
              (844) 947-7447<br />
            </div>
            <a href="mailto:xgscustomerservice@xgsi.com" className="xgs-contact-us__link">xgscustomerservice@xgsi.com</a>

            <div className="xgs-contact-us__header">FREIGHT QUOTES</div>
            <a href="mailto:ae@xgsi.com" className="xgs-contact-us__link">ae@xgsi.com</a>

            <div className="xgs-contact-us__header">PICKUP REQUESTS</div>
            <a href="mailto:xgspickups@xgsi.com" className="xgs-contact-us__link">xgspickups@xgsi.com</a>

            <div className="xgs-contact-us__header">EXPEDITE SHIPPING REQUESTS</div>
            <a href="mailto:xgx@xgsi.com" className="xgs-contact-us__link">xgx@xgsi.com</a>
          </div>
          <div className="xgs-contact-us__column">
            <div className="xgs-contact-us__header">SALES INQUIRIES</div>
            <a href="mailto:xgssales@xgsi.com" className="xgs-contact-us__link">xgssales@xgsi.com</a>

            <div className="xgs-contact-us__header">BILLING INQUIRIES</div>
            <a href="mailto:xgsbilling@xgsi.com" className="xgs-contact-us__link">xgsbilling@xgsi.com</a>

            <div className="xgs-contact-us__header">DAY DEFINITE SHIPMENT INFO REQUESTS</div>
            <a href="mailto:xgsdaydefinite@xgsi.com" className="xgs-contact-us__link">xgsdaydefinite@xgsi.com</a>

            <div className="xgs-contact-us__header">FREIGHT RETURN REQUESTS</div>
            <a href="mailto:xgsreturns@xgsi.com" className="xgs-contact-us__link">xgsreturns@xgsi.com</a>
          </div>
        </div>
        <div className="xgs-contact-us__feedback">
          If you would like to provide feedback or request a new feature, please use the Feedback in the upper right corner of the portal.          
        </div>
      </>
    </XGSPopup>
  );
};

export default ContactUs;
