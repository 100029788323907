import React from "react";
import Modal from "react-modal";
import Button, { ButtonThemes } from "../button/button";
import { confirmationModalStyle, modalStyle } from "../../app/data/common/modalStyle"
import "../../sass/modal.scss";

Modal.setAppElement("#root");

export interface ConfirmationModalProps {
  opened: boolean;
  spinner?: boolean;
  fitContent?: boolean;
  header?: string | JSX.Element;
  cancelButtonText?: string;
  confirmButtonText?: string;
  disableConfirmButton?: boolean;
  className?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
  return (
    <Modal
      isOpen={props.opened}
      style={props.fitContent ? confirmationModalStyle : modalStyle}
      className={props.className}
    >
      {props.header && (
        <div className="xgs-modal__header">
          {props.header}
        </div>
      )}    
      <div className="xgs-modal__content">
        {props.children}
      </div>
      <div className="xgs-modal__buttons" style={{ textAlign: "center" }}>
        {props.onCancel && (
          <Button
            theme={ButtonThemes.blue}
            onClick={props.onCancel}
            className="xgs-modal__button"
            type="button"
          >
            {props.cancelButtonText || "Cancel"}
          </Button>
        )}
        {props.onConfirm && (
          <Button
            theme={props.onCancel ? ButtonThemes.gray : ButtonThemes.blue}
            onClick={props.onConfirm}
            spinner={props.spinner}
            className="xgs-modal__button"
            type="button"
            disabled={props.disableConfirmButton}
          >
            {props.confirmButtonText || "Confirm"}
          </Button>
        )}
      </div>
    </Modal>
  );
};


export default ConfirmationModal;
