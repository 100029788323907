import React from "react";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<any>();

export const getLaneColumns = (assignedLaneId?: string | null, disableSelect?: boolean) => [
  ...disableSelect ? [] : [columnHelper.display({
    id: "select",
    header: "",
    cell: (cellProps) => {
      return (
        <div style={{ textAlign: "center" }}>
          <input
            type="radio"
            name="contactSelect"
            checked={cellProps.row.original.laneId === assignedLaneId}
            className="xgs-contact-card__radio"
          />
        </div>
      )
    },
    size: 40,
  })],
  columnHelper.accessor("laneName", {
    id: "laneName",
    header: "Lane",
    size: 152,
  }),
  columnHelper.accessor("miles", {
    id: "miles",
    header: "Miles",
    size: 90,
  }),
  columnHelper.accessor("laneNotes", {
    id: "laneNotes",
    header: "Lane Description",
    size: 228,
  }),
  columnHelper.accessor("relayCity", {
    id: "relayCity",
    header: "Relay Location",
    cell: (cellProps) => (
      <>
        {
          [cellProps.row.original.relayCity, cellProps.row.original.relayState, cellProps.row.original.relayZipcode]
            .filter(val => !!val)
            .join(", ")
        }
      </>
    ),
    size: 260,
  })
];