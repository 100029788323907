import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import { AppThunk } from "../../app/store";
import TrackingService from "../../app/data/tracking/trackingService";
import { initialShipmentDetailsState } from "./ShipmentDetailsState";

const trackingService = TrackingService.getInstance();

export const shipmentDetailsSlice = createSlice({
  name: "shipmentDetails",
  initialState: initialShipmentDetailsState,
  reducers: {
    resetShipmentDetails: (state) => initialShipmentDetailsState,
    startLoading: (state) => {
      state.loading = true;
      state.loaded = false;
      state.needToReload = false;
      state.loadingFailed = false;
    },
    setShipmentDetails: (state, { payload }) => {
      state.shipment = payload;
      state.loading = false;
      state.loaded = true;
      state.loadingFailed = false;
    },
    loadingShipmentDetails_has_failed: (state, { payload }) => {
      state.loading = false;
      state.loaded = false;
      state.loadingFailed = true;
      state.error = payload;
    },
    reloadShipmentDetails: (state) => {
      if (state.loaded) {
        state.needToReload = true;
      }
    },
    startDockLogLoading: (state) => {
      state.dockLogLoading = true;
      state.dockLogLoaded = false;
      state.dockLogLoadingFailed = false;
    },
    setDockLog: (state, { payload }) => {
      state.dockLogLoading = false;
      state.dockLogLoaded = true;
      state.dockLogLoadingFailed = false;
      state.dockLog = payload;
    },
    dockLogLoading_has_failed: (state, { payload }) => {
      state.dockLogLoading = false;
      state.dockLogLoaded = false;
      state.dockLogLoadingFailed = true;
      state.dockLogLoadingError = payload;
    },
    clearDockLog: (state) => {
      state.dockLog = null;
      state.dockLogLoaded = false;
    },
    startStatusHistoryLoading: (state) => {
      state.statusHistoryLoading = true;
      state.statusHistoryLoaded = false;
      state.statusHistoryLoadingFailed = false;
    },
    setStatusHistory: (state, { payload }) => {
      state.statusHistoryLoading = false;
      state.statusHistoryLoaded = true;
      state.statusHistoryLoadingFailed = false;
      state.statusHistory = payload;
    },
    statusHistoryLoading_has_failed: (state, { payload }) => {
      state.statusHistoryLoading = false;
      state.statusHistoryLoaded = false;
      state.statusHistoryLoadingFailed = true;
      state.statusHistoryLoadingError = payload;
    },
    clearStatusHistory: (state) => {
      state.statusHistory = null;
      state.statusHistoryLoaded = false;
    },
    statusHistoryCheckStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
    },
    statusHistoryCheckSucceed: (state, { payload }) => {
      state.loading = false;
      state.loaded = true;
      state.statusHistoryPresent = payload;
    },
    statusHistoryCheckFailed: (state, { payload }) => {
      state.loading = false;
      state.loadingFailed = true;
      state.loadingError = payload;
    },
    setShipmentSubscribeStatus: (state, { payload }) => {
      if (state.shipment) {
        state.shipment.userSubscribed = payload;
      }
    },
    startClaimsDocumentsLoading: (state) => {
      state.claimsDocumentsLoading = true;
      state.claimsDocumentsLoaded = false;
      state.claimsDocumentsLoadingFailed = false;
    },
    setClaimsDocuments: (state, { payload }) => {
      state.claimsDocumentsLoading = false;
      state.claimsDocumentsLoaded = true;
      state.claimsDocumentsLoadingFailed = false;
      state.claimsDocuments = payload;
    },
    claimsDocumentsLoading_has_failed: (state, { payload }) => {
      state.claimsDocumentsLoading = false;
      state.claimsDocumentsLoaded = false;
      state.claimsDocumentsLoadingFailed = true;
      state.claimsDocumentsLoadingError = payload;
    },
    clearClaimsDocuments: (state) => {
      state.claimsDocuments = null;
      state.claimsDocumentsLoaded = false;
    },
    startAddingComment: (state, { payload }) => {
      state.addingComment = payload;
    },
    addCommentLocally: (state, { payload }) => {
      if (!state.shipment) return;
      const originalNotes = state.shipment.notes || [];
      const updatedNotes = [{
        type: "G",
        typeDescription: "General",
        text: payload,
        dateAdded: moment.utc().tz("America/New_York").format("YYYY-MM-DD"),
        timeAdded: moment.utc().tz("America/New_York").format("hh:mm")
      }, ...originalNotes];
      state.shipment.notes = updatedNotes;
    },
    trackingHistoryStarted: (state) => {
      state.trackingHistoryLoading = true;
      state.trackingHistoryFailed = false;
      state.trackingHistoryLoaded = false;
    },
    trackingHistorySucceed: (state) => {
      state.trackingHistoryLoading = false;
      state.trackingHistoryLoaded = true;
      state.trackingHistoryFailed = false;
    },
    trackingHistoryFailed: (state, { payload }) => {
      state.trackingHistoryLoading = false;
      state.trackingHistoryFailed = true;
      state.trackingHistoryError = payload;
    },
    setTrackingHistory: (state, { payload }) => {
      state.trackingHistory = payload;
    }
  }
});

export const {
  resetShipmentDetails,
  startLoading,
  setShipmentDetails,
  loadingShipmentDetails_has_failed,
  reloadShipmentDetails,
  startDockLogLoading,
  setDockLog,
  dockLogLoading_has_failed,
  clearDockLog,
  startStatusHistoryLoading,
  setStatusHistory,
  statusHistoryLoading_has_failed,
  clearStatusHistory,
  statusHistoryCheckStarted,
  statusHistoryCheckSucceed,
  statusHistoryCheckFailed,
  setShipmentSubscribeStatus,
  startClaimsDocumentsLoading,
  setClaimsDocuments,
  claimsDocumentsLoading_has_failed,
  clearClaimsDocuments,
  startAddingComment,
  addCommentLocally,
  trackingHistoryStarted,
  trackingHistorySucceed,
  trackingHistoryFailed,
  setTrackingHistory
} = shipmentDetailsSlice.actions;

export const shipmentDetailsSelector = (state: IState) => state.shipmentDetails;

export const getShipmentDetails = (
  invoiceNumber: number
): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  const response = await trackingService.getShipmentDetails(invoiceNumber);
  if (response.ok()) {
    dispatch(setShipmentDetails(response.data));
  } else {
    dispatch(loadingShipmentDetails_has_failed(response.getError()));
  }
};

export const getDockLog = (
  invoiceNumber: number
): AppThunk => async (dispatch) => {
  dispatch(startDockLogLoading());
  const response = await trackingService.getDockLog(invoiceNumber);
  if (response.ok()) {
    dispatch(setDockLog(response.data));
  } else {
    dispatch(dockLogLoading_has_failed(response.getError()));
  }
};

export const getStatusHistory = (
  invoiceNumber: number
): AppThunk => async (dispatch) => {
  dispatch(startStatusHistoryLoading());
  const response = await trackingService.getStatusHistory(invoiceNumber);
  if (response.ok()) {
    dispatch(setStatusHistory(response.data));
  } else {
    dispatch(statusHistoryLoading_has_failed(response.getError()));
  }
};

export const getClaimsDocuments = (invoiceNumber: number): AppThunk => async (dispatch) => {
  dispatch(startClaimsDocumentsLoading());
  const response = await trackingService.getClaimsDocuments(invoiceNumber);
  if (response.ok()) {
    dispatch(setClaimsDocuments(response.data));
  } else {
    dispatch(claimsDocumentsLoading_has_failed(response.getError()));
  }
};

export const checkStatusHistory = (
  invoiceNumber: number
): AppThunk => async (dispatch) => {
  dispatch(statusHistoryCheckStarted());
  const response = await trackingService.checkStatusHistory(invoiceNumber);
  if (response.ok()) {
    dispatch(statusHistoryCheckSucceed(response.data.historyPresent));
  } else {
    response.getError && dispatch(statusHistoryCheckFailed(response.getError()));
  }
};

export const addComment = (
  invoiceNumber: number,
  note: string,
  onSuccess: () => void,
  onFail: (err: string | object) => void
): AppThunk => async (dispatch) => {
  dispatch(startAddingComment(true));
  const response = await trackingService.addComment(invoiceNumber, note);
  if (response.ok()) {
    dispatch(startAddingComment(false));
    dispatch(addCommentLocally(note));
    onSuccess();
  } else {
    dispatch(startAddingComment(false));
    onFail((response.getError && response.getError()) || "");
  }
};

export const getTrackingHistory = (
  probill: number
): AppThunk => async (dispatch) => {
  dispatch(trackingHistoryStarted());
  const response = await trackingService.getTrackingHistory(probill);
  if (response.ok()) {
    dispatch(trackingHistorySucceed());
    dispatch(setTrackingHistory(response.data));
  } else {
    response.getError && dispatch(trackingHistoryFailed(response.getError()));
  }
};

const shipmentDetailsReducer = shipmentDetailsSlice.reducer;
export default shipmentDetailsReducer;
