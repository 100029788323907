import React, { useEffect } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import SlideOutSidebar from "../../ui-components/slide-out-sidebar/slideOutSidebar";
import Table from "../../ui-components/table/table";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerHolidayLogs, getHolidayLogs, holidayCalendarSelector } from "../../slices/holiday-calendar/holidayCalendarSlice";

const columnHelper = createColumnHelper<any>();

const getTableColumns = () => (
  [
    columnHelper.accessor("date", {
      id: "date",
      header: "Date",
      cell: (cellProps) => <>{cellProps.getValue().toUiDateFormat()}</>,
      size: 130,
    }),
    columnHelper.accessor("action", {
      id: "action",
      header: "Action",
      cell: (cellProps) => {
        return <>{cellProps.getValue()}</>
      },
      size: 110,
    }),
    columnHelper.accessor("dateCreated", {
      id: "dateCreated",
      header: "Action Timestamp",
      cell: (cellProps) => <>{cellProps.getValue().toUiDateTimeFormat()}</>,
      size: 190,
    }),
    columnHelper.accessor("userCreated", {
      id: "userCreated",
      header: "Performed by",
      size: 210
    })
  ]
)

export interface HolidayChangeLogProps {
  show: boolean;
  accountId?: string;
  isCustomer?: boolean;
  onClose: () => void;
}

export const HolidayChangeLog: React.FC<HolidayChangeLogProps> = (props) => {
  const holidayCalendarState = useSelector(holidayCalendarSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.isCustomer) {
      let accountId = props.accountId!;
      dispatch(getCustomerHolidayLogs(accountId));
    } else {
      dispatch(getHolidayLogs());
    }
  }, [dispatch, props.show, props.isCustomer, props.accountId])

  return (
    <SlideOutSidebar onClose={props.onClose} spinner={holidayCalendarState.loadingLogs} show={props.show} header={props.isCustomer ? "Exception change log" : "Holiday change log"}>
      <div className="xgs-holiday-calendar__logs">
        <div className="xgs-holiday-calendar__logs__heading">Recent changes</div>
        <Table
          infiniteScroll
          columns={getTableColumns()}
          data={holidayCalendarState.logs}
        />
      </div>
    </SlideOutSidebar>
  )
}