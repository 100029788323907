import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { getUnitTag } from "./getUnitTag";

const columnHelper = createColumnHelper<any>();

export const lowQuanitySkuColumns = [
  columnHelper.accessor("sku", {
    id: "sku",
    header: () => <>SKU # /<br />Description</>,
    cell: (cellProps) => (
      <div className="xgs-warehouse-summary__item-cell">
        <span>{cellProps.row.original.sku}</span><br />
        {cellProps.row.original.description}
      </div>
    ),
    size: 152,
  }),
  columnHelper.accessor("terminal", {
    id: "terminal",
    header: "Warehouse",
    size: 212,
    cell: (cellProps) => (
      <div className="xgs-lmi__trips__address-cell">
        {cellProps.row.original.locationAirportCode} - {cellProps.row.original.locationTerminalName} <span>{cellProps.row.original.locationTerminalNumber}</span>
      </div>
    )
  }),
  columnHelper.accessor("unitOfMeasure", {
    id: "unitOfMeasure",
    header: "Measure Unit",
    cell: (cellProps) => (
      getUnitTag(cellProps.getValue())
    ),
    size: 104,
  }),
  columnHelper.accessor("qtyAvailableToOrder", {
    id: "qtyAvailableToOrder",
    header: "Quantity",
    size: 80
  })
]