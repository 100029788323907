import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export type EmergencyAlertStatusType = "PUBLISHED" | "UNPUBLISHED" | "DRAFT";

export type AnnouncementType = "EMPLOYEE" | "CUSTOMER";

export interface EmergencyAlertFormModel {
  header: string;
  content: string;
};

export interface EmergencyAlertStatusModel {
  status: EmergencyAlertStatusType;
};

export const EmergencyAlertSchema: Yup.ObjectSchema<EmergencyAlertFormModel> = Yup.object({
  header: Yup.string().required(validationMessages.required).max(64, "The value must be no more than 64 characters"),
  content: Yup.string().required(validationMessages.required).max(4096, "The value exceeds the maximum length"),
}).defined();


export interface EmergencyAlertRequestModel {
  header: string;
  content: string;
  status: EmergencyAlertStatusType;
};

export interface EmergencyAlertResponseModel {
  header: string;
  content: string;
  status: EmergencyAlertStatusType;
  announcementFlag: boolean;
};
