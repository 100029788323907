import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import Helmet from "react-helmet";
import { PathParams, PublicShipmentDetailsPath } from "./route";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import Loading from "../../../ui-components/loading/loading";
import { Routes } from "../../../app/route/RoutesConfig";
import { UserUtils } from "../../../app/data/user/userUtils";
import { SHIPMENT_STATUSES } from "../../../app/data/common/ShipmentStatuses";
import SteppedProgress from "../../../ui-components/stepped-progress/steppedProgress";
import TrailerLocation from "../../trailer-location/trailerLocation";
import {
  userSelector,
  getCurrentUser
} from "../../../slices/user/userSlice";
import UserState from "../../../slices/user/UserState";
import PublicShipmentDetailsState from "../../../slices/public-shipment-details/PublicShipmentDetailsState";
import {
  publicShipmentDetailsSelector,
  getPublicShipmentDetails
} from "../../../slices/public-shipment-details/publicShipmentDetailsSlice";
import Exclamation, {
  ExclamationTypes,
} from "../../../ui-components/molecules/exclamation/exclamation";
import { PublicShipmentDetailsModel } from "../../../app/data/public-shipment-details/models";
import "./publicShipmentDetails.scss";

const PublicShipmentDetails: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams() as PathParams;
  const userState: UserState = useSelector(userSelector);
  const publicShipmentDetailsState: PublicShipmentDetailsState = useSelector(publicShipmentDetailsSelector);
  const [error, setError] = useState<boolean>(false);

  const probill = useMemo(() => {
    return parseInt(params[PublicShipmentDetailsPath.probill], 10);
  }, [params]);

  const isOutForDelivery = () => publicShipmentDetailsState.shipment?.status.description === SHIPMENT_STATUSES.outForDelivery;

  const isTerminalStatus = () => publicShipmentDetailsState.shipment?.status.description === SHIPMENT_STATUSES.delivered ||
    publicShipmentDetailsState.shipment?.status.description === SHIPMENT_STATUSES.warehoused;

  const mixpanelDataObj = useMemo(() => {
    return {
      "Probill": probill,
      "Public": true
    }
  }, [probill]);

  const generateProgressSteps = (shipment: PublicShipmentDetailsModel) => {
    if (!shipment.progress) return [];
    return shipment.progress.map((step, index) => {
      return {
        title: step.statusTitle,
        completed: step.completed,
        reached: step.reached
      }
    });
  };

  useEffect(() => {
    if (
      userState.getCurrentUser_is_Started ||
      userState.getCurrentUser_is_Failed ||
      userState.profile ||
      publicShipmentDetailsState.shipment ||
      error
    ) return;
    dispatch(getCurrentUser("", (profile) => {
      if (UserUtils.isEmployee(profile) || UserUtils.isCustomer(profile)) {
        history.push(`${Routes.shipments.tracking}/${probill}`);
      } else {
        dispatch(getPublicShipmentDetails(probill));
        mixpanel.track("Viewed a probill", mixpanelDataObj);
      }
    }, (statusCode: number) => {
      if (statusCode === 401) {
        dispatch(getPublicShipmentDetails(probill));
        mixpanel.track("Viewed a probill", mixpanelDataObj);
      } else {
        setError(true);
      }
    }));
  }, [probill, userState.profile, userState.getCurrentUser_is_Started, userState.getCurrentUser_is_Failed, history, dispatch, mixpanelDataObj, publicShipmentDetailsState.shipment, error]);

  return (
    <div className="xgs-public-shipment-details">
      <Helmet title="Shipment Tracking" />
      <div className="xgs-public-shipment-details__container">
        {publicShipmentDetailsState.loading && !publicShipmentDetailsState.shipment && <Loading isLoading={true} className="xgs-public-shipment-details__spinner" />}
        {publicShipmentDetailsState.loaded && publicShipmentDetailsState.shipment && (
          <div className="xgs-public-shipment-details__content">
            <div className="xgs-public-shipment-details__header">Shipment Tracking #{publicShipmentDetailsState.shipment.probill}</div>
            <div className="xgs-public-shipment-details__row">
              <div className="xgs-public-shipment-details__row__left">
                {publicShipmentDetailsState.shipment.status.translate && (
                  <div className={`xgs-public-shipment-details__status${isTerminalStatus() ? " xgs-public-shipment-details__status--terminal" : ""}`}>{publicShipmentDetailsState.shipment.status.translate}</div>
                )}
                {isOutForDelivery() && publicShipmentDetailsState.shipment.showOnMap && (
                  <div className="xgs-public-shipment-details__map-link">
                    <TrailerLocation textMode={true} invoiceNumber={publicShipmentDetailsState.shipment?.probill} />
                  </div>
                )}
                {publicShipmentDetailsState.shipment.eta && (
                  <div className="xgs-public-shipment-details__property">
                    <div className="xgs-public-shipment-details__property__label">ETA:</div>
                    <div className="xgs-public-shipment-details__property__value">{publicShipmentDetailsState.shipment.eta.expectedEta.toUiDateFormat()}</div>
                  </div>
                )}
                {publicShipmentDetailsState.shipment.eta?.notes && publicShipmentDetailsState.shipment.eta?.notes.length > 0 && (
                  <div className="xgs-form__field__notes">
                    <div className="xgs-public-shipment-details__property">
                      <div className="xgs-public-shipment-details__property__label"><strong>Note{publicShipmentDetailsState.shipment.eta.notes.length > 1 ? "s" : ""}:</strong></div>
                      <div className="xgs-public-shipment-details__property__value xgs-public-shipment-details__property__value--eta-notes">
                        {publicShipmentDetailsState.shipment.eta.notes.length === 1 && (
                          <>
                            {publicShipmentDetailsState.shipment.eta.notes[0]}
                          </>
                        )}
                        {publicShipmentDetailsState.shipment.eta.notes.length > 1 && (
                          <ul>
                            {publicShipmentDetailsState.shipment.eta.notes.map(note => (
                              <li>
                                {note}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {publicShipmentDetailsState.shipment.pod && (
                  <div className="xgs-public-shipment-details__pod">
                    <div className="xgs-public-shipment-details__property">
                      <div className="xgs-public-shipment-details__property__label">POD:</div>
                      <div className="xgs-public-shipment-details__property__value">{publicShipmentDetailsState.shipment.pod.date.toUiDateFormat()} {publicShipmentDetailsState.shipment.pod.time}</div>
                    </div>
                    <div className="xgs-public-shipment-details__property">
                      <div className="xgs-public-shipment-details__property__label">By:</div>
                      <div className="xgs-public-shipment-details__property__value">{publicShipmentDetailsState.shipment.pod.signature}</div>
                    </div>
                  </div>
                )}
              </div>
              <div className="xgs-public-shipment-details__row__right">
                <div className="xgs-public-shipment-details__property">
                  <div className="xgs-public-shipment-details__property__label">Probill:</div>
                  <div className="xgs-public-shipment-details__property__value">{publicShipmentDetailsState.shipment.probill}</div>
                </div>
                <div className="xgs-public-shipment-details__property">
                  <div className="xgs-public-shipment-details__property__label">BOL:</div>
                  <div className="xgs-public-shipment-details__property__value">{publicShipmentDetailsState.shipment.bol}</div>
                </div>
                <div className="xgs-public-shipment-details__property">
                  <div className="xgs-public-shipment-details__property__label">Pieces:</div>
                  <div className="xgs-public-shipment-details__property__value">{publicShipmentDetailsState.shipment.pieces}</div>
                </div>
                <div className="xgs-public-shipment-details__property">
                  <div className="xgs-public-shipment-details__property__label">Weight:</div>
                  <div className="xgs-public-shipment-details__property__value">{publicShipmentDetailsState.shipment.weight} lb</div>
                </div>
              </div>
            </div>
            <div className="xgs-public-shipment-details__progress">
              <SteppedProgress
                steps={generateProgressSteps(publicShipmentDetailsState.shipment)}
              />
            </div>
            <div className="xgs-public-shipment-details__locations">
              <div className="xgs-public-shipment-details__locations__item">
                <div className="xgs-public-shipment-details__locations__item__type">XGS Origin Hub</div>
                <div className="xgs-public-shipment-details__locations__item__box">
                  {publicShipmentDetailsState.shipment.origin.name && publicShipmentDetailsState.shipment.origin.address && (
                    <>
                      <div className="xgs-public-shipment-details__locations__item__name">{publicShipmentDetailsState.shipment.origin.id} {publicShipmentDetailsState.shipment.origin.name}</div>
                      <div className="xgs-public-shipment-details__locations__item__address">
                        {publicShipmentDetailsState.shipment.origin.address}<br />
                        {publicShipmentDetailsState.shipment.origin.city}, {publicShipmentDetailsState.shipment.origin.state} {publicShipmentDetailsState.shipment.origin.zip}
                      </div>
                      {publicShipmentDetailsState.shipment.origin.phone && (
                        <div className="xgs-public-shipment-details__locations__item__phone">
                          <XGSIcon
                            icon={XGSIcons.faPhone}
                            size="sm"
                            className="xgs-public-shipment-details__locations__item__phone__icon"
                          />
                          {publicShipmentDetailsState.shipment.origin.phone.formatPhone()}
                        </div>
                      )}
                    </>
                  )}
                  {!(publicShipmentDetailsState.shipment.origin.name || publicShipmentDetailsState.shipment.origin.address) && (
                    <div className="xgs-public-shipment-details__locations__item__no">
                      There is no origin hub yet.
                    </div>
                  )}
                </div>
              </div>
              <div className="xgs-public-shipment-details__locations__arrow xgs-public-shipment-details__locations__arrow--right">
                <XGSIcon
                  icon={XGSIcons.faArrowRight}
                  className="xgs-public-shipment-details__locations__arrow__icon"
                />
              </div>
              <div className="xgs-public-shipment-details__locations__arrow xgs-public-shipment-details__locations__arrow--down">
                <XGSIcon
                  icon={XGSIcons.faArrowDown}
                  className="xgs-public-shipment-details__locations__arrow__icon"
                />
              </div>
              <div className="xgs-public-shipment-details__locations__item">
                <div className="xgs-public-shipment-details__locations__item__type">XGS Destination Hub</div>
                <div className="xgs-public-shipment-details__locations__item__box">
                  {publicShipmentDetailsState.shipment.destination.name && publicShipmentDetailsState.shipment.destination.address && (
                    <>
                      <div className="xgs-public-shipment-details__locations__item__name">{publicShipmentDetailsState.shipment.destination.id} {publicShipmentDetailsState.shipment.destination.name}</div>
                      <div className="xgs-public-shipment-details__locations__item__address">
                        {publicShipmentDetailsState.shipment.destination.address}<br />
                        {publicShipmentDetailsState.shipment.destination.city}, {publicShipmentDetailsState.shipment.destination.state} {publicShipmentDetailsState.shipment.destination.zip}
                      </div>
                      {publicShipmentDetailsState.shipment.destination.phone && (
                        <div className="xgs-public-shipment-details__locations__item__phone">
                          <XGSIcon
                            icon={XGSIcons.faPhone}
                            size="sm"
                            className="xgs-public-shipment-details__locations__item__phone__icon"
                          />
                          {publicShipmentDetailsState.shipment.destination.phone.formatPhone()}
                        </div>
                      )}
                    </>
                  )}
                  {!(publicShipmentDetailsState.shipment.destination.name || publicShipmentDetailsState.shipment.destination.address) && (
                    <div className="xgs-public-shipment-details__locations__item__no">
                      There is no destination hub yet.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {(publicShipmentDetailsState.loadingFailed || error) && (
          <div className="xgs-public-shipment-details__content">
            <div className="xgs-public-shipment-details__error">
              <Exclamation type={ExclamationTypes.error}>
                {publicShipmentDetailsState.loadingErrorStatus === 404 && (
                  <>
                    <div className="xgs-public-shipment-details__error__header">No Results Found for shipment #{probill}</div>
                    <div className="xgs-public-shipment-details__error__text">
                      The shipment information provided could not be located in our system.
                      Please contact the merchant to confirm the accuracy of the probill number.
                    </div>
                    <div className="xgs-public-shipment-details__error__text">
                      If your probill number is correct and you still do not get tracking results,
                      contact our customer support team at <a href="mailto:xgscustomerservice@xgsi.com">xgscustomerservice@xgsi.com</a>.
                    </div>
                  </>
                )}
                {publicShipmentDetailsState.loadingErrorStatus !== 404 && (
                  <>
                    {publicShipmentDetailsState.loadingError || "Error. Please try again later."}
                  </>
                )}
              </Exclamation>
            </div>
          </div>
        )}
        <div className="xgs-public-shipment-details__login-link">
          <a href="/" className="blue-link">Go to Customer Portal login</a>
        </div>
      </div>
      <div className="xgs-public-shipment-details__footer">
        <div className="xgs-public-shipment-details__footer__text">Xpress Global Systems, LLC.</div> <div className="xgs-public-shipment-details__footer__delimiter">|</div> <div className="xgs-public-shipment-details__footer__text"><a href="tel:8449477447" className="blue-link">844-947-7447</a></div> <div className="xgs-public-shipment-details__footer__delimiter">|</div> <div className="xgs-public-shipment-details__footer__text"><a href="mailto:info@xgsi.com" className="blue-link">info@xgsi.com</a></div>
      </div>
    </div>
  );
};

export default PublicShipmentDetails;
