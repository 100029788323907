import React, { forwardRef, memo } from "react";

import "./selectedBar.scss";

const SelectedBar = memo(forwardRef<any>((props, ref) => {
  return (
    <div className="xgs-table__selected-bar">
      <div className="xgs-table__selected-bar__bar" ref={ref}>
        {props.children}
      </div>
    </div>
  );
}));

export default SelectedBar;
