import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import ProbillService from "../../app/data/probill/probillService";
import { initialProbillPreviewState } from "./ProbillPreviewState";
import { ERROR_MESSAGES } from "../../app/data/common/errorMessages";

const probillService = ProbillService.getInstance();

export const probillPreviewSlice = createSlice({
  name: "probillPreview",
  initialState: initialProbillPreviewState,
  reducers: {
    resetProbillPreviewState: (state) => initialProbillPreviewState,
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestCreator = payload;
      state.requestError = "";
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
      state.requestFailed = false;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestError = payload.error;
    },   
    setProbillPreview: (state, { payload }) => {
      state.probillPreview = payload;
    },    
  }
});

export const {
  resetProbillPreviewState,
  requestStarted,
  requestSucceed,
  requestFailed,  
  setProbillPreview,
} = probillPreviewSlice.actions;

export const probillPreviewSelector = (state: IState) => {
  return state.probill;
};

export const getProbillPreview = (
  probillNumber: number
): AppThunk => async (dispatch) => {
  dispatch(resetProbillPreviewState());
  dispatch(requestStarted("GET_PROBILL_PREVIEW"));
  const response = await probillService.getProbillPreview(probillNumber);
  if (response.ok()) {
    dispatch(setProbillPreview(response.data));
    dispatch(requestSucceed());
  } else {
    dispatch(requestFailed({
      error: response.getError ? response.getError() : ERROR_MESSAGES.COMMON
    }));
  }
};

const probillPreviewReducer = probillPreviewSlice.reducer;
export default probillPreviewReducer;
