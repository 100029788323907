import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "../../../ui-components/table/table";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import AddShipper from "./addShipper";
import CompanySettingsShippersState from "../../../slices/company-settings/CompanySettingsShippersState";
import {
  companySettingsShippersSelector,
  getShippers,
  resetErrors
} from "../../../slices/company-settings/companySettingsShippersSlice";
import "./companySettingsShippers.scss";

const columnHelper = createColumnHelper<any>();

const CompanySettingsShippers: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const shippersState: CompanySettingsShippersState = useSelector(companySettingsShippersSelector);
  const [showAddShipper, setShowAddShipper] = useState<boolean>(false);

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: "Shipper Name",
      size: 220,
    }),
    columnHelper.display({
      id: "address",
      header: "Address",
      cell: ({ row }: any) => (
        <span>
          {row.original.address?.line1 ? `${row.original.address.line1}, ` : ""}{row.original.address?.line2 ? `${row.original.address.line2}, ` : ""}{row.original.address?.city ? `${row.original.address.city}, ` : ""}{row.original.address?.state ? `${row.original.address.state}, ` : ""}{row.original.address?.zip}
        </span>
      ),
      size: 340,
    })
  ];

  useEffect(() => {
    dispatch(resetErrors());
    dispatch(getShippers());

    return () => {
      dispatch(resetErrors());
    }
  }, [dispatch]);

  return (
    <div className="xgs-company__shippers">
      <div className="xgs-list__controls xgs-company__shippers__controls">
        <Button
          className="xgs-company__shippers__add-button"
          theme={ButtonThemes.blue}
          onClick={() => setShowAddShipper(true)}
        >
          Add Shipper
        </Button>
        <AddShipper
          show={showAddShipper}
          onRequestSent={() => {
            dispatch(getShippers());
          }}
          onClose={() => {
            setShowAddShipper(false);
          }}
        />
      </div>
      {shippersState.requestFailed && shippersState.requestCreator === "GET_SHIPPERS" && (
        <XGSErrorMessage>{shippersState.requestError}</XGSErrorMessage>
      )}
      {!(shippersState.requestFailed && shippersState.requestCreator === "GET_SHIPPERS") && (
        <Table
          isLoading={shippersState.requestStarted && shippersState.requestCreator === "GET_SHIPPERS"}
          columns={columns}
          data={shippersState.shippers}
          cursorPointer={false}
          rowHeight={66}
          minTableHeight={420}
          noResultsText="There are no shippers"
        />
      )}
    </div>
  );
};

export default CompanySettingsShippers;
