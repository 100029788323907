import React from "react";
import ContentContainer from "../../../templates/content-container/contentContainer";
import ReturnList from "../common/list";
import { PAGE_IDS } from "../../../app/data/common/pageIds";

import "../bol.scss";

const CombinedList: React.FC<{}> = (props) => {
  return (
    <ContentContainer pageId={PAGE_IDS.RETURNS} title="Returns">
      <ReturnList defaultTypes={["RETURN"]} />
    </ContentContainer>
  );
};

export default CombinedList;
