import React from "react";
import { createColumnHelper } from "@tanstack/react-table";

const parseProbills = (descriptionItem: string) => {
  if (!descriptionItem) return null;
  const probillRegex = /(\b\d{6,8}\b(\b|$))/gi;
  let descriptionItemBlocks = descriptionItem.split(probillRegex);
  let result = descriptionItemBlocks.map((block) => {
    if (block.match(probillRegex)) {
      return (
        <a
          href={"/shipments/" + block}
          target="_blank"
          className="xgs-invoice-details__table__link"
          rel="noopener noreferrer"
        >
          {block}
        </a>
      );
    } else {
      return block;
    }
  });
  return result;
};

const columnHelper = createColumnHelper<any>();

const selectColumn = (selectedItems: Set<number>, onClickSelect: any) => (
  columnHelper.display({
    id: "checked",
    header: "Select",
    cell: (cellProps) => {
      return (
        <div className="xgs-form__checkbox">
          <input
            checked={selectedItems?.has(cellProps.row.index)}
            onChange={() => onClickSelect(cellProps.row.index)}
            type="checkbox"
          />

        </div>
      );
    },
    size: 80,
  }));

export const getExceptionItemsColumns = (selectedItems: Set<number>, onClickSelect: any) => [
  selectColumn(selectedItems, onClickSelect),
  columnHelper.accessor("serialNumber", {
    id: "serialNumber",
    header: "Serial Number",
    size: 220,
    minSize: 220
  }),
  columnHelper.accessor("weight", {
    id: "weight",
    header: "Weight",
    size: 100,
    minSize: 100
  }),
  columnHelper.accessor("description", {
    id: "description",
    header: "Description",
    cell: (cellProps) => parseProbills(cellProps.getValue()),    
    size: 288,
  })
];