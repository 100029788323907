import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentContainer from "../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import Table from "../../../ui-components/table/table";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import { UnplannableProbillsState } from "../../../slices/dispatch-planning/unplannable-probills/unplannableProbillsState";
import { getUnplannableProbills, resetUnplannableProbills, unplannableProbillsSelector } from "../../../slices/dispatch-planning/unplannable-probills/unplannableProbillsSlice";
import { getunplanProbillsColumns } from "./unplannableProbillsColumns";
import { UnplannableProbill, UnplannableProbillsFilters, UnplannableProbillsFiltersSchema, UnplannableProbillsRequest, UnplannableProbillsStatusOptions } from "../../../app/data/unplannable-probills/models";
import UnplannableProbillDetails from "./details/unplannableProbillDetails";
import { toast } from "react-toastify";
import { UnplannableProbillsSummary } from "./summary/unplannableProbillsSummary";
import { Form, Formik, FormikProps } from "formik";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import LabeledDateRangeInput from "../../../ui-components/molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import XGSFormSelect from "../../../ui-components/form/select/xgsFormSelect";
import moment from "moment";
import dateFormats from "../../../app/data/common/dateFormats";
import { PAGE_IDS } from "../../../app/data/common/pageIds";
import "./unplannableProbills.scss";

const initialValues = {
  etaStartDate: "",
  etaEndDate: "",
  probillStatus: ""
};

const UnplannableProbills: React.FC<{}> = memo((props) => {
  const dispatch = useDispatch();
  const [selectedProbillDetails, setSelectedProbillDetails] = useState<UnplannableProbill | null>(null);
  const formRef = React.useRef<FormikProps<UnplannableProbillsFilters>>(null);

  const userState: UserState = useSelector(
    userSelector
  );
  const unplannableProbillsState: UnplannableProbillsState = useSelector(
    unplannableProbillsSelector
  );

  useEffect(() => {
    const userTerminal = userState.activeTerminal?.id;
    onTerminalChanged(userTerminal);
    // eslint-disable-next-line
  }, [userState.activeTerminal?.id])

  const onTerminalChanged = (userTerminal: number | null | undefined) => {
    dispatch(resetUnplannableProbills());
    if (!userTerminal) return; // can not get the Probills without knowing the terminal
    formRef.current?.submitForm();
  };

  const showProbillDetails = (probillDetails: UnplannableProbill) => {
    setSelectedProbillDetails(probillDetails);
  }

  const onSubmit = (data: UnplannableProbillsFilters) => {
    if (!userState.activeTerminal?.id) return;
    setSelectedProbillDetails(null);
    let requestData: UnplannableProbillsRequest = {
      terminalNumber: userState.activeTerminal?.id
    };
    if (data.probillNumber) requestData.probillNumber = data.probillNumber;
    if (data.etaStartDate) requestData.etaStartDate = moment(data.etaStartDate).format(dateFormats.isoDate);
    if (data.etaEndDate) requestData.etaEndDate = moment(data.etaEndDate).format(dateFormats.isoDate);
    if (data.probillStatus) requestData.probillStatus = data.probillStatus;
    dispatch(getUnplannableProbills(
      requestData,
      (error) => { toast.error(error) }
    ));
  };

  return (
    <ContentContainer
      className="xgs-list xgs-list--full-width"
      mods={{ 'full-width': true }}
      titleComponent={
        <ContentContainerToolbar
          pageId={PAGE_IDS.UNPLANNABLE_PROBILLS}
          title="Unplannable Probills"
          mods={{ "full-width": true }}
        >
        </ContentContainerToolbar>
      }
    >
      <div className="xgs-unplannabe-probills">
        <div className="xgs-list__controls">
          <div className="xgs-list__controls__search">
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={UnplannableProbillsFiltersSchema}
              innerRef={formRef}
            >
              {(props: FormikProps<UnplannableProbillsFilters>) => (
                <Form className="xgs-list__controls__search-form">
                  <XGSFormInput
                    type="text"
                    name="probillNumber"
                    label="Probill"
                    placeholder="Probill Number"
                    labelMode={LabelModes.column}
                    className="xgs-item-form__field"
                  />
                  <LabeledDateRangeInput
                    label="ETA range"
                    labelMode={LabelModes.column}
                    className="xgs-item-form__field"
                    start={props.values.etaStartDate}
                    end={props.values.etaEndDate}
                    onStartChange={(v) => {
                      props.setFieldValue("etaStartDate", v || undefined);
                    }}
                    onEndChange={(v) => {
                      props.setFieldValue("etaEndDate", v || undefined);
                    }}
                  />
                  <XGSFormSelect
                    name={"probillStatus"}
                    options={UnplannableProbillsStatusOptions}
                    formik
                    disabled={false}
                    placeholder="Select..."
                    labelMode={LabelModes.column}
                    label="Status"
                    onValueChange={(value) => {
                      props.setFieldValue("probillStatus", value?.value);
                    }}
                    isClearable={true}
                    className="xgs-item-form__field"
                  />
                  <Button
                    type="submit"
                    theme={ButtonThemes.blue}
                    className=""
                    spinner={false}
                    disabled={unplannableProbillsState.loading}
                  >
                    Search
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="xgs-unplannabe-probills__summary">
          <UnplannableProbillsSummary
            summaryData={unplannableProbillsState.summary}
            isLoading={unplannableProbillsState.loading}
          />
        </div>
        {(!userState.activeTerminal?.id) ? (
          <XGSErrorMessage>
            <div>
              You do not have assigned terminals.
              Email <a className="white-link" href="mailto:helpdesk@xgsi.com">helpdesk@xgsi.com</a> to request terminal assignment for your account.
            </div>
          </XGSErrorMessage>
        ) : (
          <div className="xgs-unplannabe-probills__table-wrapper">
            <Table
              isLoading={unplannableProbillsState.loading}
              columns={getunplanProbillsColumns()}
              data={unplannableProbillsState.selectedProbillsList}
              cursorPointer={true}
              rowHeight={64}
              noResultsText="No Probills available"
              onRowClicked={(row: UnplannableProbill) => { showProbillDetails(row) }}
              onSelectedRowsChange={null}
              infiniteScroll={true}
              responsive={true}
              sorting={true}
              enableMultiSort={true}
              minTableHeight={600}
            />
          </div>
        )}
        {(selectedProbillDetails && selectedProbillDetails.probillNumber) && (
          <UnplannableProbillDetails
            probillDetails={selectedProbillDetails}
            onClose={() => { setSelectedProbillDetails(null) }}
          />
        )}
      </div>
    </ContentContainer>
  );
});

export default UnplannableProbills;
