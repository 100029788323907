import { ApiResponse } from "../app/data/api";
import { toast } from "react-toastify";
import { useRef, useState } from "react";
import ExportService from "../app/data/export/exportService";
import { ERROR_MESSAGES } from "../app/data/common/errorMessages";

const exportService = ExportService.getInstance();

export const useExport = (
  exportMethod: () => Promise<ApiResponse<any>>,
  exportIdKey?: string,
  errorMessage?: string,
) => {  
  const idKey = exportIdKey || "id";
  
  const [exportStarted, setExportStarted] = useState(false);
  const [exportSucceed, setExportSucceed] = useState(false);
  const [exportFailed, setExportFailed] = useState(false);

  const exportIdRef = useRef(null);

  const getExport = async (
  ) =>  {
    resetExport();  
    setExportStarted(true);
  
    if (!exportIdRef?.current) {
      // get export process ID
      const response = await exportMethod();
      if (response.ok()) {
        if (response.data.fileUrl) {
          setExportSucceed(true);
          setExportStarted(false);
          onExportSuccess(response.data.fileUrl, response.data.mimeType);
          return;
        }
        exportIdRef.current = response.data[idKey];
      } else {
        setExportFailed(true);
      }
    }
  
    if (exportIdRef?.current) {
      // get link of exported file
      let attempt = 0;
      while (attempt < 20) {
        const response = await exportService.getExportLink(exportIdRef?.current);        
        if (response.ok()) {
          if (response.data.status === "DONE") {
            setExportSucceed(true);
            setExportStarted(false);
            onExportSuccess(response.data.fileUrl, response.data.mimeType);
            break;
          }
          if (response.data.status === "FAILED") {
            setExportFailed(true);
            setExportStarted(false);
            onExportFailed(errorMessage || ERROR_MESSAGES.COMMON);
            break;
          }
        } else {
          setExportFailed(true);
          setExportStarted(false);
          onExportFailed(errorMessage || ERROR_MESSAGES.COMMON);
          break;
        }
        await new Promise((d) => setTimeout(d, 1000));
        attempt++;
      }
    }
  };

  const onExportSuccess = (exportLink: string, mimeType: string) => {
    if (!exportLink) return
    const link = document.createElement("a");
    link.href = exportLink;

    if (mimeType === "application/pdf") {
      link.target = "_blank";
    }

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onExportFailed = (error: string) => { toast.error(error); }; 

  const resetExport = () => {
    setExportStarted(false);
    setExportSucceed(false);
    setExportFailed(false);
    exportIdRef.current = null;
  };

  return {
    getExport,
    resetExport,
    exportState: {
      exportStarted,
      exportSucceed,
      exportFailed,
    },
  };
};