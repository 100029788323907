export const PAGE_IDS = {
  ACTIVE_ROUTES: "active-routes",
  AGENTS: "agents",
  AGING_INVOICES_120_PLUS: "aging-invoices_120_PLUS",
  AGING_INVOICES_1_30: "aging-invoices_1_30",
  AGING_INVOICES_31_60: "aging-invoices_31_60",
  AGING_INVOICES_61_90: "aging-invoices_61_90",
  AGING_INVOICES_91_120: "aging-invoices_91_120",
  AGING_INVOICES_TOTAL: "aging-invoices_TOTAL",
  AI: "ai-chat",
  ANNOUNCEMENT: "announcement",
  APPOINTMENTS: "appointments",
  BOL: "bol",
  CLAIMS: "claims",
  CREATE_PICKUP: "create-pickup",
  CUSTOMERS: "customers",
  DASHBOARD: "dashboard",
  DOCK_LOADING: "dock-loading",
  DOCUMENTS: "shipments-documents",
  EMERGENCY_ALERT_MANAGEMENT: "emergency-alert",
  FEATURE_BANNER: "feature-banner",
  HOLIDAYS: "holidays",
  IMAGES: "images",
  LANE_MANAGEMENT: "lane-management",
  LINEHAUL_MILEAGE: "linehaul-mileage",
  MANIFEST_DOCUMENTS: "manifest-documents",
  PENDING_REQUESTS: "pending-requests",
  PICKLISTS: "picklists",
  PICKUP_ASSIGNMENT: "pickup-assignment",
  PICKUP_LOG: "pickup-log",
  PICKUPS: "pickups",
  PLAN_ROUTE: "plan-route",
  POD_RECOGNITION: "pod-recognition",
  RATE_QUOTES: "rate-quotes",
  RECOGNITION_LOGS: "recognition_logs",
  REPORTS_SCHEDULED: "reports-scheduled",
  RETURNS: "returns",
  SERVICE_DISRUPTION: "service-disruption",
  TRACK_MANIFESTS: "track-manifests",
  TRACK_SHIPMENTS: "shipments-track-shipments",
  TRANSIT_CALCULATOR: "transit-calculator",
  UNPLANNABLE_PROBILLS: "unplannable-probills",
  USERS: "users",
  WAREHOUSE_INVENTORY: "warehouse-inventory",
  WAREHOUSE_SUMMARY: "warehouse-summary",
  // please add new lines in alphabetical order
};