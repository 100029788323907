import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";
import { Contact, SortParams } from "../common/models";
import { PaymentStatus } from "../payment/PaymentStatus";
import CheetahInfoModel from "../tracking/CheetahInfoModel";
import DeliveryStatus from "../tracking/DeliveryStatus";
import { ServiceCenterModel } from "../tracking/ShipmentDetailsModel";

export default interface Invoice {
  subAccountId?: string;
  invoiceNumber: number;
  bolNumber: number;
  poNumber: number;
  billType: string;
  paymentType: string;
  dateInvoice: string;
  dateDue: string;
  invoiceAmount: number;
  openAmount: number;
  paid: boolean;
}

export interface GetAgingInvoiceRequestModel extends SortParams {
  subAccountId: string;
  agingPeriod?: string;
  invoiceNumber?: number | null;
  lastIds?: string | null;
}

export interface GetInvoiceDetailsRequestModel {
  subAccountId: string;
  invoiceId: number;
}

export interface InvoiceDetails {
  cheetahInfo?: CheetahInfoModel;
  codCollect: string;
  consignee: Contact;
  destination: ServiceCenterModel;
  driver: string;
  driverCollect: string;
  exportId?: string;
  fullAccess: boolean;
  invoice: Invoice;
  items: InvoiceDetailsItem[];
  origin: ServiceCenterModel;
  paymentStatus?: PaymentStatus;
  payor: Contact;
  shipper: Contact;
  status?: DeliveryStatus;
  trailer: number;
  typeOfBill: string;
  manifest?: {
    delivery: string;
    linehaul: string;
  };
}

export interface InvoiceDetailsItem {
  pieces: number | undefined | null;
  commodityCode: string | undefined | null;
  description: string | undefined | null;
  units: string | undefined | null;
  quantity: number | undefined | null;
  weight: number | undefined | null;
  rate: number | undefined | null;
  freightCharges: number | undefined | null;
  identifier?: string | null;
  serialNumber?: string | null;
}

export interface InvoiceDocument {
  id: number;
  type: string;
  thumbnail: string;
}

export interface InvoiceDocuments {
  id: number;
  type: string;
  thumbnail: string;
}

export interface DocumentRequestModel {
  probill: number;
  types: string[];
}

export interface ManifestDocumentRequestModel {
  manifestNumber: number;
  types: string[];
  manifestType: ManifestType;
  isConsolidated: boolean;
}

export interface EmailDocumentsModel {
  email: string;
}

export const EmailDocumentsSchema: Yup.ObjectSchema<EmailDocumentsModel> = Yup.object().shape({
  email: Yup.string().max(60, "The value must be no more than 60 characters").email(validationMessages.notValid).required(validationMessages.required)
}).defined();

export enum ManifestType {
  LINEHAUL = "LINEHAUL",
  DELIVERY = "DELIVERY",
}

export interface InvoiceFilterModel {
  invoiceNumber?: number | null;
}

export const InvoiceFilterSchema: Yup.ObjectSchema<InvoiceFilterModel> = Yup.object({
  invoiceNumber: Yup.number().nullable().typeError(validationMessages.notValid).test('length', 'Must be exactly 8 digits', val => (!val || val.toString().length === 8)),
}).defined();
