import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import {ReactComponent as ProbillIcon}  from "../../../images/probill.svg";
import {ReactComponent as BOLIcon} from "../../../images/bol.svg";
import {ReactComponent as WeightIcon} from "../../../images/weight_light.svg";
import {ReactComponent as CompanyIcon} from "../../../images/company.svg";
import {ReactComponent as LocationIcon} from "../../../images/location.svg";
import {ReactComponent as PlanetIcon} from "../../../images/planet.svg";
import {ReactComponent as PhoneIcon} from "../../../images/phone.svg";
import {ReactComponent as EmailIcon} from "../../../images/ad_mark.svg";
import {ReactComponent as LinkIcon} from "../../../images/link_2.svg";
import Loading from "../../../ui-components/loading/loading";
import XGSNavLink from "../../../ui-components/xgs-nav-link/xgsNavLink";

import { getProbillPreview, probillPreviewSelector } from "../../../slices/probill/probillPreviewSlice";
import { AddressModel } from "../../../app/data/common/address";

import "./probillPreview.scss";

export interface ProbillPreviewProps {
  children: React.ReactElement;
  probillNumber: number;
}

const ProbillPreview: React.FC<ProbillPreviewProps> = ({ probillNumber, children }) => {
  const ref = useRef<any>();
  const dispatch = useDispatch();
  const probillState = useSelector(probillPreviewSelector);

  const fetchPreview = () => {
    if (probillState.probillPreview?.invoiceNumber !== probillNumber) {
      dispatch(getProbillPreview(probillNumber));
    }
  }

  const displayValue = (value?: string | number) => value?.toString() || "-";

  const formatAddress = (address: AddressModel) => 
    `${address.city ? `${address.city.toLowerCase().capitalize()}, ` : ""}${address.state ? `${address.state} ` : ""}${address.postalCode || ""}`;

  const { probillPreview } = probillState;

  return (
    <Popup
      className="probill-preview"
      ref={ref}
      trigger={children}
      position={["right center", "right bottom", "right top"]}
      offsetX={24}
      on={["hover"]}
      arrow={false}
      contentStyle={{
        width: "260px",
        minHeight: "420px",
        padding: "0",
      }}
      onOpen={fetchPreview}
    >
      <div className="probill-preview__content">
        <div className="probill-preview__header">
          <div className="probill-preview__header__icon">
            <ProbillIcon />
          </div>
          <div>
            <div className="probill-preview__header__title">
              {probillNumber}
            </div>
            {probillPreview?.status.translate && <div className="probill-preview__header__status">
              {probillPreview.status.translate}
            </div>}
          </div>
        </div>
        
        {!probillPreview ? (
          <div className="probill-preview__loading">
            <Loading isLoading={true} />
          </div>
        )
          : (
            <div>
              <div className="probill-preview__section">
                <div className="probill-preview__section__heading">Items</div>
                <div className="probill-preview__section__body">
                  <div className="probill-preview__section__item">
                    <span className="probill-preview__section__item__label"><BOLIcon />&nbsp;Quantity:</span>
                    <span className="probill-preview__section__item__value">{probillPreview?.itemsQuantity}</span>
                  </div>
                  <div className="probill-preview__section__item">
                    <span className="probill-preview__section__item__label"><WeightIcon />&nbsp;Weight:</span>
                    <span className="probill-preview__section__item__value">{`${probillPreview?.itemsWeight} lbs`}</span>
                  </div>
                </div>
              </div>

              <div className="probill-preview__section">
                <div className="probill-preview__section__heading">Consignee</div>
                <div className="probill-preview__section__body">
                  <div className="probill-preview__section__item">
                    <span className="probill-preview__section__item__label probill-preview__section__item__label--top"><CompanyIcon /></span>
                    <span className="probill-preview__section__item__value">{`${displayValue(probillPreview.consignee.name)} (${displayValue(probillPreview.consignee.consigneeNumber)})`}</span>
                  </div>
                  <div className="probill-preview__section__item">
                    <span className="probill-preview__section__item__label probill-preview__section__item__label--top"><LocationIcon /></span>
                    <span className="probill-preview__section__item__value">{displayValue(probillPreview.consignee.address.address1?.toLowerCase().capitalize())}</span>
                  </div>
                  <div className="probill-preview__section__item">
                    <span className="probill-preview__section__item__label probill-preview__section__item__label--top"><PlanetIcon /></span>
                    <span className="probill-preview__section__item__value">{displayValue(formatAddress(probillPreview.consignee.address))}</span>
                  </div>
                  <div className="probill-preview__section__item">
                    <span className="probill-preview__section__item__label probill-preview__section__item__label--top"><PhoneIcon /></span>
                    <span className="probill-preview__section__item__value">{displayValue(probillPreview.consignee.contactInfo.phone?.formatPhone())}</span>
                  </div>
                  {probillPreview.consignee.contactInfo.email && <div className="probill-preview__section__item">
                    <span className="probill-preview__section__item__label probill-preview__section__item__label--top"><EmailIcon /></span>
                    <span className="probill-preview__section__item__value">{probillPreview.consignee.contactInfo.email}</span>
                  </div>}
                </div>
              </div>

              <XGSNavLink
                className="blue-link underline-link"
                to={`/shipments/${probillPreview.invoiceNumber}`}
                target="_blank"
              >
                Show more&nbsp;&nbsp;<LinkIcon />
              </XGSNavLink>
            </div>
          )}
      </div>
    </Popup>
  )
};

export default ProbillPreview;