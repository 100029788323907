import React from "react";
import InvoiceService from "../../../app/data/invoice/invoiceService";
import DownloadButton from "../../../ui-components/download-button/downloadButton";
import { useExport } from "../../../hooks/useExport";

export interface InvoiceDownloadButtonProps {
  invoiceNumber: number;
}

const invoiceDocumentsService = InvoiceService.getInstance();

const InvoiceDownloadButton: React.FC<InvoiceDownloadButtonProps> = ({ invoiceNumber }) => {
  const exportInvoice = () => invoiceDocumentsService.exportInvoiceDocument(invoiceNumber);

  const {
    getExport,
    exportState
  } = useExport(    
    exportInvoice,
    "id",
  );

  return (
    <DownloadButton
        title="Invoice"
        onClick={(e) => {
          e.stopPropagation();
          getExport();
        }}
        spinner={exportState?.exportStarted}
      />
  )
}

export default InvoiceDownloadButton;