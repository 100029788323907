import moment from "moment";
import { XGSSelectOption } from "../../ui-components/xgs-select/xgsSelect";

// Returns label-value pairs with time options
export const timeOptions = () => {
  let options: XGSSelectOption[] = [];
  let startTime = moment().startOf("day").add(6, "hours");
  for (let i = 1; i < 30; i++) {
    let time = startTime.format("h:mm a");
    options.push({
      label: time,
      value: time
    });
    startTime = startTime.add(30, "minutes");
  }
  return options;
};

// Returns label-value pairs with time options for From fields
// 3 hours gap between fromTimeOptions and toTimeOptions
export const fromTimeOptions = () => {
  let options = timeOptions();
  // cut last 3 hours
  options = options.slice(0, -6);
  return options;
};

// Returns label-value pairs with time options for To fields
// 3 hours gap between fromTimeOptions and toTimeOptions
export const toTimeOptions = (fromTime: string) => {
  let options = timeOptions();
  if (fromTime) {
    // cut all till "from time + 3 hours"
    const fromIndex = options.findIndex((obj: XGSSelectOption) => obj.value === fromTime);
    options = options.slice(fromIndex + 6, options.length);
  } else {
    // cut first 3 hours
    options = options.slice(6, options.length);
  }
  return options;
};
