import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "../../../ui-components/table/table";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import XGSFormSelect from "../../../ui-components/form/select/xgsFormSelect";
import ContentContainer from "../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import DownloadButton from "../../../ui-components/download-button/downloadButton";
import UsersState from "../../../slices/customers/users/TeamUsersState";
import {
  getAllUsers,
  resetErrors,
  usersSelector
} from "../../../slices/customers/users/teamUsersSlice";
import { customerRoles } from "../../../app/data/common/customerRoles";
import UserProfile from "../../../app/data/user/UserProfile";
import UserDetails from "../user-details/userDetails";
import { SearchCustomersModel } from "../../../app/data/company/models";
import { userRoleOptions, userStatusOptions, userStatuses } from "../constants";
import { agentRoles } from "../../../app/data/common/agentRoles";
import { useDownload } from "../../../hooks/useDownload";
import CompanyService from "../../../app/data/company/companyService";
import { UserUtils } from "../../../app/data/user/userUtils";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import { PAGE_IDS } from "../../../app/data/common/pageIds";

import "./usersList.scss";

const companyService = CompanyService.getInstance();

const initialValues: SearchCustomersModel = {
  search: "",  
  company: "",
  role: null,
  status: null,
};

const columnHelper = createColumnHelper<UserProfile>();

const CustomersUsers: React.FC<{}> = React.memo((props) => {
  const dispatch = useDispatch();
  const usersState: UsersState = useSelector(usersSelector);
  const userState: UserState = useSelector(userSelector);
  const [userId, setUserId] = useState<string | undefined>();
  const [lastFilter, setLastFilter] = useState<SearchCustomersModel>();
  const filterFormRef = useRef<any>(null);  

  const columns = [
    columnHelper.accessor(
      (row) => (`${row.firstName || ""} ${row.lastName || ""}`),
      {
        id: "name",
        header: "Name",
        size: 200,
        minSize: 180,
      }), 
    columnHelper.accessor("email", {
      id: "email",     
      header: "Email",      
      cell: (cellProps) => (
        <>
          {cellProps.getValue()}
          {cellProps.row.original.pendingEmail && (cellProps.getValue() !== cellProps.row.original.pendingEmail) && (
            <>
              <br />
              <span className="xgs-company__users__list__pending-email">({
                cellProps.row.original.pendingEmail.length > 25 ? cellProps.row.original.pendingEmail.substring(0, 25) + "..." : cellProps.row.original.pendingEmail
              })</span>
            </>
          )}
        </>
      ),
      size: 250,
      minSize: 210,
    }),
    columnHelper.accessor("team.name", {
      id: "teamName",
      header: "Customer",
      size: 220,
      minSize: 180,
    }),
    columnHelper.accessor("role", {
      id: "role",
      header: "Role",      
      cell: (cellProps) => (
        <>
          {[...customerRoles, ...agentRoles].find(role => role.value === cellProps.getValue())?.label}
        </>
      ),
      size: 120,
      minSize: 90,
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <div className="text-center">
          Status
        </div>
      ),      
      cell: (cellProps) => (
        <div
          className="xgs-company__users__details__status__circle"
          style={{
            backgroundColor: userStatuses.find(status => status.value === cellProps.getValue())?.color,
            marginLeft: "auto",
            marginRight: "auto"
          }}
          title={cellProps.getValue().toLowerCase()}>
        </div>
      ),
      maxSize: 70,
      minSize: 64,
    }),
  ];

  const onListInfiniteScroll = () => {
    !usersState.usersFetchedAll && dispatch(getAllUsers(false, lastFilter, usersState.usersRequest?.lastIds));
  };

  const onUserClick = (row: UserProfile) => {
    setUserId(row.id)
  };

  const fetchCSV = () => companyService.getUsersCSV(lastFilter);

  const {
    download,
    resetDownload,
    loadingState
  } = useDownload(
    "USERS",
    fetchCSV,
  );

  const onSearchStart = (values: SearchCustomersModel) => {
    resetDownload();
    setLastFilter(values);
    dispatch(getAllUsers(false, values));
    setUserId(undefined);
  };

  const onSearchClear = () => {
    resetDownload();
    setLastFilter(undefined);
    filterFormRef.current.resetForm();
    dispatch(getAllUsers(false, undefined));
    setUserId(undefined);
  };

  useEffect(() => {
    dispatch(resetErrors());
    dispatch(getAllUsers(false, undefined));
    setUserId(undefined);
  }, [dispatch]);

  return (
    <ContentContainer    
    title="Users"
    mods={{ 'full-width': true }}
    titleComponent={
      <ContentContainerToolbar
        pageId={PAGE_IDS.USERS}
        title="Users"
        mods={{ "full-width": true }}
      >
        {UserUtils.isXGSAdministrator(userState.profile) && <DownloadButton
          title="Download CSV"
          spinner={loadingState?.downloadStarted}
          disabled={usersState.fetch_was_started && usersState.requestCreator === "GET_USERS"}
          onClick={() => {
            !loadingState?.downloadStarted && download();
          }}
        />}
      </ContentContainerToolbar>
    }
    >    
      <div className="xgs-customers-users">
        <div className="xgs-list__controls">          
            <Formik
              onSubmit={onSearchStart}
              initialValues={initialValues}
              innerRef={filterFormRef}
            >
            {(props: FormikProps<SearchCustomersModel>) => (
              <Form className="xgs-list__controls__search" style={{ width: "100%" }}>
                <XGSFormInput
                  name="search"
                  label="Name"
                  labelMode={LabelModes.column}
                  className="xgs-labeled-input"
                  type="text"
                  placeholder="First, last name, email"
                />
                <XGSFormInput
                  name="company"
                  label="Company"
                  labelMode={LabelModes.column}
                  className="xgs-labeled-input"
                  type="text"
                  placeholder="Company, customer"
                />
                <XGSFormSelect
                  name="role"
                  label="Role"
                  labelMode={LabelModes.column}
                  className="xgs-labeled-input"
                  isClearable
                  options={userRoleOptions}
                  controlled={true}
                />
                <XGSFormSelect
                  name="status"
                  label="Status"
                  labelMode={LabelModes.column}
                  className="xgs-labeled-input"
                  isClearable
                  options={userStatusOptions}
                  controlled={true}
                />
                <Button
                  type="submit"
                  className="xgs-list__controls__button"
                  theme={ButtonThemes.blue}
                  disabled={usersState.fetch_was_started && usersState.requestCreator === "GET_USERS"}
                >
                  Search
                </Button>
                <Button
                  className="xgs-list__controls__button"
                  theme={ButtonThemes.gray}
                  onClick={onSearchClear}>
                  Clear
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        {usersState.fetch_was_failed && (
          <XGSErrorMessage>{usersState.fetch_fail_reason}</XGSErrorMessage>
        )}
        {!usersState.fetch_was_failed && (
          <div className="xgs-list__table-wrapper">
            <div className="xgs-list__table">
              <Table
                autoHeight={true}
                isLoading={usersState.fetch_was_started && usersState.requestCreator === "GET_USERS"}
                columns={columns}
                data={usersState.users}
                onRowClicked={onUserClick}
                cursorPointer={true}
                rowHeight={64}
                minTableHeight={420}
                noResultsText="There are no matching users"
                infiniteScroll={true}
                infiniteScrollLoading={usersState.fetchPortion_was_started}
                infiniteScrollHasNext={!usersState.usersFetchedAll}
                onInfiniteScroll={onListInfiniteScroll}
                responsive={true}
              />
              <UserDetails
                id={userId}
                show={!!userId}
                onClose={() => {
                  setUserId(undefined);
                }}
                onDelete={() => {
                  dispatch(getAllUsers(false, filterFormRef.current.values));
                }}
                additionalFields={true}
              />
            </div>
          </div>
        )}
      </div>
    </ContentContainer>
  );
});

export default CustomersUsers;
