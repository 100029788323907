import {
  DoorsResponseModel,
  DoorDetailsResponseModel,
  InvolvedUsersResponseModel,
  SummaryResponseModel
} from "../../app/data/dock-loading/models";

export default interface DockLoadingState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError: string;
  requestCreator: string;
  summary: SummaryResponseModel | null;
  doors: DoorsResponseModel[];
  door: DoorDetailsResponseModel | null;
  involvedUsers: InvolvedUsersResponseModel[];
}

export const initialDockLoadingState: DockLoadingState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: "",
  requestCreator: "",
  summary: null,
  doors: [],
  door: null,
  involvedUsers: []
};
