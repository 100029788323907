import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ContentContainer from "../../templates/content-container/contentContainer";
import { PAGE_IDS } from "../../app/data/common/pageIds";
import ConfirmationModal from "../../ui-components/confirmation-modal/confirmationModal";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import XGSFormInput from "../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledInput from "../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import Help from "../../ui-components/help/help";
import Tag from "../../ui-components/molecules/tag/tag";
import XGSCheckbox from "../../ui-components/xgs-checkbox/xgsCheckbox";
import { TagColor } from "../../app/data/common/tagColor";
import {
  AnnouncementType,
  EmergencyAlertFormModel,
  EmergencyAlertSchema,
  EmergencyAlertStatusType,
  EmergencyAlertResponseModel
} from "../../app/data/emergency-alert/models";
import EmergencyAlertState from "../../slices/emergency-alert/EmergencyAlertState";
import {
  getEmergencyAlert,
  changeEmergencyAlertStatus,
  pushEmergencyAlertToAnnouncements,
  resetErrors,
  saveDraftEmergencyAlert,
  emergencyAlertSelector
} from "../../slices/emergency-alert/emergencyAlertSlice";
import "../../sass/forms.scss";
import "./emergencyAlertManagement.scss";
import checkImg from "../../images/check_circle_green.svg";
import crossImg from "../../images/cross_red.svg";

let initialValues: EmergencyAlertFormModel = {
  header: "",
  content: ""
};

const quillModules = {
  clipboard: {
    matchVisual: false
  },
  toolbar: [
    [{ "size": ["small", false, "large"] }],
    ["bold", "italic", "underline"],
    [{"list": "bullet"}],
    ["link"],
    ["clean"]
  ]
};

type StatusChangeType = EmergencyAlertStatusType | undefined;

const EmergencyAlertManagement: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const emergencyAlertState: EmergencyAlertState = useSelector(emergencyAlertSelector);
  const [content, setContent] = useState("");
  const [showPushConfirmation, setShowPushConfirmation] = useState(false);
  const [showChangeStatusConfirmation, setShowChangeStatusConfirmation] = useState<boolean>(false);
  const [changeStatusConfirmationMode, setChangeStatusConfirmationMode] = useState<StatusChangeType>(undefined);
  const [announcementTypes, setAnnouncementTypes] = useState<AnnouncementType[]>([]);
  const emergencyAlertFormRef = useRef<any>(null);

  const onSave = (request: EmergencyAlertFormModel, callback?: () => void) => {
    dispatch(saveDraftEmergencyAlert({
      ...request,
      status: "DRAFT"
    }, () => {
      toast.info("Draft emergency alert saved!");
      callback && callback();
    }));
  };

  const onClear = () => {
    initialValues.header = (emergencyAlertState.draftEmergencyAlert?.header || "");
    emergencyAlertFormRef.current?.setFieldValue("header", emergencyAlertState.draftEmergencyAlert?.header || "");
    initialValues.content = (emergencyAlertState.draftEmergencyAlert?.content || "");
    setContent(emergencyAlertState.draftEmergencyAlert?.content || "");
  };

  const onChangeEmergencyAlertStatus = (status: StatusChangeType) => {
    if (!status) return;
    dispatch(changeEmergencyAlertStatus(status, () => {
      toast.info(`The emergency alert has been ${status === "PUBLISHED" ? "" : "un"}published!`);
      setShowChangeStatusConfirmation(false);
    }));
  };

  const onPublish = () => {
    if (valuesWereChanged(emergencyAlertFormRef.current?.values)) {
      onSave(emergencyAlertFormRef.current?.values, () => {
        onChangeEmergencyAlertStatus("PUBLISHED");
      });
    } else {
      onChangeEmergencyAlertStatus("PUBLISHED");
    }
  };

  const hasUnpublishedChanges = () => {
    if (!emergencyAlertState.draftEmergencyAlert) return false;
    if (!emergencyAlertState.publishedEmergencyAlert) return true;
    if (emergencyAlertState.publishedEmergencyAlert.header !== emergencyAlertState.draftEmergencyAlert.header) return true;
    if (emergencyAlertState.publishedEmergencyAlert.content !== emergencyAlertState.draftEmergencyAlert.content) return true;
  };

  const valuesWereChanged = (formValues: EmergencyAlertFormModel = emergencyAlertFormRef.current?.values) => {
    if (!formValues) return false;
    return (emergencyAlertState.draftEmergencyAlert?.header !== formValues.header) || (emergencyAlertState.draftEmergencyAlert?.content !== formValues.content);
  };

  const publishedAlertBlock = () => (
    <div className="xgs-emergency-alert__preview__area">
      <div className="xgs-emergency-alert__preview__header">
        {emergencyAlertState.publishedEmergencyAlert?.header}
      </div>
      <div
        className="xgs-emergency-alert__preview__body"
        dangerouslySetInnerHTML={{__html: emergencyAlertState.publishedEmergencyAlert?.content || ""}}>
      </div>
    </div>
  );

  useEffect(() => {
    dispatch(getEmergencyAlert((emergencyAlert: EmergencyAlertResponseModel | null) => {
      emergencyAlertFormRef.current?.setFieldValue("header", emergencyAlert?.header || "");
      emergencyAlertFormRef.current?.setFieldValue("content", emergencyAlert?.content || "", false);
      setContent(emergencyAlert?.content || "");
    }));

    return () => {
      dispatch(resetErrors());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    initialValues.header = (emergencyAlertState.draftEmergencyAlert?.header || "");
    initialValues.content = (emergencyAlertState.draftEmergencyAlert?.content || "");
    setContent(emergencyAlertState.draftEmergencyAlert?.content || "");
  }, [emergencyAlertState.draftEmergencyAlert?.header, emergencyAlertState.draftEmergencyAlert?.content]);

  useEffect(() => {
    setAnnouncementTypes([]);
  }, [showPushConfirmation]);

  return (
    <ContentContainer
      pageId={PAGE_IDS.EMERGENCY_ALERT_MANAGEMENT}
      title="Emergency Alert"
      isLoading={emergencyAlertState.requestStarted && emergencyAlertState.requestCreator === "GET"}
    >
      <div className="xgs-emergency-alert">
        <div>
          <div className="xgs-emergency-alert__header">
            <div>
              Edit Content
            </div>
            <div className="xgs-emergency-alert__help">
              <Help header="Emergency Alert Help">
                <div className="help__description">
                  The Emergency Alert is a notification displayed on the official website, <a href="https://www.xgsi.com" target="_blank" rel="noreferrer" className="blue-link">www.xgsi.com</a>, to inform visitors about weather-related or other emergency updates.<br /><br />
                  Follow these steps to manage the emergency alert:
                </div>
                <div className="help__item"><strong>Make Updates</strong><br />Enter or modify the emergency information to ensure accuracy and relevance.</div>
                <div className="help__item"><strong>Save</strong><br />You can save draft version at any time. Treat this as a draft. Saving will not automatically publish updated information.</div>
                <div className="help__item"><strong>Publish</strong><br />Once satisfied with the text, click the Publish button to make the changes live on the website.</div>
                <div className="help__item"><strong>Unpublish</strong><br />When the notification is no longer needed, click the Unpublish button to remove it from the website.</div>
                <div className="help__item"><strong>Push to Announcements</strong><br />You can also push published information to the Announcements of the MyXGS/OneXGS portals. In this case, all active announcements will be terminated and new announcements with an emergency alert will be created.</div>
                <div className="help__item"><strong>Monitor for Updates</strong><br />Regularly check for emergency status or new weather information and repeat the process as needed to keep users informed.</div>
                Note: Ensure all information is clear, concise, and promptly updated to maintain trust and reliability.
              </Help>
            </div>
          </div>
          <div className="xgs-emergency-alert__form">
            <Formik
              onSubmit={(values) => onSave(values)}
              initialValues={initialValues}
              validationSchema={EmergencyAlertSchema}
              innerRef={emergencyAlertFormRef}
            >
              {(props: FormikProps<EmergencyAlertFormModel>) => (
                <Form>
                  {valuesWereChanged(props.values) && props.isValid && props.dirty && !(emergencyAlertState.requestStarted && emergencyAlertState.requestCreator === "GET") && (
                    <Tag
                      mods={{color: TagColor.RED}}
                      mix="xgs-emergency-alert__block__status"
                    >
                      Unsaved Changes
                    </Tag>
                  )}
                  {hasUnpublishedChanges()
                    && !valuesWereChanged(props.values)
                    && emergencyAlertState.publishedEmergencyAlert
                    && (
                    <Tag
                      mods={{color: TagColor.RED}}
                      mix="xgs-emergency-alert__block__status"
                    >
                      Unpublished Changes
                    </Tag>
                  )}
                  <XGSFormInput
                    type="text"
                    name="header"
                    label="Alert Header:"
                    required={true}
                    requiredAsteriskDisabled={false}
                    labelMode={LabelModes.column}
                    className="xgs-emergency-alert__field"
                  />
                  <div className="xgs-emergency-alert__field">
                    <LabeledInput
                      label="Alert Body:"
                      labelMode={LabelModes.column}
                      required={true}
                      requiredAsteriskDisabled={false}
                      isFailed={() => !!(props.touched.content && props.errors.content)}
                      error={props.errors.content || ""}
                      className="xgs-visual-editor"
                    >
                    <ReactQuill
                      theme="snow"
                      value={content}
                      modules={quillModules}
                      onChange={(value, _delta, _source, editor) => {
                        const text = editor.getText().replace(/(\r\n|\n|\r)/gm, "");
                        if (text) {
                          setContent(value);
                          props.setFieldValue("content", value);
                        } else {
                          setTimeout(() => {
                            setContent("");
                            props.setFieldValue("content", "");
                          }, 50);
                        }
                        props.setFieldTouched("content", true, false);
                        props.setFieldValue("content", value);
                      }}
                      onBlur={() => {
                        props.setFieldTouched("content", true, false);
                      }}
                      bounds=".xgs-visual-editor"
                    />
                    </LabeledInput>
                  </div>
                  {emergencyAlertState.requestFailed && (
                    <XGSErrorMessage className="xgs-emergency-alert__error">{emergencyAlertState.requestError}</XGSErrorMessage>
                  )}
                  <div className="xgs-emergency-alert__buttons xgs-emergency-alert__buttons--main">
                    <Button
                      theme={ButtonThemes.blue}
                      className="xgs-emergency-alert__button xgs-emergency-alert__button--publish"
                      onClick={() => {
                        setChangeStatusConfirmationMode("PUBLISHED");
                        setShowChangeStatusConfirmation(true);
                      }}
                      type="button"
                      disabled={
                        (!hasUnpublishedChanges() && !valuesWereChanged(props.values))
                        || (emergencyAlertState.requestStarted && (emergencyAlertState.requestCreator === "SET_STATUS" || emergencyAlertState.requestCreator === "GET"))
                        || !props.isValid
                      }
                    >
                      {(!hasUnpublishedChanges() && !valuesWereChanged(props.values)) ? (
                        <><img src={checkImg} alt="check" /> Published</>
                      ) : (
                        <>Publish</>
                      )}
                    </Button>
                    <Button
                      theme={ButtonThemes.blue}
                      className="xgs-emergency-alert__button xgs-emergency-alert__button--save"
                      type="submit"
                      disabled={!valuesWereChanged(props.values) || !props.isValid || !props.dirty || (emergencyAlertState.requestStarted && emergencyAlertState.requestCreator === "SAVE")}
                      spinner={emergencyAlertState.requestStarted && emergencyAlertState.requestCreator === "SAVE"}
                    >
                      {!valuesWereChanged(props.values) ? (
                        <><img src={checkImg} alt="check" /> Saved</>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                    <Button
                      theme={ButtonThemes.gray}
                      className="xgs-emergency-alert__button"
                      type="button"
                      onClick={onClear}
                      disabled={
                        !valuesWereChanged(props.values) ||
                        (emergencyAlertState.requestStarted &&
                          (emergencyAlertState.requestCreator === "GET" ||
                            emergencyAlertState.requestCreator === "SAVE" ||
                            emergencyAlertState.requestCreator === "SET_STATUS"
                          )
                        )
                      }
                    >
                      Reset
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {emergencyAlertState.publishedEmergencyAlert && (
          <div>
            <div className="xgs-emergency-alert__header">
              Published Content
            </div>
            <div className="xgs-emergency-alert__state">
              <div className="xgs-emergency-alert__control">
                <div className="xgs-emergency-alert__control__text">
                  The Emergency Alert is currently published on the website and visible to visitors. If you want to hide it, then press &quot;Unpublish&quot;. You can also push published information to the Announcements of the MyXGS/OneXGS portals by clicking &quot;Push to Announcements&quot;. In this case, all active announcements will be terminated and new announcements with an emergency alert will be created.
                </div>
                <div className="xgs-emergency-alert__control__buttons">
                  <Button
                    theme={ButtonThemes.redInverted}
                    className="xgs-emergency-alert__button xgs-emergency-alert__button--unpublish"
                    onClick={() => {
                      setChangeStatusConfirmationMode("UNPUBLISHED");
                      setShowChangeStatusConfirmation(true);
                    }}
                    type="button"
                    disabled={emergencyAlertState.requestStarted && (emergencyAlertState.requestCreator === "SET_STATUS" || emergencyAlertState.requestCreator === "GET")}
                  >
                    <img src={crossImg} alt="cross" /> Unpublish
                  </Button>
                  <Button
                    theme={ButtonThemes.blueInverted}
                    className="xgs-emergency-alert__button xgs-emergency-alert__button--push-to-announcements"
                    onClick={() => setShowPushConfirmation(true)}
                    type="button"
                    disabled={emergencyAlertState.requestStarted && (emergencyAlertState.requestCreator === "PUSH_TO_ANNOUNCEMENTS" || emergencyAlertState.requestCreator === "GET")}
                  >
                    {emergencyAlertState.publishedEmergencyAlert.announcementFlag ? (
                      <><img src={checkImg} alt="check" /> Pushed to Announcements</>
                    ) : (
                      <>Push to Announcements</>
                    )}
                  </Button>
                </div>
              </div>
              {emergencyAlertState.publishedEmergencyAlert && (
                <div className="xgs-emergency-alert__preview">
                  {publishedAlertBlock()}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <ConfirmationModal
        opened={showChangeStatusConfirmation}
        header={changeStatusConfirmationMode === "UNPUBLISHED" ? "Unpublish emergency alert" : "Publish emergency alert"}
        confirmButtonText={changeStatusConfirmationMode === "UNPUBLISHED" ? "Unpublish" : "Publish"}
        spinner={emergencyAlertState.requestStarted && emergencyAlertState.requestCreator === "SET_STATUS"}
        onCancel={() => setShowChangeStatusConfirmation(false)}
        onConfirm={() => changeStatusConfirmationMode === "UNPUBLISHED" ? onChangeEmergencyAlertStatus(changeStatusConfirmationMode) : onPublish()}
      >
        {changeStatusConfirmationMode === "UNPUBLISHED" ? "This emergency alert will be removed from the public website." : "This emergency alert will be published on the public website."}
      </ConfirmationModal>
      <ConfirmationModal
        opened={showPushConfirmation}
        header="Push to Announcements"
        confirmButtonText="Yes, push!"
        disableConfirmButton={announcementTypes.length === 0}
        spinner={emergencyAlertState.requestStarted && emergencyAlertState.requestCreator === "PUSH_TO_ANNOUNCEMENTS"}
        onCancel={() => setShowPushConfirmation(false)}
        onConfirm={() => dispatch(pushEmergencyAlertToAnnouncements(announcementTypes, () => {
          setShowPushConfirmation(false);
          toast.info("The emergency alert has been pushed to announcements!");
        }))}
      >
        All active announcements will be terminated and new announcements with the emergency alert will be created. The original information from the current announcements will be lost.
        <div className="xgs-emergency-alert__announcement-groups">
          Select the user groups for which the system should create new announcements based on the emergency alert. Then, confirm the action by clicking &quot;Yes, Push!&quot;
          <div className="xgs-emergency-alert__announcement-groups__checkboxes">
            <XGSCheckbox
              name="customers"
              onChange={() => {
                if (announcementTypes.find(type => type === "CUSTOMER")) {
                  setAnnouncementTypes(announcementTypes.find(type => type === "EMPLOYEE") ? ["EMPLOYEE"] : []);
                } else {
                  setAnnouncementTypes(["CUSTOMER", ...announcementTypes]);
                }
              }}
              checked={!!announcementTypes.find(type => type === "CUSTOMER")}
            >
              Customers
            </XGSCheckbox>
            <XGSCheckbox
              name="employees"
              onChange={() => {
                if (announcementTypes.find(type => type === "EMPLOYEE")) {
                  setAnnouncementTypes(announcementTypes.find(type => type === "CUSTOMER") ? ["CUSTOMER"] : []);
                } else {
                  setAnnouncementTypes(["EMPLOYEE", ...announcementTypes])
                }
              }}
              checked={!!announcementTypes.find(type => type === "EMPLOYEE")}
            >
              Employees
            </XGSCheckbox>
          </div>
        </div>
      </ConfirmationModal>
    </ContentContainer>
  );
};

export default EmergencyAlertManagement;
