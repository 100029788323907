import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";
import { SteppedProgressDataModel } from "../tracking/ShipmentDetailsModel";

export interface ShipmentShareModel {
  email: string;
};

export const ShipmentShareSchema: Yup.ObjectSchema<ShipmentShareModel> = Yup.object().shape({
  email: Yup.string().max(60, "The value must be no more than 60 characters").email(validationMessages.notValid).required(validationMessages.required)
}).defined();

export interface PublicShipmentDetailsModel {
  probill: number;
  bol: string;
  po: string;
  status: {
    title?: string;
    description?: string;
    translate?: string;
  };
  eta: {
    originalEta: string;
    expectedEta: string;
    notes: string[];
  };
  origin: {
    id: number;
    name: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
  };
  destination: {
    id: number;
    name: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
  };
  pieces: number;
  weight: number;
  progress?: SteppedProgressDataModel[];
  trailer?: any;
  invoice?: any;
  pod?: {
    date: string;
    time: string;
    signature: string;
  };
  showOnMap: boolean;
};
