import {
  BolListItemModel,
  BolConsigneeModel,
  BolPayorModel
} from "../../app/data/bol/models";
import { ItemModel } from "../../app/data/common/models";
import { SubAccountMinimal } from "../../app/data/account/SubAccount";

export default interface BolState {
  bols: BolListItemModel[];
  bolsRequest: any;
  bolsFetchedAll: boolean;
  fetchStarted: boolean;
  fetchPortionStarted: boolean;
  fetchSucceed: boolean;
  fetchFailed: boolean;
  fetchError: string;
  fromRequestRates: boolean;
  shippers: SubAccountMinimal[] | null;
  shipper: number | null;
  bolNumber: string;
  poNumber: string;
  payType: string;
  specialInstructions: string | undefined;
  pickupRequest: boolean;
  pickupDate: string | undefined;
  pickupReadyTime: string | undefined;
  pickupCloseTime: string | undefined;
  willCall: boolean;
  consignee: BolConsigneeModel;
  consignees: BolConsigneeModel[] | null;
  payor?: BolPayorModel;
  items: ItemModel[];
  quoteNumber?: number;
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestCreator: string;
  requestError: string;
  pickupAvailable: boolean;
  consigneeCheckResult: boolean;
  consigneeZipChecked: string;
  csvLink: string;
  requestRatesPickupZip: string;
}

export const initialBolState: BolState = {
  bols: [],
  bolsRequest: null,
  bolsFetchedAll: false,
  fetchStarted: false,
  fetchPortionStarted: false,
  fetchSucceed: false,
  fetchFailed: false,
  fetchError: "",
  fromRequestRates: false,
  shippers: null,
  shipper: null,
  bolNumber: "",
  poNumber: "",
  payType: "",
  specialInstructions: "",
  pickupRequest: false,
  pickupDate: undefined,
  pickupReadyTime: undefined,
  pickupCloseTime: undefined,
  willCall: false,
  consignee: {
    name: "",
    address: {
      address1: "",
      city: "",
      postalCode: "",
      state: "",
      additionalAddressLine: "",
    },
    phone: ""
  },
  consignees: null,
  payor: undefined,
  items: [],
  quoteNumber: undefined,
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestCreator: "",
  requestError: "",
  pickupAvailable: false,
  consigneeCheckResult: false,
  consigneeZipChecked: "",
  csvLink: "",
  requestRatesPickupZip: ""
};
