import React, { useEffect } from "react";
import ContentContainer from "../../templates/content-container/contentContainer";
import "./warehouseSummary.scss";
import lowQuanity from "../../images/low_quantity_sku.svg"
import Table from "../../ui-components/table/table";
import { lowQuanitySkuColumns } from "./lowQuantityColumns";
import { topSellingColumns } from "./topSellingColumns";
import topSellingIcon from "../../images/top-selling-skus.svg";
import boxIcon from "../../images/box__blue-bg.svg";
import infoIcon from "../../images/info_grey.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchLowQuantityItems, fetchTopSellingItems, fetchWarehouseLocationSummary, resetWarehouseInventoryState, warehouseInventorySelector } from "../../slices/warehouse-inventory/warehouseInventorySlice";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import { ReactComponent as ArrowRight } from "../../images/arrow_right.svg";
import { useHistory } from "react-router-dom";
import { Routes } from "../../app/route/RoutesConfig";
import { PAGE_IDS } from "../../app/data/common/pageIds";

export const WarehouseSummary = () => {
  const dispatch = useDispatch();
  const warehouseInventoryState = useSelector(warehouseInventorySelector);
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchTopSellingItems());
    dispatch(fetchLowQuantityItems());
    dispatch(fetchWarehouseLocationSummary());
    return () => {
      dispatch(resetWarehouseInventoryState());
    }
  }, [dispatch])

  const isLoading = warehouseInventoryState.loadingLocationSummary || warehouseInventoryState.loadingLowQuantityItems || warehouseInventoryState.loadingTopSellingItems;

  const goToInventoryPage = (terminalNumber: number) => {
    history.push(Routes.warehouseInventory.inventory + `?loc=${terminalNumber}`)
  }

  return (
    <ContentContainer
      mods={{ "full-width": true }}
      pageId={PAGE_IDS.WAREHOUSE_SUMMARY}
      title="Summary"
      isLoading={isLoading}
    >
      {!isLoading && <>
        <div className="xgs-warehouse-summary__summary-blocks">
            {warehouseInventoryState.locationSummary.map((summary) => (
              <div className="xgs-warehouse-summary__summary-block">
                <div className="xgs-warehouse-summary__summary-block__title">
                  {summary.locationTerminalName.toLowerCase().capitalize()} 
                  <Button onClick={() => goToInventoryPage(summary.locationTerminalNumber)} theme={ButtonThemes.white}>View &nbsp;<ArrowRight /></Button>
                </div>
                <div className="xgs-warehouse-summary__summary-block__values">Total SKUs: <span>{summary.totalSkus}</span></div>
                <div className="xgs-warehouse-summary__summary-block__values">
                  Low Quantity: <span>{summary.lowQuantitySkus} {summary.lowQuantitySkus === 1 ? "SKU" : "SKUs"}</span>
                </div>
                <div className="xgs-warehouse-summary__summary-block__values">
                  No Items: <span>{summary.noQuantitySkus} {summary.noQuantitySkus === 1 ? "SKU" : "SKUs"}</span>
                </div>
                <div className="xgs-warehouse-summary__summary-block__values">
                  Total weight: <span>{summary.totalWeight} {summary.totalWeight > 1 ? "lbs" : "lb"}</span>
                </div>
                <div className="xgs-warehouse-summary__summary-block__values">
                  Total SqYds: <span>{summary.totalSquareYards}</span>
                </div>
              </div>))
            }
        </div>
        <div className="xgs-warehouse-summary__info"><img src={infoIcon} alt=""/>&nbsp;&nbsp;Following data provided for last 30 days.</div>
        <div className="xgs-warehouse-summary__sections">
          <div className="xgs-warehouse-summary__section">
            <div className="xgs-warehouse-summary__section__title">
              <img src={lowQuanity} alt=""/>&nbsp;&nbsp;Low Quantity SKUs
            </div>
            <div className="xgs-warehouse-summary__section__body">
              <Table
                data={warehouseInventoryState.lowQuantityItems}
                columns={lowQuanitySkuColumns}
              />
            </div>
            <div className="xgs-warehouse-summary__section__body--small">
              <div className="xgs-warehouse-summary__section__cards">
                {warehouseInventoryState.lowQuantityItems.map(sku => (
                  <div className="xgs-warehouse-summary__card">
                    <div className="xgs-warehouse-summary__card__title">
                      <img src={boxIcon} alt="box"/>
                      <div>
                        <span>{sku.sku}</span><br/>
                        {sku.description}
                      </div>
                    </div>
                    <div className="xgs-warehouse-summary__card__body">
                      <div className="xgs-warehouse-summary__card__body__item">
                        <div className="xgs-warehouse-summary__card__body__item__label">
                          Warehouse:
                        </div>
                        <div className="xgs-warehouse-summary__card__body__item__value">{sku.locationAirportCode} - {sku.locationTerminalName} <span>{sku.locationTerminalNumber}</span></div>
                      </div>
                      <div className="xgs-warehouse-summary__card__body__item">
                        <div className="xgs-warehouse-summary__card__body__item__label">
                          Orders:
                        </div>
                        <div className="xgs-warehouse-summary__card__body__item__value">{sku.qtyAvailableToOrder}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="xgs-warehouse-summary__section">
            <div className="xgs-warehouse-summary__section__title">
              <img src={topSellingIcon} alt=""/>&nbsp;&nbsp;Top Selling SKUs
            </div>
            <div className="xgs-warehouse-summary__section__body">
              <Table
                data={warehouseInventoryState.topSellingItems}
                columns={topSellingColumns}
              />
            </div>
            <div className="xgs-warehouse-summary__section__body--small">
              <div className="xgs-warehouse-summary__section__cards">
                {warehouseInventoryState.topSellingItems.map(sku => (
                  <div className="xgs-warehouse-summary__card">
                    <div className="xgs-warehouse-summary__card__title">
                      <img src={boxIcon} alt="box"/>
                      <div>
                        <span>{sku.sku}</span><br/>
                        {sku.description}
                      </div>
                    </div>
                    <div className="xgs-warehouse-summary__card__body">
                      <div className="xgs-warehouse-summary__card__body__item">
                        <div className="xgs-warehouse-summary__card__body__item__label">
                          Warehouse:
                        </div>
                        <div className="xgs-warehouse-summary__card__body__item__value">{sku.locationAirportCode} - {sku.locationTerminalName} <span>{sku.locationTerminalNumber}</span></div>
                      </div>
                      <div className="xgs-warehouse-summary__card__body__item">
                        <div className="xgs-warehouse-summary__card__body__item__label">
                          Orders:
                        </div>
                        <div className="xgs-warehouse-summary__card__body__item__value">{sku.numberOfShipments}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>}
    </ContentContainer>
  )
}