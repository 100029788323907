import axios, { AxiosResponse } from "axios";
import api, { ApiResponse } from "./../api";
import {
  ConfirmDataRequestModel,
  ConfirmEmailRequestModel,
  ForgotPasswordRequest,
  LoginFormModel,
  PortalRegistrationModel,
  ProfilePasswordRequestModel,
  ProfileSettingsRequestModel,
  ResetPasswordRequestModel,
  RegistrationApproveModel,
  RequestRejectModel,
  ShipperApproveModel,
  SignupByInviteRequestModel,
  UserPasswordChangeRequestModel
} from "./requestModels"
import { FavoritePageModel, TokenRequestModel } from "./models";
import LegacyUser from "./LegacyUser";
import { apiQueryAdapter } from "../apiQueryAdapter";

class UserService {
  private static instance: UserService;
  private constructor() {}
  static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  public confirmEmail = async (request: ConfirmEmailRequestModel): Promise<ApiResponse<any>> => {
    return await api.post("/users/verifyEmail", request);
  };

  public getCurrent = async (): Promise<ApiResponse<any>> => {
    return await api.get(`/users/current`);
  };

  public get = async (id: string): Promise<ApiResponse<any>> => {
    return await api.get(`/users/${id}`);
  };

  public getUsers = async (): Promise<ApiResponse<any>> => {
    return await api.get("/users/");
  };

  public getAccountInfo = async (accountNumber: number | string): Promise<ApiResponse<any>> => {
    return await api.get(`/accounts/legacy/${accountNumber}`);
  };

  public sendForgotPasswordRequest = async (
    request: ForgotPasswordRequest
  ): Promise<ApiResponse<any>> => {
    return await api.post("/users/forgot-password-request", request);
  };

  public getLegacyUserByResetPasswordToken = async (
    resetToken: string
  ): Promise<ApiResponse<LegacyUser>> => {
    return await api.get(`/users/byToken?token=${resetToken}`);
  };

  public submitResetPassword = async (
    request: ResetPasswordRequestModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/users/reset-password", request);
  };

  public updateProfile = async (
    userId: string,
    request: ProfileSettingsRequestModel
  ): Promise<ApiResponse<any>> => {
    return await api.post(`/users/${userId}/updateProfile`, request);
  };

  public updatePassword = async (
    request: ProfilePasswordRequestModel
  ): Promise<ApiResponse<any>> => {
    return await api.post(`/users/update-password`, request);
  };

  public changeUserPassword = async (
    request: UserPasswordChangeRequestModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/users/force-update-password", request);
  };

  public checkEmailToken = async (
    token: string
  ): Promise<ApiResponse<any>> => {
    return await api.post("/users/confirm-email-change", { token });
  };

  public cancelEmailChange = async (): Promise<ApiResponse<any>> => {
    return await api.post("/users/cancel-email-change");
  };

  public checkInvite = async (
    token: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/users/invite/${token}`);
  };

  public signupByInvite = async (
    request: SignupByInviteRequestModel
  ): Promise<ApiResponse<any>> => {
    return await api.put("/users", request);
  };

  public portalRegistration = async (
    request: PortalRegistrationModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/users/registration", request);
  };

  public checkLegacyUser = async (
    request: LoginFormModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/users/legacy", request);
  };

  public checkLegacyUserEmail = async (
    username: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/users/emailFor?username=${username}`);
  };

  public confirmLegacyUserData = async (
    request: ConfirmDataRequestModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/users/legacy/profile", request);
  };

  public checkConfirmLegacyUserToken = async (
    token: string
  ): Promise<ApiResponse<any>> => {
    return await api.post("/users/legacy/confirm", { token });
  };

  public getPendingRequests = async (
    lastIds?: string
  ): Promise<ApiResponse<any>> => {
    const params = new URLSearchParams();
    lastIds && params.append("lastIds", lastIds);
    return await api.get("/customer-requests", { params });
  };

  public approveRegistrationRequest = async (
    payload: RegistrationApproveModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/users/registration/accept", payload);
  };

  public approveShipperRequest = async (
    payload: ShipperApproveModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/customer-requests/shippers/accept", payload);
  };

  public rejectRegistrationRequest = async (
    payload: RequestRejectModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/users/registration/reject", payload);
  };

  public rejectShipperRequest = async (
    payload: RequestRejectModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/customer-requests/shippers/reject", payload);
  };

  public impersonateUser = async (
    email: string
  ): Promise<ApiResponse<any>> => {
    return await api.post(`/impersonate?username=${encodeURIComponent(email)}`);
  };

  public impersonateAgent = async (
    email: string
  ): Promise<ApiResponse<any>> => {
    return await api.post(`/impersonate/agent?username=${encodeURIComponent(email)}`);
  };

  public getAdditionalSettings = async (): Promise<ApiResponse<any>> => {
    return await api.get("/users/settings");
  };

  public getPortalState = async (): Promise<ApiResponse<any>> => {
    return await api.get("/state");
  };

  public getAuthToken = async (
    request: TokenRequestModel
  ): Promise<AxiosResponse<any>> => {
    const params = new URLSearchParams(request).toString();
    axios.interceptors.response.use((response) => response, (error) => error);
    return await axios.post(process.env.REACT_APP_TOKEN_ENDPOINT || "", params, {
      headers: {
        "Content-type": "application/x-www-form-urlencoded"
      }
    });
  };

  public logout = async (): Promise<AxiosResponse<any>> => {
    const params = new URLSearchParams();
    params.append("id_token_hint", localStorage.getItem("xgs-id-token") || "");
    params.append("post_logout_redirect_uri", process.env.REACT_APP_DOMAIN || "");
    axios.interceptors.response.use((response) => response, (error) => error);
    return await axios.get(process.env.REACT_APP_LOGOUT_ENDPOINT || "", { params });
  };

  public silentLogout = async (): Promise<ApiResponse<any>> => {
    return await api.post("/users/logout");
  };

  public getFavorites = async (): Promise<ApiResponse<FavoritePageModel[]>> => {
    return await apiQueryAdapter.query("/favorites",
      "GET",
    )
  };

  public addFavorite = async (pageId: string): Promise<ApiResponse<FavoritePageModel>> => {
    return await apiQueryAdapter.query("/favorites",
      "POST",
      { params: { favoritePageId: pageId } }
    )
  };

  public removeFavorite = async (pageId: string): Promise<ApiResponse<FavoritePageModel>> => {
    return await apiQueryAdapter.query(`/favorites/${pageId}`,
      "DELETE",
    )
  };
}

export default UserService;
