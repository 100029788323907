import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SteppedProgress, { SteppedProgressStep } from "../../../ui-components/stepped-progress/steppedProgress";
import XGSNavLink from "../../../ui-components/xgs-nav-link/xgsNavLink";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import {
  shipmentDetailsSelector,
  getTrackingHistory
} from "../../../slices/shipment-details/shipmentDetailsSlice";
import {
  ShipmentTrackingHistoryEventModel,
  ShipmentTrackingHistoryModel
} from "../../../app/data/tracking/ShipmentDetailsModel";
import { UserUtils } from "../../../app/data/user/userUtils";
import { userSelector } from "../../../slices/user/userSlice";
import { cn } from "../../../services/common/className";
import { Routes } from "../../../app/route/RoutesConfig";
import Loading from "../../../ui-components/loading/loading";

export interface ShipmentTrackingHistoryProps {
  probillNumber: number;
  inSidebar?: boolean;
}

const ShipmentTrackingHistory: React.FC<ShipmentTrackingHistoryProps> = ({probillNumber, inSidebar}) => {
  const dispatch = useDispatch();
  const shipmentDetailsState = useSelector(shipmentDetailsSelector);
  const userState = useSelector(userSelector);

  const generateStepDetails = (step: ShipmentTrackingHistoryEventModel) => (
    <>
      {step.trailerNumber && <div>Trailer #: <span>{step.trailerNumber}</span></div>}
      {step.manifestNumber && <div>Manifest #: <span>{step.manifestNumber}</span></div>}
      {step.address && (
        <>
        {step.address.isTerminal ? (
          <>
            <div>Terminal: <span>{step.address.airportCode} - {step.address.name} - {step.address.terminal}</span></div>
          </>
        ) : (
          <>
            {step.address.address && <div>Street Address: <span>{step.address.address.toLowerCase().capitalize()}</span></div>}
            <div>City/State/ZIP: <span>{step.address.city ? step.address.city.toLowerCase().capitalize() : ""}, {step.address.state}{step.address.zip ? `, ${step.address.zip}` : ""}</span></div>
          </>
        )}
        </>
      )}
      {step.driverName && <div>Driver: <span>{step.driverName}</span></div>}
      {step.pickupNumber && (
        <div>Pickup #:&nbsp;
          {UserUtils.isEmployee(userState.profile)
            ? (
              <XGSNavLink
                className="xgs-table-link-cell"
                target="_blank"
                to={`${Routes.pickup.log}/${step.pickupNumber}`}
              >
                {step.pickupNumber}
              </XGSNavLink>
            ) : <>{step.pickupNumber}</>
          }
        </div>
      )}
    </>
  );

  const detailsExist = (step: ShipmentTrackingHistoryEventModel) => !!(step.trailerNumber || step.manifestNumber || step.address || step.driverName || step.pickupNumber);

  const generateProgressSteps = (trackingHistory: ShipmentTrackingHistoryModel) => {
    if (!trackingHistory.pickupEvents && !trackingHistory.linehaulEvents && !trackingHistory.deliveryEvents && !trackingHistory.agentEvents) return [];
    let steps: SteppedProgressStep[] = [];
    if (trackingHistory.allEvents?.length > 0) {
      let allSteps = trackingHistory.allEvents.map((step) => {
        let newObj = {
          title: <>
            <div>{step.event}</div>
            <div><span>{step.date.toUiDateTimeFormat()}</span></div>
          </>,
          completed: true,
          reached: true,
          details: generateStepDetails(step),
          collapsible: detailsExist(step)
        };
        return newObj;
      });
      steps = [...allSteps];
    }
    if (steps.length > 0) {
      const lastIndex = steps.length - 1;
      steps[lastIndex].completed = false;
      steps[lastIndex].defaultOpened = true;
    }
    if (!trackingHistory.isCancelled && !trackingHistory.isCompleted) {
      steps.push({
        completed: false,
        reached: false
      });
    }
    return steps.reverse();
  };

  const wereEventsProvided = () => {
    if (!shipmentDetailsState.trackingHistory) return false;
    return (shipmentDetailsState.trackingHistory.allEvents?.length > 0);
  };

  useEffect(() => {
    if (!probillNumber) return;
    dispatch(getTrackingHistory(probillNumber));
  }, [dispatch, probillNumber]);

  return (
    <>
      {!inSidebar && (
        <div className="xgs-info-block__header">
          <div className="xgs-info-block__header__title">
            Tracking History
          </div>
        </div>
      )}
      <div className={cn("xgs-shipment-details__tracking-history")({"in-sidebar": inSidebar})}>
        <Loading className="xgs-shipment-details__tracking-history__spinner" isLoading={shipmentDetailsState.trackingHistoryLoading} />
        {!shipmentDetailsState.trackingHistoryLoading && shipmentDetailsState.trackingHistoryLoaded && (
          <>
            <div className="xgs-shipment-details__tracking-history__paired-values">
              <div className="xgs-shipment-details__tracking-history__paired-values__item">
                <div className="xgs-shipment-details__tracking-history__paired-values__item__label">Last Location:</div>
                <div className="xgs-shipment-details__tracking-history__paired-values__item__value">
                  {shipmentDetailsState.trackingHistory?.lastLocation
                    ? (
                      <>
                        {shipmentDetailsState.trackingHistory.lastLocation.address ? <div>{shipmentDetailsState.trackingHistory.lastLocation.address}</div> : ""}
                        {shipmentDetailsState.trackingHistory.lastLocation.city}, {shipmentDetailsState.trackingHistory.lastLocation.state}
                        {shipmentDetailsState.trackingHistory.lastLocation.zip ? `, ${shipmentDetailsState.trackingHistory.lastLocation.zip}` : ""}
                      </>
                    ) : "N/A"
                  }
                </div>
              </div>
              <div className="xgs-shipment-details__tracking-history__paired-values__item">
                <div className="xgs-shipment-details__tracking-history__paired-values__item__label">Last Terminal:</div>
                <div className="xgs-shipment-details__tracking-history__paired-values__item__value">
                  {shipmentDetailsState.trackingHistory?.lastTerminal
                    ? (
                      <>
                        {shipmentDetailsState.trackingHistory.lastTerminal.airportCode} - {shipmentDetailsState.trackingHistory.lastTerminal.name} - {shipmentDetailsState.trackingHistory.lastTerminal.terminal}
                      </>
                    ) : "N/A"
                  }
                </div>
              </div>
            </div>
            <div className="xgs-shipment-details__tracking-history__content">
              {shipmentDetailsState.trackingHistory && wereEventsProvided() && (
                <SteppedProgress
                  steps={generateProgressSteps(shipmentDetailsState.trackingHistory)}
                  vertical
                />
              )}
              {!wereEventsProvided() && (
                <div className="xgs-shipment-details__tracking-history__no-events">There are no events yet for this probill.</div>
              )}
            </div>
          </>
        )}
        {!shipmentDetailsState.trackingHistoryLoading && shipmentDetailsState.trackingHistoryFailed && (
          <XGSErrorMessage className="xgs-shipment-details__tracking-history__error">{shipmentDetailsState.trackingHistoryError}</XGSErrorMessage>
        )}
      </div>
    </>
  );
};

export default ShipmentTrackingHistory;
