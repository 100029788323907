import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { createColumnHelper } from "@tanstack/react-table";

const getFormattedDate = (date: number) => {
  if (!date || !(+date)) return "";
  let dateStr = date.toString();
  let formattedDate = moment(dateStr, "YYYY-MM-DD");
  return formattedDate.format("MMM D, YYYY");
}

const getIssueValue = (planningIssues: any) => {
  if (planningIssues && planningIssues.length) {
    let issuesValue = planningIssues[0].type;
    if (planningIssues.length > 1) {
      return <span>{issuesValue} and <span className="xgs-table-link-cell">{` ${planningIssues.length - 1}`} more</span></span>;
    }
    return issuesValue || "";
  } else return "No Planning Issue";
}

const columnHelper = createColumnHelper<any>();

export const getunplanProbillsColumns = () => [
  columnHelper.accessor("probillNumber", {
    id: "probillNumber",
    header: "Probill",
    cell: (cellProps) => (
      <Link
        className="xgs-table-link-cell"
        to={`/shipments/${cellProps.getValue()}`}
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        {cellProps.getValue()}
      </Link>
    ),
    size: 70,
  }),
  columnHelper.accessor("planningIssues", {
    id: "planningIssues",
    header: "Issue",
    cell: (cellProps) => (
      <>
        {getIssueValue(cellProps.getValue())}
      </>
    ),    
    size: 120,
  }),
  columnHelper.accessor("etaDate", {
    id: "etaDate",
    header: "ETA",
    cell: (cellProps) => (
      <>
        {getFormattedDate(cellProps.getValue())}
      </>
    ),    
    size: 80,
  }),  
  columnHelper.accessor("billDate", {
    id: "billDate",
    header: "Bill Date",    
    cell: (cellProps) => (
      <>
        {getFormattedDate(cellProps.getValue())}
      </>
    ),
    size: 80,
  }),
  columnHelper.accessor("probillStatus", {
    id: "probillStatus",
    header: "Status",
        cell: (cellProps) => (
      <>
        {cellProps.getValue()}
      </>
    ),
    size: 130,
  }),
  columnHelper.accessor("shipperName", {
    id: "shipperName",
    header: "Shipper",    
    cell: (cellProps) => (
      <>{cellProps.getValue()} ({cellProps.row.original.shipperNumber})</>
    ),
    size: 150,
  }),
  columnHelper.accessor("consigneeName", {
    id: "consigneeName",
    header: "Consignee",
    cell: (cellProps) => (
      <>{cellProps.getValue()} ({cellProps.row.original.consigneeNumber})</>
    ),
    size: 150,
  }),
  columnHelper.accessor("probillWeight", {
    id: "probillWeight",
    header: "Weight",    
    cell: (cellProps) => (
      <>
        {(cellProps.getValue() && +cellProps.getValue()) ? cellProps.getValue().toLocaleString('en', { useGrouping: true }) : cellProps.getValue()}
      </>
    ),
    size: 70,
  }),
  columnHelper.accessor("probillItems", {
    id: "probillItems",
    header: "Pieces",    
    cell: (cellProps) => (
      <>
        {(cellProps.getValue() && +cellProps.getValue()) ? cellProps.getValue().toLocaleString('en', { useGrouping: true }) : cellProps.getValue()}
      </>
    ),
    size: 70,
  })
];
