import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Link,
  useHistory,
  useParams
} from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import Table from "../../ui-components/table/table";
import CustomCell, { CustomCellTypes } from "../../ui-components/table/custom-cells/customCell";
import ContentContainer from "../../templates/content-container/contentContainer";
import ClaimsListFilters from "./filters";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import UserState from "../../slices/user/UserState";
import { userSelector } from "../../slices/user/userSlice";
import { ClaimSearchModel } from "../../app/data/claims/models";
import { Routes } from "../../app/route/RoutesConfig";
import { PathParams, ClaimsPath } from "./route";
import ClaimDetails from "./details";
import ClaimsState, { ClaimListItem } from "../../slices/claims/ClaimsState";
import {
  claimsSelector,
  getClaimsList,
  resetClaimsState
} from "../../slices/claims/claimsSlice";
import {
  CLAIM_REASON_TYPES,
  CLAIM_STATUSES
} from "./constants";
import { PAGE_IDS } from "../../app/data/common/pageIds";

import "./claims.scss";

const columnHelper = createColumnHelper<ClaimListItem>();

const ClaimsList: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams() as PathParams;
  const userState: UserState = useSelector(userSelector);
  const claimsState: ClaimsState = useSelector(claimsSelector);
  const [claimNumber, setClaimNumber] = useState<number>();
  const [searchParams, setSearchParams] = useState<ClaimSearchModel | null>(null);

  const columns = [
    columnHelper.accessor("claimNumber", {
      id: "claimNumber",
      header: "Claim #",
      size: 90,
    }),
    columnHelper.accessor("totalClaimAmount", {
      id: "totalClaimAmount",
      header: () => (
        <div className="text-right">
          Claim Amount
        </div>
      ),      
      cell: (cellProps) => (
        <div className="text-right">
          <CustomCell
            cellType={CustomCellTypes.amount}
            selector="totalClaimAmount"
            data={cellProps.row.original}
            value={cellProps.getValue() || "0"}
          />
        </div>
      ),
      size: 110
    }),
    columnHelper.accessor("probill", {
      id: "probill",
      header: "Probill",
      cell: (cellProps) => (
        <Link
          className="xgs-table-link-cell"
          target="_blank"
          to={`${userState.activeSubAccount?.accountNumber ? "/" + userState.activeSubAccount?.accountNumber : ""}/shipments/${cellProps.getValue()}`}
        >
          {cellProps.getValue()}
        </Link>
      ),
      size: 100,
    }),
    columnHelper.accessor("bol", {
      id: "bol",
      header: "BOL",
      size: 126
    }),
    columnHelper.accessor("reason", {
      id: "reason",
      header: "Reason",
      cell: (cellProps) => (
        <>
          { CLAIM_REASON_TYPES.find(reason => reason.value === cellProps.getValue())?.label }
        </>
      ),
      size: 152,
    }),
    columnHelper.accessor("createdAt", {
      id: "createdAt",      
      header: () => (
        <div className="text-center">
          Date Created
        </div>
      ),      
      cell: (cellProps) => (
        <div className="text-center">
          { cellProps.getValue().toUiDateFormat() }
        </div>
      ),
      size: 120,
    }),
    columnHelper.accessor("dateResolved", {
      id: "dateResolved",      
      header: "Date Resolved",      
      cell: (cellProps) => (
        <div className="text-center">
          { cellProps.getValue()?.toUiDateFormat() || "-" }
        </div>
      ),
      size: 120,
    }),
    columnHelper.accessor("settlementAmount", {
      id: "settlementAmount",
      header: () => (
        <div className="text-right">
          Settlement Amount
        </div>
      ),
      cell: (cellProps) => (
        <div className="text-right">
          {cellProps.getValue()
            ? <CustomCell
              cellType={CustomCellTypes.amount}
              selector="settlementAmount"
              data={cellProps.row.original}
              value={cellProps.getValue() || "0"}
            />
            : <>-</>
          }
        </div>
      ),
      size: 100
    }),
    columnHelper.accessor("status", {
      id: "status",      
      header: () => (
        <div className="text-center">
          Status
        </div>
      ),
      cell: (cellProps) => (
        <div className="text-center">
          <div className="xgs-status-label" style={{ backgroundColor: CLAIM_STATUSES.find(status => status.value === cellProps.getValue())?.color || "#cccccc" }}>
            {CLAIM_STATUSES.find(status => status.value === cellProps.getValue())?.label}
          </div>
        </div>
      ),
      size: 124,
    })
  ];

  const onClaimsInfiniteScroll = () => {
    dispatch(getClaimsList(searchParams, claimsState.claimsRequest.lastIds)); // TODO
  };

  const onClaimClick = (row: any) => {
    setClaimNumber(row.claimNumber);
    window.history.replaceState("", "", "/" + userState.activeSubAccount?.accountNumber + Routes.claims.list + "/" + row.claimNumber);
  };

  const onDetailsClose = () => {
    setClaimNumber(undefined);
    window.history.replaceState("", "", "/" + userState.activeSubAccount?.accountNumber + Routes.claims.list);
  };

  useEffect(() => {
    if (!params[ClaimsPath.number]) return;
    setClaimNumber(Number(params[ClaimsPath.number]));
  }, [dispatch, params]);

  useEffect(() => {
    dispatch(getClaimsList(searchParams));

    return () => {
      dispatch(resetClaimsState());
    }
  }, [dispatch, searchParams]);

  return (
    <ContentContainer pageId={PAGE_IDS.CLAIMS} title="Claims">
      <div className="xgs-rates__list">
        <div className="xgs-rates__request-buttons-area">
          <div className="xgs-rates__request-buttons">
            <Button
              theme={ButtonThemes.blue}
              onClick={() => history.push(`/${userState.activeSubAccount?.accountNumber}${Routes.claims.create}`)}
              className="xgs-rates__request-button"
            >
              Create New Claim
            </Button>
          </div>
        </div>
        <ClaimsListFilters onChange={(q) => {
          setSearchParams(q);
          onDetailsClose();
        }}/>
        {claimsState.requestFailed && claimsState.requestCreator === "GET_CLAIMS" && (
          <XGSErrorMessage>{claimsState.requestError || "Error while obtaining the list of claims"}</XGSErrorMessage>
        )}
        {!(claimsState.requestFailed && claimsState.requestCreator === "GET_CLAIMS") && (
          <>
            <Table
              isLoading={claimsState.requestStarted && claimsState.requestCreator === "GET_CLAIMS"}
              columns={columns}
              data={claimsState.claims}
              cursorPointer={true}
              onRowClicked={onClaimClick}
              rowHeight={42}
              minTableHeight={200}
              noResultsText="You don't have any claims yet"
              infiniteScroll={true}
              infiniteScrollLoading={claimsState.requestPortionStarted}
              infiniteScrollHasNext={!claimsState.claimsFetchedAll}
              onInfiniteScroll={onClaimsInfiniteScroll}
              highlightRow={claimNumber ? claimsState.claims.findIndex((claim) => claim.claimNumber === claimNumber) : null}
              highlightRowClass="xgs-claims__list__highlighted-row"
            />
          </>
        )}
        <ClaimDetails
          claimNumber={claimNumber}
          onClose={onDetailsClose}
        />
      </div>
    </ContentContainer>
  );
};

export default ClaimsList;
