import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Wizard, Steps, Step } from "react-albus";
import ContentContainer from "../../../templates/content-container/contentContainer";
import StepsMilestones, { stepsMilestonesDataType } from "../../../ui-components/steps-milestones/stepsMilestones";
import PickupShipperStep from "./steps/pickupShipper";
import PickupConsigneeStep from "./steps/pickupConsignee";
import PickupItemsStep from "./steps/pickupItems";
import PickupSuccessStep from "./steps/pickupSuccess";
import {
  pickupSelector,
  resetPickupState
} from "../../../slices/pickup/pickupSlice";
import { PAGE_IDS } from "../../../app/data/common/pageIds";

import "../../bol/bol.scss";
import "../pickup.scss";

const initialStepsData: stepsMilestonesDataType[] = [
  {
    id: "pickup-shipper-step",
    name: "Shipper Information",
    data: null
  },
  {
    id: "pickup-consignee-step",
    name: "Consignee Information",
    data: null
  },
  {
    id: "pickup-items-step",
    name: "Detail Information",
    data: null
  },
];

const PickupCreate: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const pickupState = useSelector(pickupSelector);
  const [stepsData, setStepsData] = useState<stepsMilestonesDataType[]>(initialStepsData);

  useEffect(() => {
    if (!stepsData) return;
    let newStepsData = [...stepsData];
    let index = newStepsData.findIndex(step => step.id === "pickup-shipper-step");
    if (index === -1) return;
    newStepsData[index].data = pickupState.shipper && pickupState.shipper.accountNumber
      ? (<>
        <div><strong>Shipper:</strong> {pickupState.shipper.name} ({pickupState.shipper.accountNumber})</div>
        {(pickupState.shipper.contacts?.firstName || pickupState.shipper.contacts?.lastName) && (
          <div><strong>Contact person:</strong> {pickupState.shipper.contacts.firstName} {pickupState.shipper.contacts.lastName}</div>
        )}
        {pickupState.shipper.contacts?.mobilePhone && (
          <>
            <div><strong>Contact phone #:</strong> {pickupState.shipper.contacts.mobilePhone}</div>
            {pickupState.shipper.contacts?.allowSms && (
              <div>(SMS allowed)</div>
            )}
          </>
        )}
        <div><strong>Contact email:</strong> {pickupState.shipper.contacts.email}</div>
        {pickupState.bolNumber && (
          <div><strong>BOL:</strong> {pickupState.bolNumber}</div>
        )}
        {pickupState.poNumber && (
          <div><strong>PO:</strong> {pickupState.poNumber}</div>
        )}
        {pickupState.additionalReference && (
          <div><strong>Additional Ref.:</strong> {pickupState.additionalReference}</div>
        )}
        <div><strong>Pickup date:</strong> {pickupState.pickupDate}</div>
        <div><strong>Pickup time:</strong> {pickupState.pickupReadyTime} - {pickupState.pickupCloseTime}</div>
        {pickupState.specialInstructions && (
          <div><strong>Special Instructions:</strong> {pickupState.specialInstructions}</div>
        )}
      </>)
      : null;
    setStepsData([...newStepsData]);
    // eslint-disable-next-line
  }, [
    pickupState.shipper,
    pickupState.shipper.contacts,
    pickupState.bolNumber,
    pickupState.poNumber,
    pickupState.additionalReference,
    pickupState.pickupDate,
    pickupState.pickupReadyTime,
    pickupState.pickupCloseTime,
    pickupState.specialInstructions
  ]);

  useEffect(() => {
    if (!stepsData) return;
    let newStepsData = [...stepsData];
    let index = newStepsData.findIndex(step => step.id === "pickup-consignee-step");
    if (index === -1) return;
    newStepsData[index].data = (pickupState.consignee.name || (pickupState.consignee.address.address1 && pickupState.consignee.address.city) || pickupState.consignee.phone)
      ? (
        <>
          <div><strong>Consignee:</strong> {pickupState.consignee.name}</div>
          {pickupState.consignee.address.address1 && pickupState.consignee.address.city && pickupState.consignee.address.state && pickupState.consignee.address.postalCode && (
            <div><strong>Address:</strong> {pickupState.consignee.address.address1}, {pickupState.consignee.address.city}, {pickupState.consignee.address.state}, {pickupState.consignee.address.postalCode}</div>
          )}
          {pickupState.consignee.address.additionalAddressLine && (
            <div><strong>Additional address line:</strong> {pickupState.consignee.address.additionalAddressLine}</div>
          )}
          {pickupState.consignee.phone && (
            <div><strong>Phone #:</strong> {pickupState.consignee.phone}</div>
          )}
          {pickupState.consignee.email && (
            <div><strong>Email:</strong> {pickupState.consignee.email}</div>
          )}
        </>
      )
      : null;
    setStepsData([...newStepsData]);
    // eslint-disable-next-line
  }, [pickupState.consignee]);

  useEffect(() => {
    if (!stepsData) return;
    let newStepsData = [...stepsData];
    let index = newStepsData.findIndex(step => step.id === "pickup-items-step");
    if (index === -1) return;
    const itemsCount = pickupState.items.filter(item => !item.deleted).length;
    newStepsData[index].data = itemsCount > 0
      ? (
        <>
          <div>{itemsCount} item{itemsCount !== 1 ? "s" : ""}</div>
        </>
      )
      : null;
    setStepsData([...newStepsData]);
    // eslint-disable-next-line
  }, [pickupState.items]);

  useEffect(() => {
    return () => {
      dispatch(resetPickupState());
    };
  }, [dispatch]);

  return (
    <ContentContainer pageId={PAGE_IDS.CREATE_PICKUP} title="New Pickup">
      <div className="xgs-pickup">
        <Wizard
          render={({ step, push }) => (
            <>
              <div className="xgs-pickup__wizard">
                <Steps>
                  <Step
                    id="pickup-shipper-step"
                    render={({ next }) => (
                      <PickupShipperStep next={next} />
                    )}
                  />
                  <Step
                    id="pickup-consignee-step"
                    render={({ next, previous }) => (
                      <PickupConsigneeStep next={next} previous={previous} />
                    )}
                  />
                  <Step
                    id="pickup-items-step"
                    render={({ next, previous }) => (
                      <PickupItemsStep next={next} previous={previous} />
                    )}
                  />
                  <Step
                    id="pickup-success-step"
                    render={({ push }) => (
                      <PickupSuccessStep push={push} />
                    )}
                  />
                </Steps>
              </div>
              <div className="xgs-pickup__milestones">
                {stepsData && <StepsMilestones steps={stepsData} step={step} push={push} />}
              </div>
            </>
          )}
        />
      </div>
    </ContentContainer>
  );
};

export default PickupCreate;
