import { useDispatch, useSelector } from "react-redux"
import { addConsigneeContactLocally, appointmentSelector, setCurrentAppointmentContact } from "../../../slices/appointments/appointmentsSlice"
import React, { useEffect, useState } from "react";
import { ContactCard } from "../../company/account-contact/contactCard";
import Loading from "../../../ui-components/loading/loading";
import { StepProps } from "../../../app/data/common/models";
import { addContact, teamAccountContactSelector } from "../../../slices/customers/teams/teamAccountContactSlice";
import { AccountContactForm } from "../../company/account-contact/contactForm";
import { Contact, ContactType } from "../../../app/data/contact/models";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import { toast } from "react-toastify";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import { AppointmentStepIndicator } from "./appointmentStepIndicator";
import arrowLeft from "../../../images/arrow-left.svg";

const initialFormValues = {
  firstName: "",
  lastName: "",
  phone: "",
  title: "",
  email: "",
  type: ContactType.RECEIVING
};

interface ContactStepProps extends StepProps {
  stepCount: number;
  stepIdx: number;
}

export const AppointmentContactStep: React.FC<ContactStepProps> = (props) => {
  const appointmentState = useSelector(appointmentSelector);
  const accountContactState = useSelector(teamAccountContactSelector);
  const [selectedContactId, setSelectedContactId] = useState<string | null>(null);
  const [isCreating, setIsCreating] = useState(accountContactState.contacts.length === 0);
  const dispatch = useDispatch();

  const consigneeIsPortalUser =  !!appointmentState.consigneeInfo?.id;

  useEffect(() => {
    if (!!appointmentState.contacts.length) setIsCreating(false);
  }, [appointmentState.contacts])
  useEffect(() => {
    if (!!appointmentState.currentContact?.id) {
      setSelectedContactId(appointmentState.currentContact.id)
    }
  }, [appointmentState.currentContact])

  const handleSelectContact = (contactId: string) => {
    setSelectedContactId(contactId);
  };

  const handleClickNext = () => {
    dispatch(setCurrentAppointmentContact(appointmentState.contacts.find((contact) => contact.id === selectedContactId)))
    props.next && props.next()
  }

  const handleNewContactSubmit = (values: Contact) => {
    // If consigneeInfo.id is null contact does not get saved to an account, instead it is one-to-one to appointment
    dispatch(
      addContact(
        appointmentState.consigneeInfo?.id || null,
        values,
        (contact: Contact) => {
          toast.info("Contact created.")
          dispatch(addConsigneeContactLocally(contact))
          setSelectedContactId(contact.id!)
          setIsCreating(false);
        },
        (error: any) => {
          toast.error(error || "Something went wrong.")
        }
      )
    )
  }

  return (
    <div className="xgs-create-appt">
      <div className="xgs-create-appt__wizard">
        <div className="xgs-create-appt__wizard__heading">Choose a contact</div>
        <div className="xgs-create-appt__wizard__desc">Please choose the customer receiving contact that will be assigned to this appointment. Or add new contact information.</div>
      </div>
      <AppointmentStepIndicator step={props.stepIdx} stepCount={props.stepCount}/>
      {appointmentState.fetchConsigneeStarted && <Loading isLoading/>}
      {appointmentState.fetchConsigneeFailed && <XGSErrorMessage>{appointmentState.fetchConsigneeError}</XGSErrorMessage>}
      {appointmentState.fetchConsigneeSucceed && <div>
        {appointmentState.contacts.map((contact, idx) => {
          return <ContactCard
            key={`appt-contact-${idx}`}
            contact={contact}
            selectable
            onSelect={handleSelectContact}
            selected={contact.id === selectedContactId}
          />
        })}
        {isCreating && <AccountContactForm 
          contacts={accountContactState.contacts} 
          onSubmit={handleNewContactSubmit} 
          onCancelEditing={() => {setIsCreating(false)}} 
          initialFormValues={initialFormValues}
          isSubmitting={accountContactState.request_was_started && !(accountContactState.request_creator === "GET")}
          disablePrepopulate={!consigneeIsPortalUser}
        />}
        {!isCreating && <Button className="xgs-create-appt__button" onClick={() => setIsCreating(true)} theme={ButtonThemes.blueInverted}><XGSIcon icon={XGSIcons.faPlus}/> Add another contact</Button>}
        {!!appointmentState.existingAppointment && <div className="xgs-create-appt__form__submit">
          <Button
            type="button"
            onClick={() => props.previous && props.previous()}
            theme={ButtonThemes.gray}
          >
            <img src={arrowLeft} alt="arrow-left"/>
            &nbsp;&nbsp;Back
          </Button>
          <Button disabled={selectedContactId === null} onClick={handleClickNext} theme={ButtonThemes.blue}>Next <XGSIcon icon={XGSIcons.faAngleRight}/></Button>
        </div>}
        {!appointmentState.existingAppointment && (
          <Button 
            onClick={handleClickNext} 
            style={{marginTop: 24}}
            className="xgs-create-appt__button" 
            disabled={selectedContactId === null} 
            theme={ButtonThemes.blue}
            >
              Next <XGSIcon icon={XGSIcons.faAngleRight}/>
          </Button>
        )}
      </div>}
    </div>
  )
}