import React from "react";
import NumberFormat from "react-number-format";

interface SummaryAmountProps {
  value?: number;
}

const SummaryAmount: React.FC<SummaryAmountProps> = (props) => {
  return (
    <NumberFormat
      value={props.value || 0}
      decimalScale={2}
      displayType={"text"}
      thousandSeparator={" "}
      prefix={"$ "}
    />
  );
};

export default SummaryAmount;