import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { AppThunk } from "../../app/store";
import { BolRequestModel } from "../../app/data/bol/models";
import BolService from "../../app/data/bol/bolService";
import ExportService from "../../app/data/export/exportService";
import { initialBolState } from "./BolState";

const bolService = BolService.getInstance();
const exportService = ExportService.getInstance();

export const bolSlice = createSlice({
  name: "bol",
  initialState: initialBolState,
  reducers: {
    resetBolState: (state) => initialBolState,
    setBolFromRequestRates: (state, { payload }) => {
      Object.assign(state, initialBolState);
      state.fromRequestRates = true;
      state.requestRatesPickupZip = payload.pickupZip;
      state.quoteNumber = payload.quoteNumber;
      for (const item of payload.items) {
        state.items.push({
          packageType: "ROLL",
          units: item.pieces,
          ratingClass: item.freightClass,
          description: item.description,
          notes: "",
          yards: item.yards,
          weight: item.weight,
          deleted: false
        });
      }
    },
    removeBolFromRequestRatesFlag: (state) => {
      state.fromRequestRates = false;
    },
    setBolShipper: (state, { payload }) => {
      state.shipper = Number(payload.shipper);
      state.bolNumber = payload.bolNumber;
      state.poNumber = payload.poNumber;
      state.payType = payload.payType;
      state.specialInstructions = payload.specialInstructions;
      state.pickupRequest = payload.pickupRequest;
      state.willCall = payload.willCall;
      if (payload.pickupRequest) {
        state.pickupDate = payload.pickupDate;
        state.pickupReadyTime = payload.pickupReadyTime;
        state.pickupCloseTime = payload.pickupCloseTime;
      } else {
        state.pickupDate = "";
        state.pickupReadyTime = "";
        state.pickupCloseTime = "";
      }
    },
    setBolConsignee: (state, { payload }) => {
      state.consignee.name = payload.name;
      state.consignee.address.address1 = payload.address?.address1;
      state.consignee.address.city = payload.address?.city;
      state.consignee.address.postalCode = payload.address?.postalCode;
      state.consignee.address.state = payload.address?.state;
      state.consignee.address.additionalAddressLine = payload.address?.additionalAddressLine;
      state.consignee.phone = payload.phone;
    },
    setBolPayor: (state, { payload }) => {
      if (!state.payor) {
        state.payor = {
          name: payload.name,
          address: {
            address1: payload.address?.address1,
            city: payload.address?.city,
            postalCode: payload.address?.postalCode,
            state: payload.address?.state
          },
          phone: payload.phone
        };
      } else {
        state.payor.name = payload.name;
        state.payor.address.address1 = payload.address?.address1;
        state.payor.address.city = payload.address?.city;
        state.payor.address.postalCode = payload.address?.postalCode;
        state.payor.address.state = payload.address?.state;
        state.payor.phone = payload.phone;
      }
    },    
    addItem: (state, { payload }) => {
      state.items.push({
        packageType: payload.packageType,
        units: payload.units ? parseFloat(payload.units) : undefined,
        notes: payload.notes,
        yards: payload.yards ? parseFloat(payload.yards) : undefined,
        weight: payload.weight ? parseFloat(payload.weight) : undefined,
        length: payload.length ? parseFloat(payload.length) : undefined,
        width: payload.width ? parseFloat(payload.width) : undefined,
        height: payload.height ? parseFloat(payload.height) : undefined,
        diameter: payload.diameter ? parseFloat(payload.diameter) : undefined,
        ratingClass: payload.ratingClass,
        description: payload.description,
        sidemark: payload.sidemark,
        commodityCode: payload.commodityCode,
        deleted: false
      });
    },
    updateItem: (state, { payload }) => {
      const i = payload.index;
      state.items[i].packageType = payload.data.packageType;
      state.items[i].units = payload.data.units ? parseFloat(payload.data.units) : undefined;
      state.items[i].yards = payload.data.yards ? parseFloat(payload.data.yards) : undefined;
      state.items[i].weight = payload.data.weight ? parseFloat(payload.data.weight) : undefined;
      state.items[i].length = payload.data.length ? parseFloat(payload.data.length) : undefined;
      state.items[i].width = payload.data.width ? parseFloat(payload.data.width) : undefined;
      state.items[i].height = payload.data.height ? parseFloat(payload.data.height) : undefined;
      state.items[i].diameter = payload.data.diameter ? parseFloat(payload.data.diameter) : undefined;
      state.items[i].ratingClass = payload.data.ratingClass;
      state.items[i].description = payload.data.description;
      state.items[i].notes = payload.data.notes;
      state.items[i].sidemark = payload.data.sidemark;
      state.items[i].commodityCode = payload.data.commodityCode;
    },
    deleteItem: (state, { payload }) => {
      state.items[payload].deleted = true;
    },
    clearDeletedItems: (state) => {
      state.items = state.items.filter(item => !item.deleted);
    },
    clearItems: (state) => {
      state.items = [];
    },
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestCreator = payload;
      state.requestError = "";
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestError = payload;
    },
    fetchStarted: (state, { payload }) => {
      if (payload) {
        state.fetchPortionStarted = true;
      } else {
        state.fetchStarted = true;
        state.bolsRequest = null;
      }
      state.fetchSucceed = false;
      state.fetchFailed = false;
      state.fetchError = "";
    },
    fetchSucceed: (state) => {
      state.fetchSucceed = true;
      state.fetchStarted = false;
      state.fetchPortionStarted = false;
      state.fetchFailed = false;
    },
    fetchFailed: (state, { payload }) => {
      state.fetchFailed = true;
      state.fetchSucceed = false;
      state.fetchStarted = false;
      state.fetchError = payload;
    },
    setBols: (state, { payload }) => {
      state.bols = payload.content;
      state.bolsRequest = payload.scroll;
      state.bolsFetchedAll = !!(payload.content.length < 50);
    },
    addBols: (state, { payload }) => {
      state.bols = [...state.bols, ...payload.content];
      state.bolsRequest = payload.scroll;
      state.bolsFetchedAll = !!(payload.content.length < 50);
    },
    setShippers: (state, { payload }) => {
      state.shippers = payload;
    },
    setConsignees: (state, { payload }) => {
      state.consignees = payload;
    },
    setPickupAvailability: (state, { payload }) => {
      state.pickupAvailable = payload;
      if (!payload) {
        state.pickupRequest = false;
      }
    },
    setConsigneeCheckResult: (state, { payload }) => {
      state.consigneeCheckResult = payload;
    },
    setConsigneeZipChecked: (state, { payload }) => {
      state.consigneeZipChecked = payload;
    },
    storeCSVLink: (state, { payload }) => {
      state.csvLink = payload;
    },
    clearCsvLink: (state) => {
      state.csvLink = "";
    },
    updateBolPickup: (state, { payload }) => {
      const bols = [...state.bols];
      const bolIndex = bols.findIndex((bol) => bol.probill === payload.probill);
      if (bolIndex === -1) return;
      bols[bolIndex].pickupId = payload.pickupId;
      bols[bolIndex].pickupNumber = payload.pickupNumber;
      bols[bolIndex].pickupDate = moment(payload.date, "MM/DD/YYYY").format("YYYY-MM-DD");
      bols[bolIndex].pickupReadyTime = moment(payload.readyTime, "h:mm a").format("HH:mm:ss");
      bols[bolIndex].pickupCloseTime = moment(payload.closeTime, "h:mm a").format("HH:mm:ss");
      state.bols  = [...bols];
    },
    updateBolPickupStatus: (state, { payload }) => {
      const bols = [...state.bols];
      const bolIndex = bols.findIndex((bol) => bol.probill === payload.probill);
      if (bolIndex === -1) return;
      bols[bolIndex].pickupStatus = payload.status;
      state.bols  = [...bols];
    }
  }
});

export const {
  resetBolState,
  setBolFromRequestRates,
  removeBolFromRequestRatesFlag,
  setBolShipper,
  setBolConsignee,
  setBolPayor,
  addItem,
  updateItem,
  deleteItem,
  clearDeletedItems,
  clearItems,
  requestStarted,
  requestSucceed,
  requestFailed,
  fetchStarted,
  fetchSucceed,
  fetchFailed,
  setBols,
  addBols,
  setShippers,
  setConsignees,
  setPickupAvailability,
  setConsigneeCheckResult,
  setConsigneeZipChecked,
  storeCSVLink,
  clearCsvLink,
  updateBolPickup,
  updateBolPickupStatus
} = bolSlice.actions;

export const bolSelector = (state: IState) => {
  return state.bol;
};

export const getBols = (
  types: string[],
  lastIds?: string
): AppThunk => async (dispatch) => {
  dispatch(fetchStarted(!!lastIds));
  const response = await bolService.getBols(types, lastIds && lastIds);
  if (response.ok()) {
    dispatch(fetchSucceed());
    dispatch(lastIds ? addBols(response.data) : setBols(response.data));
  } else {
    dispatch(fetchFailed(response.getError && response.getError()));
  }
};

export const getShippers = (): AppThunk => async (dispatch) => {
  dispatch(requestStarted("shippers"));
  const response = await bolService.getShippers();
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setShippers(response.data));
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const getConsignees = (): AppThunk => async (dispatch) => {
  dispatch(requestStarted("consignees"));
  const response = await bolService.getConsignees();
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setConsignees(response.data));
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const checkPickup = (
  zip: string,
  onFailed?: () => void
): AppThunk => async (dispatch) => {
  dispatch(setPickupAvailability(false));
  dispatch(requestStarted("CHECK_PICKUP"));
  const response = await bolService.checkPickup(zip);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setPickupAvailability(true));
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
    dispatch(setPickupAvailability(false));
    onFailed && onFailed();
  }
};

export const createBol = (
  request: BolRequestModel,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("save"));
  const response = await bolService.createBol(request);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const downloadDocument = (
  probill: string,
  type: string,
  onSuccess: (arg: Blob) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted(`download-${type}`));
  const response = await bolService.downloadDocument(probill, type);
  if (response.ok()) {
    dispatch(requestSucceed());
    let blob: Blob = new Blob([response.data], {type: "application/pdf"});
    onSuccess(blob);
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const checkConsignee = (
  zip: string,
  onSuccess: () => void,
  onNotFound: () => void,
  onFailed: () => void
): AppThunk => async (dispatch) => {
  //dispatch(setConsigneeCheckResult(false));
  dispatch(requestStarted("CHECK_CONSIGNEE_ZIP"));
  const response = await bolService.checkConsignee(zip);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setConsigneeCheckResult(true));
    dispatch(setConsigneeZipChecked(zip));
    onSuccess();
  } else {
    const error = response.getError ? response.getError() : "Error";
    dispatch(requestFailed(error));
    dispatch(setConsigneeCheckResult(false));
    dispatch(setConsigneeZipChecked(zip));
    if (response.status === 404) {
      onNotFound();
    } else {
      onFailed();
    }
  }
};


export const getBolCsv = (
  types: string[],
  onSuccess: (arg: string) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("DOWNLOAD_CSV"));
  const response = await bolService.downloadCsv(types);
  if (response.ok()) {
    let exportId = response.data.exportId;
    if (exportId) {
      // get link of exported file
      let attempt = 0;
      while (attempt < 200) {
        const exportResponse = await exportService.getExportLink(exportId);
        if (exportResponse.ok()) {
          if (exportResponse.data.status === "DONE") {
            dispatch(requestSucceed());
            dispatch(storeCSVLink(exportResponse.data.fileUrl));
            onSuccess && onSuccess(exportResponse.data.fileUrl);
            break;
          }
          if (exportResponse.data.status === "FAILED") {
            dispatch(requestFailed(response.getError ? response.getError() : "Error"));
            break;
          }
        } else {
          dispatch(requestFailed(response.getError ? response.getError() : "Error"));
          break;
        }
        await new Promise((d) => setTimeout(d, 2000));
        attempt++;
      }
    }
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const bolReducer = bolSlice.reducer;
export default bolReducer;
