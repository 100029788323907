import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import XGSNavLink from "../../../ui-components/xgs-nav-link/xgsNavLink";
import CustomCell, {
  CustomCellTypes,
} from "../../../ui-components/table/custom-cells/customCell";

const columnHelper = createColumnHelper<any>();

const getTrackShipmentColumns = (
  employee: boolean,
  showRates: boolean
) => [
    columnHelper.accessor("invoice.invoiceNumber", {
      id: "invoice.invoiceNumber",
      header: "Probill",
      cell: (cellProps) => (
        <XGSNavLink
          className="xgs-table-link-cell"
          to={`/shipments/${cellProps.getValue()}`}
        >
          {cellProps.getValue()}
        </XGSNavLink>
      ),
      size: 90,
      minSize: 90,
    }),
    columnHelper.accessor("estimatedDeliveryDate", {
      id: "estimatedDeliveryDate",
      header: () => (
        <div className="text-center">
          ETA
        </div>
      ),
      cell: (cellProps) => (
        <div className="text-center">
          {cellProps.getValue() ? cellProps.getValue().toUiDateFormat() : ""}
        </div>
      ),
      size: 90,
      minSize: 90,
    }),
    columnHelper.accessor("invoice.dateInvoice", {
      id: "invoice.dateInvoice",
      header: () => (
        <div className="text-center">
          Bill date
        </div>
      ),
      cell: (cellProps) => (
        <div className="text-center">
          <CustomCell
            cellType={CustomCellTypes.date}
            data={cellProps.row.original}
            value={cellProps.getValue()}
          />
        </div>
      ),
      size: 90,
      minSize: 90,
    }),
    columnHelper.display({
      id: "po/bol",
      header: "PO / BOL",
      cell: (cellProps) => (
        <>
          {cellProps.row.original.invoice.poNumber}
          <div className="xgs-tracking__values-delimiter"></div>
          {cellProps.row.original.invoice.bolNumber}
        </>
      ),
      size: 160,
      minSize: 110
    }),
    columnHelper.accessor(employee ? "shipper.info" : "consignee.info", {
      id: "info",
      header: employee ? "Shipper name" : "Consignee name",
      size: 170,
      minSize: 140
    }),
    columnHelper.accessor("freightCharges", {
      id: "freightCharges",
      header: () => (
        <div className="text-right">
          {showRates && (<>Rate</>)}
        </div>
      ),
      cell: (cellProps) => (
        <div className="text-right">
          {showRates && (
            <CustomCell
              cellType={CustomCellTypes.amount}
              selector="freightCharges"
              data={cellProps.row.original}
              value={cellProps.getValue()}
            />
          )}
        </div>
      ),
      size: showRates ? 100 : 5,
      minSize: showRates ? 90 : 5
    }),
    columnHelper.display({
      id: "origin/destination",
      header: "Origin / Destination",
      cell: (cellProps) => (
        <>
          {cellProps.row.original.origin.name}
          <div className="xgs-tracking__values-delimiter"></div>
          {cellProps.row.original.destination.name}
        </>
      ),
      size: 165,
      minSize: 120
    }),
    columnHelper.accessor(row => row.status.translate, {
      id: "status.translate",
      header: "Status",
      size: 110,
      minSize: 90
    }),
    columnHelper.accessor("pod", {
      id: "pod",
      header: "POD",
      cell: (cellProps) => (
        <CustomCell
          cellType={CustomCellTypes.combined}
          values={[
            cellProps.row.original.pod?.date?.toUiDateFormat(),
            cellProps.row.original.pod?.time,
            cellProps.row.original.pod?.signature,
          ]}
          data={cellProps.row.original}
        />
      ),
      size: 120,
      minSize: 90
    })
  ];

export default getTrackShipmentColumns;
