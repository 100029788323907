import React, { memo } from "react";
import { getAgingMeta } from "../../aging/item/agingItem";
import { useSelector } from "react-redux";
import { agingSelector } from "../../../slices/aging/agingSlice";
import { getProperty } from "../../../hooks/utils";
import {
  AgingState,
} from "../../../slices";
import AgingInvoicesFilter from "./agingInvoicesFilter";
import { InvoiceFilterModel } from "../../../app/data/invoice/models";
import SummaryTile, { SummaryItemColor } from "../../../ui-components/summary/summary-tile/summaryTile";
import { ReactComponent as MoneyIcon } from "../../../images/money.svg";
import SummaryAmount from "../../../ui-components/summary/summary-format/summaryAmount";

export interface AgingInvoicesHeaderProps {
  agingPeriod: string;
  onSearch: (filter: InvoiceFilterModel) => void;
}

const AgingInvoicesHeader: React.FC<AgingInvoicesHeaderProps> = memo(
  ({ agingPeriod, ...props }) => {
    const agingState: AgingState = useSelector(agingSelector);

    const getAgingValue = () => {
      const agingMeta = getAgingMeta(agingPeriod);
      if (agingMeta && agingState.data) {
        return getProperty(agingState.data as any, agingMeta.field);
      }
    };

    return (
      <div className="xgs-list__controls xgs-aging-invoices__header">
        <div className="xgs-aging-invoices__header__item">
          <SummaryTile
            mix="xgs-aging-invoices__header__total"      
            value={<SummaryAmount value={getAgingValue()} />}
            description="Total Open amount"
            icon={<MoneyIcon/>}
            mods={{
              color: SummaryItemColor.blue,
            }}
            isLoading={agingState.loading}
          />

          <AgingInvoicesFilter onSearch={props.onSearch} key={agingPeriod} />
        </div>
      </div>
    );
  }
);

export default AgingInvoicesHeader;
