import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createColumnHelper } from "@tanstack/react-table";
import {
  companyAccountDetailsSelector,
  getAccountDetailsChangeLog,
} from "../../../slices/company-settings/companyAccountDetailsSlice";
import SlideOutSidebar from "../../../ui-components/slide-out-sidebar/slideOutSidebar";
import Table from "../../../ui-components/table/table";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import styles from "../../../sass/variables.module.scss";

const columnHelper = createColumnHelper<any>();

const DiffCell: React.FC<{ row: any }> = ({ row }) => {
  const { lhs, rhs } = row.original;
  const isBoolean = typeof lhs === "boolean";

  return (
    <div className="xgs-account-general-settings__logs__diff-cell">
      {isBoolean ? (
        <>
          {rhs ? (
            <XGSIcon icon={XGSIcons.faCheckCircle} color={styles.green1} />
          ) : (
            <XGSIcon icon={XGSIcons.faTimesCircle} color={styles.red} />
          )}
        </>
      ) : (
        <>
          {lhs && <>
            <span className="xgs-account-general-settings__logs__diff-cell__lhs">{lhs}</span>
            <br />
          </>}
          {rhs}
        </>
      )}
    </div>
  );
};

const getTableColumns = () => [
  columnHelper.accessor("key", {
    id: "key",
    header: "Field",
    cell: (cellProps) => (
      <div className="xgs-account-general-settings__logs__key-cell">{cellProps.getValue().capitalize()}</div>
    ),
    size: 120,
  }),
  columnHelper.accessor("lhs", {
    id: "lhs",
    header: "Change",
    cell: (cellProps) => {
      return <DiffCell row={cellProps.row} />;
    },
    size: 300,
  }),
  columnHelper.accessor("dateCreated", {
    id: "dateCreated",
    header: "Timestamp",
    cell: (cellProps) => <>{cellProps.getValue().toUiDateTimeFormat()}</>,
    size: 120,
  }),
  columnHelper.accessor("userCreated", {
    id: "userCreated",
    header: "Performed by",
    size: 250,
  }),
];

export interface HolidayChangeLogProps {
  show: boolean;
  onClose: () => void;
  accountId: string;
}

export const AccountGeneralSettingsLogs: React.FC<HolidayChangeLogProps> = (props) => {
  const companyAccountDetailsState = useSelector(companyAccountDetailsSelector);
  const dispatch = useDispatch();
  const diffsAfterSplitting = useMemo(() => {
    let diffs: {
      key: string;
      lhs: string;
      rhs: string;
      dateCreated: string;
      userCreated: string;
    }[] = [];
    if (!companyAccountDetailsState.changelogs) return [];
    companyAccountDetailsState.changelogs.forEach((item) => {
      let [lhs, rhs] = item.diff.split(" differs from ").map((values) => JSON.parse(values));
      var keys = Object.keys(lhs);
      keys.forEach((key) => {
        diffs.push({
          key,
          lhs: lhs[key],
          rhs: rhs[key],
          dateCreated: item.dateCreated,
          userCreated: item.userCreated,
        });
      });
    });
    return diffs;
  }, [companyAccountDetailsState.changelogs]);

  useEffect(() => {
    if (props.show) dispatch(getAccountDetailsChangeLog(props.accountId));
  }, [props.show, dispatch, props.accountId]);

  return (
    <SlideOutSidebar
      onClose={props.onClose}
      spinner={false}
      show={props.show}
      header="Account details change log"
    >
      <div className="xgs-holiday-calendar__logs">
        <div className="xgs-holiday-calendar__logs__heading">Recent changes</div>
        <Table
          isLoading={companyAccountDetailsState.fetch_logs_started}
          infiniteScroll
          columns={getTableColumns()}
          rowHeight={70}
          data={diffsAfterSplitting}
        />
      </div>
    </SlideOutSidebar>
  );
};
