import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import Invoice from "../../../app/data/invoice/models";
import CustomCell, { CustomCellTypes } from "../../../ui-components/table/custom-cells/customCell";
import { TagColor } from "../../../app/data/common/tagColor";
import Tag from "../../../ui-components/molecules/tag/tag";
import { ReactComponent as CheckIcon } from "../../../images/check.svg";
import InvoiceDownloadButton from "./invoiceDownloadButton";
import PodDownloadButton from "./podDownloadButton";

const columnHelper = createColumnHelper<Invoice>();

const getAgingInvoicesColumns = (
  getInvoiceDetailsUrl: (row: Invoice) => string
) => {
  return [    
    columnHelper.accessor("invoiceNumber", {
      id: "invoiceNumber",
      header: "Invoice #",
      cell: (cellProps) => (
        <CustomCell
          cellType={CustomCellTypes.link}
          selector="invoiceNumber"
          data={cellProps.row.original}
          value={cellProps.getValue()}
          options={{ url: getInvoiceDetailsUrl(cellProps.row.original) }}
        />
      ),
      enableSorting: false,
      size: 94,
    }),
    columnHelper.accessor("paid", {
      id: "paid",
      header: "Paid",
      cell: (cellProps) => (
        <>
          {cellProps.getValue() && (
            <Tag mods={{ color: TagColor.GREEN, uppercase: true, flex: true }}>
              <CheckIcon /><span>Paid</span>
            </Tag>
          )}
        </>
      ),
      enableSorting: false,
      size: 70,
    }),
    columnHelper.accessor("dateInvoice", {
      id: "dateInvoice",
      header: "Invoice Date",
      cell: (cellProps) => (
        <CustomCell
          cellType={CustomCellTypes.date}
          selector="dateInvoice"
          data={cellProps.row.original}
          value={cellProps.getValue()}
        />
      ),
      size: 110,
    }),
    columnHelper.accessor("invoiceAmount", {
      id: "invoiceAmount",
      header: () => (
        <div className="text-right">
          Amount
        </div>
      ),
      cell: (cellProps) => (
        <div className="text-right">
          <CustomCell
            cellType={CustomCellTypes.amount}
            selector="invoiceAmount"
            data={cellProps.row.original}
            value={cellProps.getValue()}
          />
        </div>
      ),
      size: 178,
    }),
    columnHelper.accessor("openAmount", {
      id: "openAmount",
      header: () => (
        <div className="text-right">
          Open Amount
         </div>
      ),
      cell: (cellProps) => (
        <div className="text-right">
          <CustomCell
            cellType={CustomCellTypes.amount}
            selector="openAmount"
            data={cellProps.row.original}
            value={cellProps.getValue()}
          />
        </div>
      ),
      size: 178,
    }), 
    columnHelper.accessor("dateDue", {
      id: "dateDue",
      header: "Due Date",
      cell: (cellProps) => (
        <CustomCell
          cellType={CustomCellTypes.date}
          selector="dateDue"
          data={cellProps.row.original}
          value={cellProps.getValue()}
        />
      ),
      enableSorting: false,
      size: 118,
    }),
    columnHelper.accessor("bolNumber", {
      id: "bolNumber",
      header: "Bill of Lading",
      enableSorting: false,    
      size: 150,
    }),
    columnHelper.accessor("poNumber", {
      id: "poNumber",
      header: "PO Number",
      enableSorting: false,    
      size: 150,
    }),
    columnHelper.display({
      id: "invoiceDownload",      
      header: "Download Invoice",
      cell: (cellProps) => (
        <InvoiceDownloadButton
          invoiceNumber={cellProps.row.original.invoiceNumber}
        />
      ),
      size: 100,
      minSize: 100,
    }),
    columnHelper.display({
      id: "podDownload",
      header: "Download POD",
      cell: (cellProps) => {
        if (!cellProps.row.original.hasPODDocument) return null;

        return (
          <PodDownloadButton
            invoiceNumber={cellProps.row.original.invoiceNumber}
          />
        )
      },
      size: 100,
      minSize: 100,
    })
  ]
};

export default getAgingInvoicesColumns;
