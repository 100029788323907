import { PodRecognitionItemModel } from "../../app/data/documents/models";
import { PaginationModel } from "../../app/data/common/models";

export default interface PodRecognitionState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError: string;
  requestCreator: string;
  records: PodRecognitionItemModel[];
  recordsRequest: PaginationModel | null;
};

export const initialPodRecognitionState: PodRecognitionState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: "",
  requestCreator: "",
  records: [],
  recordsRequest: null
};
