import React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import ThreeDotsMenu from "../../../ui-components/three-dots-menu/threeDotsMenu";
import { ReactComponent as StarIcon } from "../../../images/star.svg";
import UserService from "../../../app/data/user/userService";
import { QUERY_KEYS } from "../../../app/data/common/queryKeys";
import { ERROR_MESSAGES } from "../../../app/data/common/errorMessages";

export interface PageMenuProps {
  pageId: string;
}

const userService = UserService.getInstance();

const MAX_PAGES = 10;

const PageMenu: React.FC<PageMenuProps> = ({
  pageId,
}) => {
  const { data: favorites, refetch } = useQuery({
    queryKey: [QUERY_KEYS.FAVORITES],
    queryFn: async () => {
      const response = await userService.getFavorites();
      return response.data;
    },
    enabled: false,
  });

  const addFavoriteMutation = useMutation({
    mutationFn: () => {
      return userService.addFavorite(pageId);
    },
    onSuccess: () => {
      refetch();
    },
    onError: () => {
      toast.error(ERROR_MESSAGES.COMMON);
    }
  });

  const removeFavoriteMutation = useMutation({
    mutationFn: () => {
      return userService.removeFavorite(pageId);
    },
    onSuccess: () => {
      refetch();
    },
    onError: () => {
      toast.error(ERROR_MESSAGES.COMMON);
    }
  });

  const addFavorite = () => {
    if (favorites?.length && favorites.length >= MAX_PAGES) {
      toast.error(`You can not add more than ${MAX_PAGES} pages to your favorites.`);
      return;
    }
    addFavoriteMutation.mutate();
  }

  return (
    <ThreeDotsMenu
      menuItems={[
        favorites?.find(page => page.favoritePageId === pageId)
          ? { icon: <StarIcon />, text: "Remove from favorites", onClick: () => removeFavoriteMutation.mutate() }
          : { icon: <StarIcon style={{ color: "transparent", stroke: "#4F5F72B8" }} />, text: "Add to favorites", onClick: addFavorite }
      ]}
    />)
};

export default PageMenu;
