import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "../../ui-components/table/table";
import SlideOutSidebar from "../../ui-components/slide-out-sidebar/slideOutSidebar";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import {
  clearInvolvedUsers,
  dockLoadingSelector,
  getInvolvedUsers
} from "../../slices/dock-loading/dockLoadingSlice";

export interface DoorDetailsProps {
  show: boolean;
  onClose: () => void;
  terminalNumber: number;
};

const columnHelper = createColumnHelper<any>();

const InvolvedUsers: React.FC<DoorDetailsProps> = (props) => {
  const dispatch = useDispatch();
  const dockLoadingState = useSelector(dockLoadingSelector);

  const columns = [
    columnHelper.accessor("userName", {
      id: "userName",
      header: "User Name",
      size: 120,
    }),
    columnHelper.accessor("loadedItemsCount", {
      id: "loadedItemsCount",
      header: "Items Loaded",
      size: 88,
      minSize: 80,
    }),
    columnHelper.accessor("firsttLoadedAt", {
      id: "firsttLoadedAt",
      header: "First Loading",
      cell: ({ row }) => (
        <div>
          {row.original.firstLoadedAt ? row.original.firstLoadedAt.toUiDateFormat() : ""}
          <span className="text-light xgs-table__cell-subitem">{moment(row.original.firstLoadedAt, "YYYY-MM-DDThh:mm:ss").format("h:mm A")}</span>
        </div>
      ),
      size: 158,
    }),
    columnHelper.accessor("lastLoadedAt", {
      id: "lastLoadedAt",
      header: "Last Loading",
      cell: ({ row }) => (
        <div>
          {row.original.lastLoadedAt ? row.original.lastLoadedAt.toUiDateFormat() : ""}
          <span className="text-light xgs-table__cell-subitem">{moment(row.original.lastLoadedAt, "YYYY-MM-DDThh:mm:ss").format("h:mm A")}</span>
        </div>
      ),
      size: 158,
    }),
  ];

  useEffect(() => {
    if (!props.terminalNumber || !props.show) return;
    dispatch(getInvolvedUsers(props.terminalNumber));
  }, [props.terminalNumber, props.show, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearInvolvedUsers());
    };
  }, [dispatch]);

  return (
    <SlideOutSidebar
      header="Involved Employees"
      onClose={props.onClose}
      show={props.show}
      spinner={dockLoadingState.requestStarted && dockLoadingState.requestCreator === "GET_INVOLVED_USERS"}
    >
      <div className="xgs-dock-loading__involved-users">
        <div className="xgs-dock-loading__header">Employees involved in loading</div>
        {!((dockLoadingState.requestStarted || dockLoadingState.requestFailed) && dockLoadingState.requestCreator === "GET_INVOLVED_USERS") && dockLoadingState.involvedUsers && (
          <div className="xgs-dock-loading__involved-users__list">
            <Table
              isLoading={dockLoadingState.requestStarted && dockLoadingState.requestCreator === "GET_INVOLVED_USERS"}
              keepTableOnLoading
              columns={columns}
              data={dockLoadingState.involvedUsers}
              rowHeight={56}
              minTableHeight={240}
              noResultsText="There is no data"
              responsive
            />
          </div>
        )}
        {dockLoadingState.requestFailed && (dockLoadingState.requestCreator === "GET_INVOLVED_USERS") && dockLoadingState.requestError && (
          <XGSErrorMessage>{dockLoadingState.requestError}</XGSErrorMessage>
        )}
      </div>
    </SlideOutSidebar>
  );
};

export default InvolvedUsers;