import React from "react";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<any>();

export const topSellingColumns = [
  columnHelper.accessor("sku", {
    id: "sku",
    header: () => <>SKU # /<br />Description</>,
    size: 152,
    cell: (cellProps) => (
      <div className="xgs-warehouse-summary__item-cell">
        <span>{cellProps.row.original.sku}</span><br />
        {cellProps.row.original.description}
      </div>
    )
  }),
  columnHelper.accessor("terminal", {
    id: "terminal",
    header: "Warehouse",
    cell: (cellProps) => (
      <div className="xgs-lmi__trips__address-cell">
        {cellProps.row.original.locationAirportCode} - {cellProps.row.original.locationTerminalName} <span>{cellProps.row.original.locationTerminalNumber}</span>
      </div>
    ),
    size: 212,
  }),
  columnHelper.accessor("numberOfShipments", {
    id: "numberOfShipments",
    header: () => <>Orders<br />(30 days)</>,
    size: 80
  })
]