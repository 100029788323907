import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { createColumnHelper } from "@tanstack/react-table";
import ContentContainer from "../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import Table from "../../../ui-components/table/table";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import DownloadButton from "../../../ui-components/download-button/downloadButton";
import {
  recognitionLogsSelector,
  getLogfile,
  getRecognitionLogsRecords
} from "../../../slices/documents/recognitionLogsSlice";
import { RecognitionLogsItemModel } from "../../../app/data/documents/models";
import { PAGE_IDS } from "../../../app/data/common/pageIds";

import "./recognitionLogs.scss";

const columnHelper = createColumnHelper<RecognitionLogsItemModel>();

const RecognitionLogs: React.FC<{}> = (props) => {
  const recognitionLogsState = useSelector(recognitionLogsSelector);
  const dispatch = useDispatch();
  const [downloadFilename, setDownloadFilename] = useState<string>("");

  const columns = [
    columnHelper.accessor("datetime", {
      id: "datetime",
      header: "Date",
      cell: (cellProps) => (
        <div>
          {cellProps.getValue() ? cellProps.getValue().toUiDateFormat() : ""}
          {<span className="text-light xgs-table__cell-subitem">{moment(cellProps.getValue(), "hh:mm:ss").format("h:mm A")}</span>}
        </div>
      ),
      size: 160,
      minSize: 160
    }),
    columnHelper.accessor("filename", {
      id: "filename",
      header: "File Name",
      size: 480,
      minSize: 220
    }),
    columnHelper.display({
      id: "attachment",
      header: "Attachment",
      cell: ({ row }) => (
        <>
          <DownloadButton
            title="Download File"
            onClick={() => !recognitionLogsState.requestStarted && downloadLogfile(row.original.filename)}
            spinner={recognitionLogsState.requestStarted && (recognitionLogsState.requestCreator === "GET_LOGFILE") && (row.original.filename === downloadFilename)}
          />
        </>
      ),
      size: 200,
      minSize: 200
    })
  ];

  const downloadLogfile = (filename: string) => {
    setDownloadFilename(filename);
    dispatch(getLogfile(filename, (file: Blob) => {
      let documentfileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = documentfileURL;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadFilename("");
    }));
  };

  useEffect(() => {
    dispatch(getRecognitionLogsRecords());
  }, [dispatch]);

  return (   
      <ContentContainer
        className="xgs-list xgs-list--full-width"
        mods={{ "full-width": true }}
        titleComponent={
          <ContentContainerToolbar
            pageId={PAGE_IDS.RECOGNITION_LOGS}
            title="Recognition Logs"
            mods={{ "full-width": true }}
          />
        }
      >
        <div className="xgs-documents__recognition-logs">
          {(recognitionLogsState.requestFailed && recognitionLogsState.requestCreator === "GET_RECORDS") && (
            <XGSErrorMessage>{recognitionLogsState.requestError}</XGSErrorMessage>
          )}
          {!(recognitionLogsState.requestFailed && recognitionLogsState.requestCreator === "GET_RECORDS") && (
            <Table
              isLoading={recognitionLogsState.requestStarted && recognitionLogsState.requestCreator === "GET_RECORDS"}
              keepTableOnLoading
              columns={columns}
              data={recognitionLogsState.records}
              rowHeight={56}
              minTableHeight={240}
              noResultsText="There are no records"
              responsive
              infiniteScroll
            />
          )}
        </div>
      </ContentContainer>
    );
};

export default RecognitionLogs;