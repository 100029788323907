import moment from "moment";
import { createColumnHelper } from "@tanstack/react-table";
import dateFormats from "../../app/data/common/dateFormats";

const columnHelper = createColumnHelper<any>();

export const getActivityLogColumns = () => [
  columnHelper.accessor("createdAt", {
    id: "createdAt",
    header: "Event Time",
    cell: (cellProps) => (
      moment(cellProps.getValue()).format(dateFormats.uiDateTime)
    ),
    size: 190,
  }),
  columnHelper.accessor("createdBy", {
    id: "createdBy",
    header: "User",
    size: 170,
  }),
  columnHelper.accessor("event", {
    id: "event",
    header: "Action",
    size: 140,
  }),
  columnHelper.accessor("note", {
    id: "note",
    header: "Additional Info",
    size: 270
  })
]
