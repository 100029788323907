import React from "react"
import { DefaultColumnFilter } from "./defaultColumnFilter/defaultColumnFilter"
import { SelectColumnFilter } from "./selectColumnFilter/selectColumnFilter"

interface FilterPros {
    column: any
}

export const Filter: React.FC<FilterPros> = ({ column }) => {
  const { filterVariant } = column.columnDef.meta ?? {};

  return (
    <div style={{ marginTop: 5 }}>
      {filterVariant === "select" ? (
        <SelectColumnFilter column={column} />
      ) : (<DefaultColumnFilter column={column} />)
      }
    </div>
  )
}