import React, { useState } from "react"
import XGSSelect, { XGSSelectOption } from "../../../xgs-select/xgsSelect";

export interface SelectColumnFilterProps {
    column: any
}

const defaultSortFn = (item1: any, item2: any) => item1 > item2 ? 1 : -1;

export const SelectColumnFilter: React.FC<SelectColumnFilterProps> = ({
    column
}) => {
  const { filterLabelFormatter, filterSortFn } = column.columnDef.meta ?? {};

  const [value, setValue] = useState<XGSSelectOption>();
  const options: XGSSelectOption[] = [];

  new Map([...column.getFacetedUniqueValues().entries()].sort(filterSortFn || defaultSortFn))
    .forEach((value: number, key: string) => {
      options.push({ value: key, label: `${filterLabelFormatter ? filterLabelFormatter(key) : key} (${value})` });
    }); 

  return (
    <XGSSelect
      id='column-filter-select'
      value={value}
      options={options}
      disabled={false}
      onValueChange={(value) => {
        column.setFilterValue(value?.value || undefined);
        setValue(value!)
      }}
      isClearable
    />
  );
};