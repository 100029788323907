import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button, { ButtonSizes, ButtonThemes } from "../../../ui-components/button/button";
import DownloadPDF from "../../download-invoice-pdf/downloadPDF";
import { getMultiplePaymentInfo } from "../../../slices/payment/paymentSlice";
import { AgingInvoicesState } from "../../../slices";
import { agingInvoicesSelector } from "../../../slices/aging/agingInvoicesSlice";

interface InvoicesSelectedBarProps {
  selectedInvoices: number[];
  onReset: () => void;
}

const AgingInvoicesSelectedBar: React.FC<InvoicesSelectedBarProps> = (props) => {
  const agingInvoicesState: AgingInvoicesState = useSelector(
    agingInvoicesSelector
  );
  const dispatch = useDispatch();

  const getInvoiceNumbers = () => {
    let invoiceNumbers: number[] = [];
    if (!!props.selectedInvoices?.length) {
      invoiceNumbers = [...props.selectedInvoices];
    } else {
      invoiceNumbers = agingInvoicesState.invoices
        .filter((invoice) => !invoice.paid)
        .map((invoice) => invoice.invoiceNumber);
    }
    return invoiceNumbers;
  };

  const getPaymentSum = () => {
    let invoiceNumbers: number[] = [];
    invoiceNumbers = getInvoiceNumbers();
    const paymentSum = (agingInvoicesState.invoices.length > 0)
      ? agingInvoicesState.invoices
        .filter((invoice) => invoiceNumbers.indexOf(invoice.invoiceNumber) >= 0)
        .map((invoice) => invoice.openAmount)
        .reduce((sum, curr) => (sum + curr), 0)
      : undefined;
    return paymentSum;
  };

  const pay = async () => {
    let invoiceNumbers: number[] = [];
    invoiceNumbers = getInvoiceNumbers();
    dispatch(getMultiplePaymentInfo({ invoiceNumbers }, getPaymentSum()));
  };

  const paymentSum = getPaymentSum();

  const maxInvoices = props.selectedInvoices?.length > 25;

  return (
    <div className="xgs-aging-invoices__selected-bar">
      {maxInvoices && <div className="xgs-aging-invoices__selected-bar__warning">You cannot pay more than 25 invoices at the same time.</div>}
      <div className="xgs-aging-invoices__selected-bar__content">
        <div className="xgs-aging-invoices__selected-bar__item xgs-aging-invoices__mobile-hidden">
          <div>
            <span className="xgs-aging-invoices__selected-bar__summary-value">Selection Summary</span>
          </div>
          <div>
            <span className="xgs-aging-invoices__selected-bar__summary-label">Invoices:</span>
            &nbsp;
            <span className="xgs-aging-invoices__selected-bar__summary-value">{getInvoiceNumbers().length}</span>
          </div>

          {!!paymentSum && (<div>
            <span className="xgs-aging-invoices__selected-bar__summary-label">Total&nbsp;Amount:</span>
            &nbsp;
            <span className="xgs-aging-invoices__selected-bar__summary-value">$&nbsp;{paymentSum.toFixed(2)}</span>
          </div>)}
        </div>
        <div className="xgs-aging-invoices__selected-bar__item">
          {!!props.selectedInvoices?.length && <Button className="xgs-aging-invoices__mobile-hidden" theme={ButtonThemes.gray} onClick={props.onReset}>Clear Selection</Button>}
          <DownloadPDF invoiceNumbers={props.selectedInvoices} type="multiple" />
          <Button
            className="xgs-aging-invoices__selected-bar__pay-button"
            theme={ButtonThemes.blue}
            size={ButtonSizes.autoMedium}
            onClick={pay}
            disabled={maxInvoices}
          >
            Pay Selected
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AgingInvoicesSelectedBar;