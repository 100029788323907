import React, { useState, useMemo, useEffect, memo, forwardRef, ReactNode, useRef } from "react";
import { useReactTable, getCoreRowModel, flexRender, getExpandedRowModel, getSortedRowModel, Column, getFilteredRowModel, getFacetedRowModel, getFacetedUniqueValues } from "@tanstack/react-table";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import TableTotal, { BaseTableTotalProps } from "./table-total/tableTotal";
import Loading from "../loading/loading";
import useWindowSize from "../../hooks/useWindowsSize";
import { makeId, getHeightBeforeEndOfPage } from "../../hooks/utils";
import { cn } from "../../services/common/className";
import { Filter } from "./filters/filters";
import SelectedBar from "./selected-bar/selectedBar";
import { ReactComponent as ArrowUpIcon} from "../../images/arrow_up.svg";
import { ReactComponent as ArrowDownIcon} from "../../images/arrow_down.svg";
import { SortOrder } from "../../app/data/common/models";

import "./table.scss";

// eslint-disable-next-line
export interface TableProps<T> {
  data: any;
  columns: any;
  autoHeight?: boolean;
  responsive?: boolean;
  onRowClicked?: any; // () => void
  ignoreCellClick?: string;

  enableRowSelection?: boolean | ((row: any) => boolean); 
  onSelectedRowsChange?: any;
  renderSelectedBar?: (rows: any, toggleSelected: (selected: boolean) => void) => ReactNode;
  displaySelectedBar?: boolean;
  autoResetSelectedRows?: boolean;
  clearSelection?: (f: () => void) => void;

  isLoading?: boolean;
  isBlocked?: boolean;
  keepTableOnLoading?: boolean;
  cursorPointer?: boolean;
  totals?: BaseTableTotalProps[];
  customHeaderRowClass?: string;
  rowHeight?: number;

  infiniteScroll?: boolean;
  infiniteScrollHasNext?: boolean;
  infiniteScrollLoading?: boolean;
  onInfiniteScroll?: any;

  noResultsText?: string;
  noResultsComponent?: ReactNode;

  minTableHeight?: number;
  strictMinTableHeight?: boolean;
  maxTableWidth?: number;

  sorting?: boolean;
  sortingExcludeColumnIndexes?: number[];
  onSortByColumn?: (i: number, a: SortOrder) => void; // columnIndex, order
  clearSorting?: any;
  enableMultiSort?: boolean;
  enableSortingRemoval?: boolean; 

  highlightRow?: number | null;
  highlightSubRows?: number[];
  highlightRowClass?: string;

  subRowsField?: string;

  filters?: boolean;
}

const scrollbarWidth = () => {
  // https://davidwalsh.name/detect-scrollbar-width
  const scrollDiv = document.createElement("div");
  scrollDiv.setAttribute("style", "width: 100px; height: 100px; overflow: scroll; position: absolute; top: -9999px;");
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

const getTableHeight = (tableId: string, windowHeight: number, strictMinTableHeight: boolean, minTableHeight?: number) => {
  let height = getHeightBeforeEndOfPage(
    `.xgs-table.${tableId}`,
    300,
    windowHeight,
    40
  );
  const minHeight = minTableHeight || 100;
  return !strictMinTableHeight && (height > minHeight) ? height - 20 : minHeight;
};

const checkboxColumnSize = 44;

const Table = memo(forwardRef<any, TableProps<any>>((props, ref) => {
  const {
    autoHeight,
    columns,
    cursorPointer,
    data,
    ignoreCellClick,
    infiniteScroll,
    infiniteScrollHasNext,
    infiniteScrollLoading,
    isBlocked,
    isLoading,
    noResultsText,
    noResultsComponent,
    onInfiniteScroll,
    onRowClicked,
    enableRowSelection,
    onSelectedRowsChange,
    autoResetSelectedRows,
    onSortByColumn,
    responsive,
    rowHeight,
    sorting,
    totals,
    enableMultiSort,
    enableSortingRemoval,
    filters,
  } = props;
  const windowHeight = useWindowSize()[1];
  const [tableId] = useState(`xgs-table-${makeId(5)}`);
  const scrollBarSize = useMemo(() => scrollbarWidth(), []);
  const selectedBarRef = useRef<HTMLDivElement>();

  const selectedBarHeight = selectedBarRef.current?.clientHeight || 0;

  const {    
    getHeaderGroups,
    getRowModel,
    getSelectedRowModel,
    getFilteredSelectedRowModel,
    getIsAllRowsSelected,
    getToggleAllRowsSelectedHandler,
    toggleAllRowsSelected,
    toggleAllRowsExpanded,
    resetSorting,
    resetRowSelection,
    getAllColumns,
    getTotalSize,
  } = useReactTable(
    {
      columns,
      data,
      getCoreRowModel: getCoreRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
      ...props.subRowsField && { getSubRows: (row) => row[props.subRowsField] },
      enableRowSelection: enableRowSelection ?? true,
      getSortedRowModel: getSortedRowModel(),
      enableMultiSort: enableMultiSort,
      isMultiSortEvent: () => !!enableMultiSort,
      enableSortingRemoval: enableSortingRemoval,
      manualSorting: !!onSortByColumn,
      ...filters && {
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
      },
    },        
  );

  useEffect(() => {
    if (autoResetSelectedRows) {
      resetRowSelection(true);
    }
    toggleAllRowsExpanded(true);
  }, [data, toggleAllRowsExpanded, autoResetSelectedRows, resetRowSelection]);

  const selectedRows = filters ? getFilteredSelectedRowModel().flatRows : getSelectedRowModel().flatRows;

  useEffect(() => {
    if (onSelectedRowsChange) {
      onSelectedRowsChange({
        selectedRowsData: selectedRows.map(d => d.original)
      });
    }
  }, [onSelectedRowsChange, selectedRows]);

  const contentHasScrollbar = () => {
    const container = document.querySelector(`#${tableId}-content-area--js`);
    const area = document.querySelector(`#${tableId}-content-area--js > div > div`);
    if (container && area) {
      return area.scrollHeight > container.clientHeight;
    } else {
      return false;
    }
  };

  props.clearSorting && props.clearSorting(resetSorting);
  props.clearSelection && props.clearSelection(resetRowSelection);

  const selectedBarVisible = !!props.renderSelectedBar && !!(selectedRows.length || props.displaySelectedBar);

  const extraRowsCount = infiniteScrollHasNext || selectedBarVisible ? 1 : 0;
  const subitemsCount = data.reduce((result: number, item: any) => {return props.subRowsField && item[props.subRowsField] ? result + item[props.subRowsField].length : result}, 0);
  const itemCount = data.length + subitemsCount + extraRowsCount;

  const loadMoreItems = async (startIndex: number, stopIndex: number) => {
    // only load 1 portion of items at a time
    if (!infiniteScrollLoading) {
      onInfiniteScroll();
    }
  };

  const isItemLoaded = React.useCallback(
    (index: any) => { 
      return !infiniteScrollHasNext || index < data.length;
    },
    [infiniteScrollHasNext, data]
  );

  const onClickSortableColumn = (columnIndex: number, column: Column<any>, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onSortByColumn?.(columnIndex, column.getNextSortingOrder() || "");   
    column.getToggleSortingHandler()?.(e);
  }; 

  const highlightRow = (row: any) => {
    if (row.depth === 0) return props.highlightRow !== undefined && props.highlightRow === row.index;
    const parentIndex = row.id.split(".")[0];
    return props.highlightSubRows?.length && parentIndex === props.highlightRow?.toString() && props.highlightSubRows?.findIndex(index => index === row.index) >= 0;
  };

  const RenderRow = React.useCallback(
    ({ height, index, style }) => {
      const row = getRowModel().rows[index];
      if (!row) {
        return (
          <div
            style={{
              ...style,
              ...selectedBarVisible && {
                height: infiniteScrollHasNext ? `${selectedBarHeight + style.height + 8}px` : `${selectedBarHeight + 8}px`
              },
            }}
            className="tr xgs-table__spinner-row"
          >
            <div className="td" style={infiniteScrollHasNext ? { height: style.height } : {height: "0px", padding: "0px"}}>
              {infiniteScrollHasNext && "Loading..."}
            </div>
          </div>
        )
      } else {        
        return (
          <div            
            style={{
              ...style,
              width: responsive ? "100%" : "max-content",              
            }}            
            className={"tr"
                + ((cursorPointer && !ignoreCellClick) ? " cursor-pointer" : "")
                + (row.index % 2 === 0 ? "" : " xgs-table__even-row")
                + (row.subRows?.length ? " xgs-table__group-row-header" : "")
                + (row.depth > 0 ? " xgs-table__group-row" : "")
                + (highlightRow(row) ?
                  (props.highlightRowClass ? ` ${props.highlightRowClass}`: " xgs-table__highlighted-row") :
                  "")
            }
            onClick={() => (onRowClicked && !ignoreCellClick) ? onRowClicked(row.original, index) : false}
          >
            {enableRowSelection && (
              <div
                style={{
                  flex: "0 0 auto",
                  width: `${checkboxColumnSize}px`,
                }}
                className="td"
                key="td-select"
              >
                {row.getCanSelect() && (
                  <input
                    type="checkbox"
                    checked={row.getIsSelected()}
                    onChange={row.getToggleSelectedHandler()}
                    indeterminate={undefined}
                  />
                )}
              </div>
            )}
            
            {row.getVisibleCells().map(cell => {
              return (
                <div
                  key={cell.id}          
                  style={{ 
                    width: cell.column.getSize(),
                    maxWidth: cell.column.columnDef.maxSize || "none",
                    minWidth: cell.column.columnDef.minSize || "none",
                  }}
                  className={
                    "td"
                      + ((cursorPointer
                        && ignoreCellClick
                        && (cell.column.id !== ignoreCellClick)) ? " cursor-pointer" : "")
                  }
                  onClick={() => (onRowClicked && ignoreCellClick && (cell.column.id !== ignoreCellClick)) ? onRowClicked(row.original) : false}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              )
            })}
            {responsive && scrollBarSize === 0 && (
              <div className="xgs-table__scroll-spacer"></div>
            )}
          </div>
        )
      }
    },
    // we don't use selectedRows in the RenderRow, but we need to re-render row if it changed
    // eslint-disable-next-line
    [onRowClicked, cursorPointer, getRowModel().rows, getSelectedRowModel().flatRows, columns]
  );

  const getTableMaxWidth = () => {
    if (props.maxTableWidth) return props.maxTableWidth;

    if (isLoading && !props.keepTableOnLoading) return "none";

    return responsive ? "none" : "max-content";
  }

  const getTableMinWidth = () => {
    const columnsSize = getAllColumns().reduce((result, column) => {
      const width = column.columnDef.minSize || column.columnDef.size || 0;
      return result + width;
    }, 0);
    return enableRowSelection ? checkboxColumnSize + columnsSize : columnsSize;
  }

  const totalSize = enableRowSelection ? checkboxColumnSize + getTotalSize() : getTotalSize();

  return (
    <div
      className={cn("xgs-table__wrapper")({ loading: isLoading })}      
      style={{
        maxWidth: getTableMaxWidth(),
      }}
    >
      {isLoading && !props.keepTableOnLoading && (
        <div className="xgs-table__loading">
          <Loading isLoading={true} />
        </div>
      )}
      {(!isLoading || isBlocked || props.keepTableOnLoading) && (
        <div          
          className={cn("xgs-table")({ responsive: responsive }, tableId)}
          ref={ref}
          style={{
            minWidth: getTableMinWidth()
          }}
        >
          {((isLoading && props.keepTableOnLoading) || isBlocked) && (
            <div className="xgs-table__loading-background">
              <div
                className="xgs-table__loading xgs-table__loading--over"
                style={{
                  left: "calc(50% - 16px)",
                }}>
                <Loading isLoading={isLoading} />
              </div>
            </div>
          )}
          {getHeaderGroups().map(headerGroup => (
            <div
              key={headerGroup.id}
              style={{
                width: responsive
                  ? "100%"
                  : totalSize +
                  (!infiniteScroll ? scrollBarSize : ((!data || data?.length === 0) ? (scrollBarSize - 4) : 0)),
                minWidth: "none",
                height: filters ? 80 : 50,
              }}
              className={
                "xgs-table__headers tr "
                + (props.customHeaderRowClass ? ` ${props.customHeaderRowClass} ` : "")
              }
            >        
            
              {enableRowSelection && (
                <div
                  style={{
                    flex: "0 0 auto",
                    width: `${checkboxColumnSize}px`,
                  }}
                  className="th"
                  key="th-select"
                >
                  <div>
                    <input
                      type="checkbox"
                      checked={getIsAllRowsSelected()}
                      onChange={getToggleAllRowsSelectedHandler()}
                      indeterminate={undefined}
                    />
                  </div>
                </div>
              )}
              {headerGroup.headers.map((header, columnIndex) => (
                <div
                  style={{
                    width: header.getSize(),
                    maxWidth: header.column.columnDef.maxSize || "none",
                    minWidth: header.column.columnDef.minSize || "none",
                  }}
                  className="th"                  
                  key={`th-${columnIndex}`}
                >
                  <div
                    className={(sorting && header.column.getCanSort() ? "xgs-table__headers__sortable-item" : "")}
                    onClick={sorting && header.column.getCanSort() ? (e) => { onClickSortableColumn(columnIndex, header.column, e) } : undefined}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}

                    {sorting && header.column.getCanSort() && (
                      
                      <div className="xgs-table__headers__sortable-item__icon">
                        <div className="xgs-table__headers__sortable-item__icon__arrow">
                          {(header.column.getIsSorted() === "asc" || !header.column.getIsSorted()) && (
                            <ArrowUpIcon />
                          )}
                        </div>

                        <div className="xgs-table__headers__sortable-item__icon__arrow">
                          {(header.column.getIsSorted() === "desc" || !header.column.getIsSorted()) && (
                            <ArrowDownIcon />
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  {filters && header.column.getCanFilter() ? (
                    <Filter column={header.column} />
                  ) : null}
                </div>
              ))}
              {responsive && (scrollBarSize === 0 || contentHasScrollbar()) && (
                <div className="xgs-table__scroll-spacer"></div>
              )}
            </div>
          ))}
          <div className={cn("xgs-table__content")({empty: !data || data?.length === 0})}>
            {data?.length > 0 && (
              <div
                id={`${tableId}-content-area--js`}
                className={responsive ? "xgs-table__full-width" : ""}                
                style={{
                  height: "100%",
                  width: "100%"
                }}
              >
                {(infiniteScroll && (data.length > 0)) ? (
                  // with infinite scroll
                  <>
                    {autoHeight && (
                      // autoHeight true
                      <AutoSizer>
                        {({ height, width }) => (
                          <InfiniteLoader
                            isItemLoaded={isItemLoaded}
                            itemCount={50000}
                            loadMoreItems={loadMoreItems}
                            minimumBatchSize={50}                            
                          >
                            {({ onItemsRendered, ref }) => (
                              <FixedSizeList
                                className="xgs-table__fixed-size-list"
                                height={height - 50}
                                itemCount={itemCount}
                                itemSize={rowHeight || 45}
                                onItemsRendered={onItemsRendered}
                                ref={ref}
                                width={responsive ? "100%" : totalSize + scrollBarSize}                                                 
                              >
                                {RenderRow}
                              </FixedSizeList>
                            )}
                          </InfiniteLoader>
                        )}
                      </AutoSizer>
                    )}
                    {!autoHeight && (
                      // autoHeight false
                      <InfiniteLoader
                        isItemLoaded={isItemLoaded}
                        itemCount={50000}
                        loadMoreItems={loadMoreItems}
                        minimumBatchSize={50}
                      >
                        {({ onItemsRendered, ref }) => (
                          <FixedSizeList
                            className="xgs-table__fixed-size-list"
                            height={getTableHeight(tableId, windowHeight, props.strictMinTableHeight || false, props.minTableHeight)}
                            itemCount={itemCount}
                            itemSize={rowHeight || 45}
                            onItemsRendered={onItemsRendered}
                            ref={ref}
                            width={responsive ? "100%" : totalSize + scrollBarSize}
                          >
                            {RenderRow}
                          </FixedSizeList>
                        )}
                      </InfiniteLoader>
                    )}                  
                  </>
                ) : (                  
                  // without infinite scroll
                  getRowModel().rows.map((row, rowIndex) => {                    
                    return (
                      <div
                        style={{
                          width: responsive ? "100%" : totalSize + scrollBarSize,
                        }}
                        className={"tr"
                          + (cursorPointer ? " cursor-pointer" : "")
                          + (highlightRow(row) ?
                            (props.highlightRowClass ? ` ${props.highlightRowClass}` : " xgs-table__highlighted-row") :
                            "")
                        }
                        onClick={() => onRowClicked ? onRowClicked(row.original) : false}
                        key={`tr-${rowIndex}`}
                      >
                        {enableRowSelection && (
                          <div
                            style={{
                              flex: "0 0 auto",
                              width: `${checkboxColumnSize}px`,
                            }}
                            className="td"
                            key="td-select"
                          >
                            {row.getCanSelect() && (
                              <input
                                type="checkbox"
                                checked={row.getIsSelected()}
                                onChange={row.getToggleSelectedHandler()}
                                indeterminate={undefined}
                              />
                            )}
                          </div>
                        )}
                        {row.getVisibleCells().map((cell, cellIndex) => (
                          <div
                            style={{
                              width: cell.column.getSize(),
                              maxWidth: cell.column.columnDef.maxSize || "none",
                              minWidth: cell.column.columnDef.minSize || "none",
                            }}
                            className="td"
                            key={cell.id}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </div>
                        ))}
                        {responsive && (scrollBarSize === 0 || contentHasScrollbar()) && (
                          <div className="xgs-table__scroll-spacer"></div>
                        )}
                      </div>
                    )
                  })
                )}
              </div>
            )}
            {!getRowModel().rows.length && (
              <div 
              className="xgs-table__no-records"
              >
                {!isLoading && (
                  <>
                    {noResultsComponent || (<div>{noResultsText || "There are no records to display"}</div>)}
                  </>
                )}
              </div>
            )}
          </div>
          {totals && (
            <div style={{ width: totalSize + scrollBarSize }}>
              <TableTotal totals={totals} data={data} />
            </div>
          )}
        </div>
      )}

      {selectedBarVisible && (
        <SelectedBar ref={selectedBarRef}>
          {props.renderSelectedBar?.(selectedRows, toggleAllRowsSelected)}
        </SelectedBar>
      )}
    </div>
  );
}));

export default Table;
