import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import ExportService from "../../app/data/export/exportService";
import { initialExportState } from "./ExportState";

const exportService = ExportService.getInstance();

export const exportSlice = createSlice({
  name: "export",
  initialState: initialExportState,
  reducers: {
    resetExportLink: (state) => initialExportState,
    exportLink_was_started: (state) => {
      state.exportLoading = true;
    },
    exportLink_was_succeed: (state, { payload }) => {
      state.exportLoading = false;
      state.exportId = payload.exportId;
      state.exportLink = payload.exportLink;
    },
    exportLink_was_failed: (state) => {
      state.exportLoading = false;
    }
  }
});

export const {
  resetExportLink,
  exportLink_was_started,
  exportLink_was_succeed,
  exportLink_was_failed
} = exportSlice.actions;

export const exportSelector = (state: IState) => state.export;

export const getExportLink = (
  invoices?: number | number[],
  type?: string,
  exportId?: string,
  onSuccess?: (exportLink: string) => void,
  onFailed?: () => void
): AppThunk => async (dispatch) => {
  if (!invoices || !type) return;
  dispatch(exportLink_was_started());

  if (!exportId) {
    // get export process ID
    const response = await exportService.getExportId({ invoices, type }, Array.isArray(invoices))
    if (response.ok()) {
      exportId = response.data.exportId;
    } else {
      dispatch(exportLink_was_failed());
      onFailed && onFailed();
    }
  }

  if (exportId) {
    // get link of exported file
    let attempt = 0;
    while (attempt < 60) {
      const response = await exportService.getExportLink(exportId);
      if (response.ok()) {
        if (response.data.status === "DONE") {
          dispatch(exportLink_was_succeed({
            exportId: exportId,
            exportLink: response.data.fileUrl
          }));
          onSuccess && onSuccess(response.data.fileUrl);
          break;
        }
        if (response.data.status === "FAILED") {
          dispatch(exportLink_was_failed());
          onFailed && onFailed();
          break;
        }
      } else {
        dispatch(exportLink_was_failed());
        onFailed && onFailed();
        break;
      }
      await new Promise((d) => setTimeout(d, 1000));
      attempt++;
    }
  }
};

const exportReducer = exportSlice.reducer;
export default exportReducer;
