import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface ShipmentCommentModel {
  type: string;
  text: string;
  dateAdded: string;
  timeAdded: string;
};

export interface ShipmentAddCommentModel {
  note: string;
};

export const ShipmentAddCommentSchema: Yup.ObjectSchema<ShipmentAddCommentModel> = Yup.object({
  note: Yup.string().required(validationMessages.required).matches(/(\w+|-+)/, validationMessages.notValid).max(60, "The value must be no more than 60 characters")
}).defined();
