import { EmergencyAlertResponseModel } from "../../app/data/emergency-alert/models";

export default interface EmergencyAlertState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError: string;
  requestCreator: string;
  draftEmergencyAlert: EmergencyAlertResponseModel | null;
  publishedEmergencyAlert: EmergencyAlertResponseModel | null;
};

export const initialEmergencyAlertState: EmergencyAlertState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: "",
  requestCreator: "",
  draftEmergencyAlert: null,
  publishedEmergencyAlert: null
};
