import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import React from "react";

const columnHelper = createColumnHelper<any>();

const getPicklistsColumns = (
) => [
    columnHelper.accessor("picklistNumber", {
      id: "picklistNumber",
      header: "Picklist # / Description",
      cell: (cellProps) => {
        return (
          <div className="cell__picklist-identifiers">
            <div className="picklist-number">{cellProps.getValue()}</div>
            <div>{cellProps.row.original.picklistDesc}</div>
          </div>
        )
      },
      size: 136,
    }),
    columnHelper.accessor("trailerNumber", {
      id: "trailerNumber",
      header: "Trailer #",
      cell: (cellProps) => (<>{cellProps.getValue()}</>),
      size: 88,
    }),
    columnHelper.accessor("driverName", {
      id: "driverName",
      header: "Assigned Driver",
      cell: (cellProps) => (<>{cellProps.getValue()}</>),
      size: 128,
    }),
    columnHelper.accessor("probillsCount", {
      id: "probillsCount",
      header: "Probills",
      cell: (cellProps) => (<>{cellProps.getValue()}</>),
      size: 80,
    }),
    columnHelper.accessor("totalItemsCount", {
      id: "totalItemsCount",
      header: "Items",
      cell: (cellProps) => (<>{cellProps.getValue()}</>),
      size: 70,
    }),
    columnHelper.accessor("totalWeight", {
      id: "totalWeight",
      header: "Weight (Lbs)",
      cell: (cellProps) => (<>{cellProps.getValue()}</>),
      size: 102,
    }),
    columnHelper.accessor("loadedItemsCount", {
      id: "loadedItemsCount",
      header: "Loading Progress (Items)",
      cell: (cellProps) => {

        let loadedItemsCount = cellProps.getValue();
        let totalItemsCount = cellProps.row.original.totalItemsCount;
        let progressFloatValue = 0;

        if (totalItemsCount && totalItemsCount > 0 && loadedItemsCount) {
          progressFloatValue = Math.floor((loadedItemsCount / totalItemsCount) * 100)
        }

        return (
          <div className="cell__progress-container">
            <span className="cell__progress-info">
              <span className="cell__progress-percentage">{progressFloatValue}%</span>
              <span className="cell__progress">{loadedItemsCount}/{totalItemsCount}</span>
            </span>
            <div className={"cell__progress-bar" + (progressFloatValue === 100 ? " --completed" : "")}>
              <div style={{ width: progressFloatValue + "%" }}></div>
            </div>
          </div>
        )
      },
      size: 280,
    }),
    columnHelper.accessor("createdBy", {
      id: "createdBy",
      header: "Created by / Created at",
      cell: (cellProps) => {
        if (!cellProps.row.original.createdAt) {
          return (
            <div className="cell__picklist-creation-details">
              <div>{cellProps.getValue()}</div>
              <div></div>
            </div>
          )
        } else {
          const date = moment(cellProps.row.original.createdAt, "MMM D YYYY hh:mm");
          return (
            <div className="cell__picklist-creation-details">
              <div className={"cell__picklist-creation-details__username"}>{cellProps.getValue()}</div>
              <div>
                <div>{date.format("MMM D, YYYY")}</div>
                <div className="text-light">{date.format("h:mm A")}</div>
              </div>
            </div>
          )
        }
      },
      size: 128,
    }),
    columnHelper.accessor("missingItemsCount", {
      id: "missingItemsCount",
      header: "Missing Items",
      cell: (cellProps) => (<>{cellProps.getValue()}</>),
      size: 80,
    }),
    columnHelper.accessor("lastLoadedAt", {
      id: "lastLoadedAt",
      header: "Last Loaded at",
      cell: (cellProps) => {
        let lastLoadedMessage = <div
          className={cellProps.row.original.isLateLoading ? " --late-loading" : ""}
        >Not Loaded yet</div>;
        if (cellProps.getValue()) {
          const date = moment(cellProps.getValue(), "MMM D YYYY hh:mm")
          lastLoadedMessage = <> <div
            className={cellProps.row.original.isLateLoading ? " --late-loading" : ""}
          >{date.format("MMM D, YYYY")}</div>
            <div
              className={cellProps.row.original.isLateLoading ? " --late-loading" : "text-light"}
            >{date.format("h:mm A")}</div></>
        }
        return (
          <div
            className={"cell__last-loaded"}
          >
            {lastLoadedMessage}
          </div>
        )
      },
      size: 128,
    })
  ];

export default getPicklistsColumns;