import { BaseState } from "..";
import ShipmentDetailsModel, { ShipmentTrackingHistoryModel } from "../../app/data/tracking/ShipmentDetailsModel";

export default interface ShipmentDetailsState extends BaseState {
  shipment?: ShipmentDetailsModel | null;
  error?: string | null;
  needToReload?: boolean;
  dockLogLoading: boolean;
  dockLogLoadingFailed: boolean;
  dockLogLoadingError: string | null;
  dockLogLoaded: boolean;
  dockLog: any;
  statusHistoryLoading: boolean;
  statusHistoryLoadingFailed: boolean;
  statusHistoryLoadingError: string | null;
  statusHistoryLoaded: boolean;
  statusHistory: any;
  statusHistoryPresent: boolean;
  claimsDocumentsLoading: boolean;
  claimsDocumentsLoadingFailed: boolean;
  claimsDocumentsLoadingError: string | null;
  claimsDocumentsLoaded: boolean;
  claimsDocuments?: any;
  addingComment: boolean;
  trackingHistoryLoading: boolean;
  trackingHistoryLoaded: boolean;
  trackingHistoryFailed: boolean;
  trackingHistoryError: string | null;
  trackingHistory?: ShipmentTrackingHistoryModel;
}

export const initialShipmentDetailsState: ShipmentDetailsState = {
  shipment: null,
  error: null,
  loading: false,
  loadingFailed: false,
  loaded: false,
  needToReload: false,
  dockLogLoading: false,
  dockLogLoadingFailed: false,
  dockLogLoadingError: null,
  dockLogLoaded: false,
  dockLog: null,
  statusHistoryLoading: false,
  statusHistoryLoadingFailed: false,
  statusHistoryLoadingError: null,
  statusHistoryLoaded: false,
  statusHistory: null,
  statusHistoryPresent: false,
  claimsDocumentsLoading: false,
  claimsDocumentsLoadingFailed: false,
  claimsDocumentsLoadingError: null,
  claimsDocumentsLoaded: false,
  claimsDocuments: undefined,
  addingComment: false,
  trackingHistoryLoading: false,
  trackingHistoryLoaded: false,
  trackingHistoryFailed: false,
  trackingHistoryError: null,
  trackingHistory: undefined
};
