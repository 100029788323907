import api, { ApiResponse } from "./../api";
import {
  AnnouncementModel,
  AnnouncementResponseModel
} from "./models";

class AnnouncementService {
  private static instance: AnnouncementService;
  private constructor() {}
  static getInstance(): AnnouncementService {
    if (!AnnouncementService.instance) {
      AnnouncementService.instance = new AnnouncementService();
    }
    return AnnouncementService.instance;
  }

  public getAnnouncements = async (): Promise<ApiResponse<AnnouncementResponseModel[]>> => {
    return await api.get("/notifications/announcement");
  };

  public createAnnouncement = async (
    request: AnnouncementModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/notifications/announcement", request);
  };

  public updateAnnouncement = async (
    request: AnnouncementModel
  ): Promise<ApiResponse<any>> => {
    return await api.put("/notifications/announcement", request);
  };

  public getUserAnnouncement = async (): Promise<ApiResponse<AnnouncementResponseModel>> => {
    return await api.get("/notifications/announcement/user");
  };

  public acceptUserAnnouncement = async (
    announcementId: string
  ): Promise<ApiResponse<any>> => {
    return await api.post("/notifications/announcement/user", { announcementId });
  };
};

export default AnnouncementService;
