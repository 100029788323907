import api, { ApiResponse } from "./api";

const handleError = async (response: ApiResponse<any>) => {
  if (response.ok()) {
    return response;
  } else {
    let error = response.getError?.() as any;
    error = !!error ? (typeof error === 'string' ? error : error['message']) : "Something went wrong";
    throw new Error(error);
  }
}

export const apiQueryAdapter = {
  query: async (
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    otherArgs: {
      params?: Record<string, any>;
      body?: Record<string, any>;
    } = {}
  ) => {
    const { params, body } = otherArgs;

    switch (method) {
      case 'GET':
        return handleError(await api.get(url, { params }));

      case 'POST':
        return handleError(await api.post(url, body, { params }));

      case 'PUT':
        return handleError(await api.put(url, body, { params }));

      case 'DELETE':
        return handleError(await api.delete(url, { params, data: body }));

      default:
        throw new Error(`Unsupported HTTP method: ${method}`);
    }
  },
};
