import React from "react";
import Loading from "../../loading/loading";
import { cn } from "../../../services/common/className";
import "./contentContainerToolbar.scss";
import PageMenu from "../../../features/common/page-menu/pageMenu";

export interface ContentContainerToolbarProps {
  title?: string;
  isLoading?: boolean;
  className?: string;
  pageId?: string;
  mods?: {
    ["full-width"]?: boolean;
  };
}

const ContentContainerToolbar: React.FC<ContentContainerToolbarProps> = (
  props
) => {
  return (
    <div className={cn("xgs-site__content-container__toolbar")(props.mods, props.className)}>
      <div className="xgs-site__content-container__title-container">
        <span className="xgs-site__content-container__title">{props.title}</span>
        {props.pageId && <span className="xgs-site__content-container__page-menu"><PageMenu pageId={props.pageId} /></span>}
        <Loading isLoading={props.isLoading} />
      </div>
      <div className="xgs-site__content-container__toolbar__buttons">
        {props.children}
      </div>
    </div>
  );
};

export default ContentContainerToolbar;
