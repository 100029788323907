import React from "react";
import moment from "moment";
import { createColumnHelper } from "@tanstack/react-table";
import Tag from "../../ui-components/molecules/tag/tag";
import { TagColor } from "../../app/data/common/tagColor";
import dateFormats from "../../app/data/common/dateFormats";

const columnHelper = createColumnHelper<any>();

export const getTripsColumns = () => (
  [
    columnHelper.accessor("dispatchDate", {
      id: "dispatchDate",      
      header: () => (
        <div style={{  }}>
          Manifest # / <br/> Dispatch Date
        </div>
      ),
      cell: (cellProps) => (
        <div className="xgs-lmi__trips__date-cell">
          <span>{cellProps.row.original.manifestNumber} </span><br/>
          {moment(cellProps.row.original.dispatchedDate, "YYYYMMDD").format(dateFormats.uiDate)}
        </div>
      ),
      size: 110
    }),
    columnHelper.accessor("driverId", {
      id: "driverId",      
      header: "Driver",
      cell: (cellProps) => (
        cellProps.row.original.driver ? <div className="xgs-lmi__trips__driver-cell">
          {cellProps.row.original.driver?.firstName + " " + cellProps.row.original.driver?.lastName} <br/>
          <span>{cellProps.row.original.driver?.driverId}</span>
        </div> : <></>
      ),
      size: 110,
    }),
    columnHelper.accessor("lane.laneName", {
      id: "lane.laneName",      
      header: "Lane Name",
      size: 110,
    }),
    columnHelper.accessor("lane.miles", {
      id: "lane.miles",      
      header: "Lane Miles",
      size: 63,
    }),
    columnHelper.accessor("origin", {
      id: "origin",      
      header: "Origin",
      cell: (cellProps) => (
        <div className="xgs-lmi__trips__address-cell">
          {cellProps.row.original.originTerminalAirportcode} - {cellProps.row.original.originTerminalName} <span>{cellProps.row.original.originTerminalNumber}</span>
        </div>
      ),
      size: 140,
    }),
    columnHelper.accessor("destination", {
      id: "destination",      
      header: "Destination",
      cell: (cellProps) => (
        <div className="xgs-lmi__trips__address-cell">
          {cellProps.row.original.destinationTerminalAirportcode} - {cellProps.row.original.destinationTerminalName} <span>{cellProps.row.original.destinationTerminalNumber}</span>
        </div>
      ),
      size: 140,
    }),
    columnHelper.accessor("relayCity", {
      id: "relayCity",      
      header: "Relay Location",
      cell: (cellProps) => (
        cellProps.row.original.lane ? <>
          {
            [cellProps.row.original.lane.relayCity, cellProps.row.original.lane.relayState, cellProps.row.original.lane.relayZipcode]
              .filter(val => !!val)
              .join(", ")
          }
        </> : <></>
      ),
      size: 106,
    }),
    columnHelper.accessor("isReviewed", {
      id: "isReviewed",      
      header: "Status",
      cell: (cellProps) => (
        cellProps.getValue() ? <Tag mods={{ color: TagColor.GREEN }}>REVIEWED</Tag> : <Tag mods={{ color: TagColor.RED}}>NOT REVIEWED</Tag>
      ),
      size: 120,
    }),
    columnHelper.accessor("exceptions", {
      id: "exceptions",      
      header: "Exceptions",
      cell: (cellProps) => (
        cellProps.getValue().map((exc: any) => exc.type.toLowerCase().capitalize()).join(", ")
      ),
      size: 174,
    })
  ]
)