import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "../../../ui-components/table/table";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import {
  getPickups,
  pickupAssignmentSelector
} from "../../../slices/pickup/pickupAssignmentSlice";
import { PickupsGroupModel, PickupsResponseContentModel } from "../../../app/data/pickup/models";
import Tag from "../../../ui-components/molecules/tag/tag";
import { TagColor } from "../../../app/data/common/tagColor";
import { getPickupGroupStatus, isGroup, isSubPickup, getStatusColor } from "../../../services/pickups";
import { PickupStatus } from "../../../app/data/common/route";
import PickupOuterResultBlock from "./pickupOuterResultBlock";

const ROW_HEIGHT = 56;

interface PickupTableRow extends PickupsResponseContentModel {
  pickups?: PickupsResponseContentModel[];
};

const columnHelper = createColumnHelper<PickupTableRow>();

export interface PickupListProps {
  onPickupSelect: (id: string[]) => void;
  onGroupSelect: (id: string) => void;
  onShowPickupDetails: (id: string) => void;
  selectedPickupIds: string[];
  selectedPickupGroupId: string;
  expiredOnly: boolean;
  statuses: PickupStatus[];
  terminal: number | null;
  pickupSearch: string;
  height: number;
  collapsed: boolean;
};

const PickupList: React.FC<PickupListProps> = (props) => {
  const dispatch = useDispatch();
  const pickupAssignmentState = useSelector(pickupAssignmentSelector);
  const tableRef = React.useRef<HTMLElement>(null);

  const isExpired = (date: string, closeTime: string, ) => {
    if (!closeTime) return true;
    if (moment(date).startOf("day").isSame(moment().startOf("day"))) {
      return moment().isAfter(moment(closeTime, "hh:mm:ss"));
    } else {
      return moment().isAfter(moment(date), "day");
    }
  };
  
  const columns = [
    {
      Header: "",
      id: "selectedRow",
      cell: ({ row }: any) => (
        row.depth === 0 && (
          <div className="text-center">
            <input
              type="radio"
              name="selectedOrderRow"
              value="selected"
              checked={isGroup(row.original)
                ? row.original.id === props.selectedPickupGroupId
                : !!props.selectedPickupIds.find(id => row.original.pickup.pickupId === id)
              }
              onChange={(e) => { }}
            />
          </div>
        )),
      size: 52,
      maxSize: 52,
      minSize: 52,
    },
    {
      header: "",
      id: "selectedSubRow",
      cell: ({ row }: any) => {
        if (row.depth === 0) return null;
        const group = pickupAssignmentState.pickups.find(pickup => isGroup(pickup) && pickup.id === row.original.pickup.groupId);
        const status = getPickupGroupStatus((group as PickupsGroupModel)?.pickups);
        return (status === PickupStatus.ASSIGNED || status === PickupStatus.UNASSIGNED || status === PickupStatus.MISSED) && row.original.status !== PickupStatus.CANCELED
          && (
            <div className="text-center">
              <input
                type="checkbox"
                name="selectedOrderSubRow"
                value="selected"
                checked={!!props.selectedPickupIds.find(id => row.original.pickup?.pickupId === id)}
                onChange={(e) => { }}
              />
            </div>
          );
      },
      size: 52,
      maxSize: 52,
      minSize: 52,
    },
    columnHelper.accessor("pickup.pickupNumber", {
      id: "pickup.pickupNumber",
      header: "Pickup #",
      cell: ({ row }) => (
        row.original.pickups ? (
          <span className="xgs-table__group-label">
            Group
            <Tag mods={{ color: TagColor.PURPLE }} mix="xgs-table__group-label__tag">

              {row.original.pickups.length}</Tag>
          </span>
        ) : (<span
          className="xgs-table-link-cell"
          onClick={(e) => {
            e.stopPropagation();
            props.onShowPickupDetails(row.original.pickup?.pickupId);
          }}
        >
          {row.original.pickup?.pickupNumber}
        </span>)
      ),
      size: 100,
    }),
    columnHelper.accessor("pickup.date", {
      id: "pickup.date",
      header: "Time of Availability",
      cell: ({ row }) => {
        const date = row.original.pickup?.date;

        const readyTime = row.original.pickups
        ? moment.min(row.original.pickups
          .filter((pickup: PickupsResponseContentModel) => pickup.status !== PickupStatus.CANCELED)
          .map((pickup: PickupsResponseContentModel) => moment(pickup.pickup.readyTime, "hh:mm:ss"))
        )
        : row.original.pickup?.readyTime;

        const closeTime = row.original.pickups
        ? moment.max(row.original.pickups
          .filter((pickup: PickupsResponseContentModel) => pickup.status !== PickupStatus.CANCELED)
          .map((pickup: PickupsResponseContentModel) => moment(pickup.pickup.closeTime, "hh:mm:ss"))
        )
        : row.original.pickup?.closeTime;

        return (
        <div className={isExpired(date, closeTime) && (row.original.status !== PickupStatus.COMPLETED) ? "xgs-pickup-assignment__orders__time-expired" : ""}>
          <div>{moment(readyTime, "hh:mm:ss").format("h:mm A")} - {moment(closeTime, "hh:mm:ss").format("h:mm A")}</div>
          <div>{date ? date.toUiDateFormat() : ""}</div>
        </div>
      )},
      size: 140,
    }),
    columnHelper.accessor("shipper.name", {
      id: "shipper.name",
      header: "Shipper",
      size: 150,
    }),
    columnHelper.accessor("shipper.address.address1", {
      id: "shipper.address.address1",
      header: "Pickup Address",
      size: 210,
      cell: ({ row }) => (
        <span>
          {row.original.shipper?.address.address1 ? `${row.original.shipper?.address.address1.toLowerCase().capitalize()}, ` : ""}{row.original.shipper?.address.city ? `${row.original.shipper?.address.city.toLowerCase().capitalize()}, ` : ""}{row.original.shipper?.address.state ? `${row.original.shipper?.address.state}, ` : ""}{row.original.shipper?.address.postalCode}
        </span>
      )
    }),
    columnHelper.accessor("consignee.address.address1", {
      id: "consignee.address.address1",
      header: "Consignee Address",
      size: 210,
      cell: ({ row }) => (
        <span>
          {row.original.pickupFullData?.pickupRequest.consigneeAddress1 ? `${row.original.pickupFullData.pickupRequest.consigneeAddress1.toLowerCase().capitalize()}, ` : ""}{row.original.pickupFullData?.pickupRequest.consigneeCity ? `${row.original.pickupFullData.pickupRequest.consigneeCity.toLowerCase().capitalize()}, ` : ""}{row.original.pickupFullData?.pickupRequest.consigneeState ? `${row.original.pickupFullData.pickupRequest.consigneeState}, ` : ""}{row.original.pickupFullData?.pickupRequest.consigneePostalCode}
        </span>
      )
    }),
    columnHelper.accessor("items.count", {
      id: "items.count",
      header: () => (
        <div className="text-center">
          Items
        </div>
      ),
      cell: (cellProps) => {
        const value = cellProps.row.original.pickups
          ? cellProps.row.original.pickups.filter((pickup: PickupsResponseContentModel) => pickup.status !== PickupStatus.CANCELED)
            .reduce((result: number, pickup: PickupsResponseContentModel) => (result + pickup.items.count), 0)
          : cellProps.getValue();

        return (
          <div className="text-center">
            {value}
          </div>
        )
      },
      size: 76,
    }),
    columnHelper.accessor("items.weight", {
      id: "items.weight",
      header: () => (
        <div className="text-center">
          Weight
        </div>
      ),
      cell: (cellProps) => {
        const value = cellProps.row.original.pickups
          ? cellProps.row.original.pickups.filter((pickup: PickupsResponseContentModel) => pickup.status !== PickupStatus.CANCELED)
            .reduce((result: number, pickup: PickupsResponseContentModel) => (result + pickup.items.weight), 0)
          : cellProps.getValue();

        return (
          <div className="text-center">
            {value}
          </div>
        )
      },
      size: 76,
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: "Status",
      cell: (cellProps) => (!cellProps.row.original.pickups && (
        <Tag mods={{ color: getStatusColor(cellProps.getValue()), uppercase: true }}>
          {cellProps.getValue()}
        </Tag>
      )),
      size: 120,
    }),
  ];

  const scrollToIndex = (index: number) => {
    const element = tableRef.current?.getElementsByClassName("xgs-table__fixed-size-list")?.[0];
    
    const scrollPosition = index * ROW_HEIGHT;
    setTimeout(() => { element?.scrollTo(0, scrollPosition); }, 0);
  }

  const onGroupClick = (row: PickupsGroupModel) => {
    props.onGroupSelect(row.id);
    props.onPickupSelect(row.pickups.filter(pickup => pickup.status !== PickupStatus.CANCELED).map((pickup) => pickup.pickup.pickupId));
  }

  const onSubPickupClick = (row: PickupsResponseContentModel) => {
    const group = pickupAssignmentState.pickups.find(pickup => (isGroup(pickup) && pickup.id === row.pickup.groupId));
    const groupStatus = getPickupGroupStatus((group as PickupsGroupModel).pickups);

    if (groupStatus === PickupStatus.COMPLETED) {
      onGroupClick(group as PickupsGroupModel);
      return;
    };

    if (row.status === PickupStatus.CANCELED) {
      props.onGroupSelect(row.pickup.groupId);
      return;
    }

    if (row.pickup.groupId && props.selectedPickupGroupId !== row.pickup.groupId) {
      props.onGroupSelect(row.pickup.groupId);
      props.onPickupSelect([row.pickup.pickupId]);
    } else {
      if (props.selectedPickupIds.find(id => id === row.pickup.pickupId)) {
        props.onPickupSelect(props.selectedPickupIds.filter(id => row.pickup.pickupId !== id));
      } else {
        const ids = [...props.selectedPickupIds, row.pickup.pickupId]
        props.onPickupSelect(ids);
      }
    }
  };

  const onSinglePickupClick = (row: PickupsResponseContentModel) => {
    props.onPickupSelect([row?.pickup.pickupId]);
    props.onGroupSelect("");
  };

  const onRowClick = (row: PickupsResponseContentModel | PickupsGroupModel, index: number) => {
    if (!row) return;

    if (!props.collapsed) scrollToIndex(index);

    if (isGroup(row)) {
      onGroupClick(row);
    } else {
      if (isSubPickup(row)) {
        onSubPickupClick(row);
      } else {
        onSinglePickupClick(row);
      }
    }
  }

  const selectedRowIndex = props.selectedPickupGroupId
    ? pickupAssignmentState.pickups.findIndex(pickup => isGroup(pickup) && pickup.id === props.selectedPickupGroupId)
    : pickupAssignmentState.pickups.findIndex(pickup => !isGroup(pickup) && pickup.pickup.pickupId === props.selectedPickupIds[0]);

  const getSelectedSubRowsIndexes = (selectedRowIndex: number) => {
    const group = pickupAssignmentState.pickups[selectedRowIndex];

    return (group as PickupsGroupModel)?.pickups?.reduce(
      (result: number[], pickup, index) => (props.selectedPickupIds.find(id => id === pickup.pickup.pickupId) ? [...result, index] : result),
      []
    )
  };

  const selectedSubRowsIndexes = props.selectedPickupGroupId
    ? getSelectedSubRowsIndexes(selectedRowIndex) : []

  const onListInfiniteScroll = () => {
    if (pickupAssignmentState.pickupsFetchedAll) return;
    dispatch(getPickups(props.terminal, props.statuses, props.expiredOnly, props.pickupSearch, pickupAssignmentState.pickupsRequest?.lastIds));
  };

  useEffect(() => {
    dispatch(getPickups(props.terminal, props.statuses, props.expiredOnly, props.pickupSearch));
  }, [props.terminal, props.statuses, props.expiredOnly, props.pickupSearch, dispatch]);

  return (
    <div className="xgs-pickup-assignment__orders" style={{height: props.height}}>
      {(pickupAssignmentState.requestFailed && pickupAssignmentState.requestResponseCode && pickupAssignmentState.requestCreator === "GET_PICKUPS") && (
        <XGSErrorMessage>{pickupAssignmentState.requestError}</XGSErrorMessage>
      )}
      {!(pickupAssignmentState.requestFailed && pickupAssignmentState.requestResponseCode && pickupAssignmentState.requestCreator === "GET_PICKUPS") && (
        <Table
          isLoading={pickupAssignmentState.requestStarted && pickupAssignmentState.requestCreator === "GET_PICKUPS"}
          keepTableOnLoading
          columns={columns}
          data={pickupAssignmentState.pickups}
          cursorPointer
          onRowClicked={onRowClick}
          rowHeight={ROW_HEIGHT}
          minTableHeight={240}
          noResultsComponent={
            <>
              {(pickupAssignmentState.requestCreator === "GET_PICKUPS" && pickupAssignmentState.requestResponseCode === 204)
                ? <PickupOuterResultBlock pickupNumber={props.pickupSearch} />
                : "There are no pickups"
              }
            </>
          }
          infiniteScroll
          infiniteScrollLoading={pickupAssignmentState.fetchPortionStarted && pickupAssignmentState.requestCreator === "GET_PICKUPS_PORTION"}
          infiniteScrollHasNext={!pickupAssignmentState.pickupsFetchedAll}
          onInfiniteScroll={onListInfiniteScroll}
          highlightRow={selectedRowIndex}
          highlightSubRows={selectedSubRowsIndexes}
          responsive
          subRowsField="pickups"
          autoHeight={true}
          ref={tableRef}
        />
      )}
    </div>
  );
};

export default PickupList;
