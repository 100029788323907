import {
  ClaimContactModel,
  ClaimFreightChargesModel,
  ClaimGeneralModel,
  ClaimItemModel,
  ClaimRemitToModel
} from "../../app/data/claims/models";
import { XGSSelectOption } from "../../ui-components/xgs-select/xgsSelect";

interface DocumentsDataItem {
  type: string;
  id: string;
  originalFilename: string;
};

export interface ClaimListItem {
  claimNumber: number;
  probill: number;
  bol: string;
  reason: string;
  status: string;
  createdAt: string;
  originalClaimNumber: string;
  totalClaimAmount: number;
  dateResolved: string | null;
  settlementAmount: number | null;
  comments: string;
}

export default interface ClaimsState {
  requestStarted: boolean;
  requestPortionStarted: boolean;
  requestCreator?: string;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError?: string | null;
  claims: ClaimListItem[];
  claimsRequest: any;
  claimsFetchedAll: boolean;
  general?: ClaimGeneralModel;
  contact?: ClaimContactModel;
  remitTo?: ClaimRemitToModel;
  remitToSameAsContact: boolean;
  items: ClaimItemModel[];
  freightChargesOnly?: XGSSelectOption;
  claimAllItems?: XGSSelectOption;
  freightCharges: ClaimFreightChargesModel[];
  totalClaimAmount: number | undefined;
  areFreightChargesIncluded: boolean;
  documentsData: DocumentsDataItem[];
  probillSuggestions: any;
  bolSuggestions: any;
  poNumber: string;
  claimDetails: any;
};

export const initialClaimsState: ClaimsState = {
  requestStarted: false,
  requestPortionStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: null,
  claims: [],
  claimsRequest: null,
  claimsFetchedAll: false,
  general: undefined,
  contact: undefined,
  remitTo: undefined,
  remitToSameAsContact: false,
  items: [],
  freightChargesOnly: undefined,
  claimAllItems: undefined,
  freightCharges: [],
  totalClaimAmount: undefined,
  areFreightChargesIncluded: false,
  documentsData: [],
  probillSuggestions: [],
  bolSuggestions: [],
  poNumber: "",
  claimDetails: undefined
};
