import React from "react";
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ContentContainer from "../../../templates/content-container/contentContainer";
import AnnouncementState from "../../../slices/announcement/AnnouncementState";
import { announcementSelector } from "../../../slices/announcement/announcementSlice";
import AnnouncementForm from "./announcementForm";
import { PAGE_IDS } from "../../../app/data/common/pageIds";

const Announcement: React.FC<{}> = () => {
  const announcementState: AnnouncementState = useSelector(announcementSelector);

  return (
    <ContentContainer
      pageId={PAGE_IDS.ANNOUNCEMENT}
      title="Announcement"
      isLoading={announcementState.requestStarted && announcementState.requestCreator === "GET_DATA"}
    >
      <Tabs>
        <TabList>
          <Tab>Customers</Tab>
          <Tab>Employees</Tab>
        </TabList>
        <TabPanel>
          <AnnouncementForm type="CUSTOMER" />
        </TabPanel>
        <TabPanel>
          <AnnouncementForm type="EMPLOYEE" />
        </TabPanel>
      </Tabs>
    </ContentContainer>
  );
};

export default Announcement;
