import api, { ApiResponse } from "./../api";
import {
  AnnouncementType,
  EmergencyAlertRequestModel,
  EmergencyAlertResponseModel,
  EmergencyAlertStatusType
} from "./models";

class EmergencyAlertService {
  private static instance: EmergencyAlertService;
  private constructor() {}
  static getInstance(): EmergencyAlertService {
    if (!EmergencyAlertService.instance) {
      EmergencyAlertService.instance = new EmergencyAlertService();
    }
    return EmergencyAlertService.instance;
  }

  public getEmergencyAlert = async (): Promise<ApiResponse<EmergencyAlertResponseModel[]>> => {
    return await api.get("/emergency-alert/get");
  };

  public saveEmergencyAlert = async (
    request: EmergencyAlertRequestModel
  ): Promise<ApiResponse<any>> => {
    return await api.put("/emergency-alert/save", request);
  };

  public changeEmergencyAlertStatus = async (
    status: EmergencyAlertStatusType
  ): Promise<ApiResponse<any>> => {
    return await api.put("/emergency-alert/save/status", { status });
  };

  public pushEmergencyAlertToAnnouncements = async (
    recipientType: AnnouncementType[]
  ): Promise<ApiResponse<any>> => {
    return await api.put("/emergency-alert/save/announcement", { recipientType });
  };
};

export default EmergencyAlertService;
