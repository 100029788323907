import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "../../../ui-components/table/table";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import ContentContainer from "../../../templates/content-container/contentContainer";
import PendingRequestsState from "../../../slices/customers/pending/PendingRequestsState";
import {
  getPendingRequests,
  pendingRequestsSelector,
  resetErrors
} from "../../../slices/customers/pending/pendingRequestsSlice";
import { getPortalState } from "../../../slices/user/userSlice";
import RequestDetails from "./requestDetails";
import { REQUEST_TYPES } from "./constants";
import { PAGE_IDS } from "../../../app/data/common/pageIds";

import "./pendingRequests.scss";

const columnHelper = createColumnHelper<any>();

const PendingRequests: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const requestsState: PendingRequestsState = useSelector(pendingRequestsSelector);
  const [requestId, setRequestId] = useState<string | undefined>();

  const columns = [
    columnHelper.accessor("dateCreated", {
      id: "dateCreated",
      header: "Date",
      cell: (cellProps) => (
        <>
          {cellProps.getValue().toUiDateFormat()}
        </>
      ),
      size: 110,
    }),
    columnHelper.accessor("type", {
      id: "type",
      header: "Type",
      cell: (cellProps) => (
        <>
          {REQUEST_TYPES.find(obj => obj.value === cellProps.getValue())?.label}
        </>
      ),
      size: 120,
    }),
    columnHelper.display({
      id: "information",
      header: "Information",
      cell: (cellProps) => (
        <>
          {cellProps.row.original.type === "REGISTRATION" && (
            <div className="xgs-pending-requests__table__info-column">
              <div className="xgs-pending-requests__table__info-column__row">
                <div className="xgs-pending-requests__table__info-column__name">Name:</div><div className="xgs-pending-requests__table__info-column__value">{cellProps.row.original.registrationRequest.firstName} {cellProps.row.original.registrationRequest.lastName}</div>
              </div>
              <div className="xgs-pending-requests__table__info-column__row">
                <div className="xgs-pending-requests__table__info-column__name">Email:</div><div className="xgs-pending-requests__table__info-column__value">{cellProps.row.original.registrationRequest.email}</div>
              </div>
              <div className="xgs-pending-requests__table__info-column__row">
                <div className="xgs-pending-requests__table__info-column__name">Company:</div><div className="xgs-pending-requests__table__info-column__value">{cellProps.row.original.registrationRequest.companyName}</div>
              </div>
            </div>
          )}
          {cellProps.row.original.type === "SHIPPER" && (
            <div className="xgs-pending-requests__table__info-column">
              <div className="xgs-pending-requests__table__info-column__row">
                <div className="xgs-pending-requests__table__info-column__name">Customer:</div><div className="xgs-pending-requests__table__info-column__value">{cellProps.row.original.shipperRequest.team.name}</div>
              </div>
              <div className="xgs-pending-requests__table__info-column__row">
                <div className="xgs-pending-requests__table__info-column__name">Account:</div><div className="xgs-pending-requests__table__info-column__value">{cellProps.row.original.shipperRequest.name}</div>
              </div>
            </div>
          )}
        </>
      ),
      size: 380,
    })
  ];

  const onListInfiniteScroll = () => {
    !requestsState.pendingRequestsFetchedAll && dispatch(getPendingRequests(requestsState.pendingRequestsScrollRequest?.lastIds));
  };

  const onRequestClick = (row: any) => {
    setRequestId(row.id)
  };

  useEffect(() => {
    dispatch(resetErrors());
    dispatch(getPendingRequests());
    setRequestId(undefined)
  }, [dispatch]);

  return (
    <ContentContainer pageId={PAGE_IDS.PENDING_REQUESTS} title="Pending Requests">    
      <div className="xgs-pending-requests">
        {requestsState.fetch_was_failed && (
          <XGSErrorMessage>{requestsState.fetch_fail_reason}</XGSErrorMessage>
        )}
        {!requestsState.fetch_was_failed && (
          <div className="xgs-pending-requests__table-wrapper">
            <div className="xgs-pending-requests__table">
              <Table
                autoHeight={true}
                isLoading={requestsState.fetch_was_started}
                columns={columns}
                data={requestsState.pendingRequests}
                onRowClicked={onRequestClick}
                cursorPointer={true}
                rowHeight={66}
                minTableHeight={420}
                noResultsText="There are no pending requests"
                infiniteScroll={true}
                infiniteScrollLoading={requestsState.fetchPortion_was_started}
                infiniteScrollHasNext={!requestsState.pendingRequestsFetchedAll}
                onInfiniteScroll={onListInfiniteScroll}
              />
              <RequestDetails
                id={requestId}
                show={!!requestId}
                onClose={() => {
                  setRequestId(undefined);
                }}
                onUpdate={() => {
                  dispatch(getPendingRequests());
                  setRequestId(undefined);
                  dispatch(getPortalState());
                }}
              />
            </div>
          </div>
        )}
      </div>
    </ContentContainer>
  );
};

export default PendingRequests;
