import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getPicklistsLoadingStatus, picklistsLoadingStatusSelector } from "../../../slices/dispatch-planning/picklists/picklistsLoadingStatusSlice";
import { PicklistsLoadingStatusState } from "../../../slices/dispatch-planning/picklists/picklistsState";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import ContentContainer from "../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import Table from "../../../ui-components/table/table";
import ReloadButton from "../../../ui-components/reload-button/reloadButton";
import { PicklistLoadingStatus } from "../../../app/data/picklists/models";
import PicklistDetails from "./picklist-details/picklistDetails";
import { PAGE_IDS } from "../../../app/data/common/pageIds";
import "./picklists.scss";
import { PicklistsSummary } from "./picklists-summary/picklistsSummary";
import getPicklistsColumns from "./picklistsColumns";


const Picklists: React.FC = () => {
    const dispatch = useDispatch();
    const userState: UserState = useSelector(userSelector);
    const [terminal, setTerminal] = useState<number | null | undefined>(0);
    const [selectedPicklist, setSelectedPicklist] = useState<PicklistLoadingStatus | null>(null);
    const picklistsLoadingStatusState: PicklistsLoadingStatusState = useSelector(picklistsLoadingStatusSelector);

    useEffect(() => {
        const userTerminal = userState.activeTerminal?.id;
        if (userTerminal) {
            setTerminal(userTerminal);
            setSelectedPicklist(null);
            refreshPicklists(userTerminal);
        }
        // eslint-disable-next-line
    }, [userState.activeTerminal]);

    const getPicklists = (terminalNumber: number) => {
        dispatch(getPicklistsLoadingStatus(
            terminalNumber,
            (error) => { toast.error(error) }
        ));
    }
    const refreshPicklists = (userTerminal: number | null | undefined) => {
        if (!userTerminal) return;
        setSelectedPicklist(null);
        getPicklists(userTerminal)
    };

    return (
        <ContentContainer
            className="xgs-list xgs-list--full-width"
            mods={{ 'full-width': true }}
            titleComponent={
                <ContentContainerToolbar
                    pageId={PAGE_IDS.PICKLISTS}
                    title="Picklists"
                    mods={{ "full-width": true }}
                >
                    <ReloadButton
                        title="Refresh Picklists"
                        spinner={picklistsLoadingStatusState.loading}
                        disabled={picklistsLoadingStatusState.loading}
                        onClick={() => refreshPicklists(terminal)}
                    />
                </ContentContainerToolbar>
            }
        >
            <div className="xgs-picklists">
                <div className="xgs-list__summary">
                    <PicklistsSummary
                        picklistSummary={picklistsLoadingStatusState.summary}
                        isLoading={picklistsLoadingStatusState.loading}
                    />
                </div>
                {(!terminal) ? (
                    <XGSErrorMessage>
                        <div>
                            You do not have assigned terminals.
                            Email <a className="white-link" href="mailto:helpdesk@xgsi.com">helpdesk@xgsi.com</a> to request terminal assignment for your account.
                        </div>
                    </XGSErrorMessage>
                ) : (
                    <div className="xgs-list__table-wrapper">
                        <Table
                            isLoading={picklistsLoadingStatusState.loading}
                            columns={getPicklistsColumns()}
                            data={picklistsLoadingStatusState.picklists}
                            cursorPointer={true}
                            rowHeight={64}
                            noResultsText="No Picklist available"
                            onRowClicked={(row: PicklistLoadingStatus) => {
                                setSelectedPicklist(row)
                            }}
                            responsive={true}
                        />
                    </div>
                )}
                {(selectedPicklist && selectedPicklist.picklistNumber) && (
                    <PicklistDetails
                        picklist={selectedPicklist}
                        show={!!selectedPicklist?.picklistNumber}
                        onClose={() => setSelectedPicklist(null)}
                    />
                )}
            </div>
        </ContentContainer>
    )
};

export default Picklists;