import { ProbillPreviewModel } from "../../app/data/probill/models";

export default interface ProbillPreviewState {
  requestStarted: boolean;  
  requestCreator: string;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError: string;  
  probillPreview: ProbillPreviewModel | null;
};

export const initialProbillPreviewState: ProbillPreviewState = {
  requestStarted: false,  
  requestCreator: "",
  requestSucceed: false,
  requestFailed: false,
  requestError: "",
  probillPreview: null,
};