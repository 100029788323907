import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createColumnHelper } from "@tanstack/react-table";
import SubAccount from "../../../app/data/account/SubAccount";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import AccountDetails from "../../company/accounts/accountDetails";
import Table from "../../../ui-components/table/table";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "../../../app/route/RoutesConfig";
import { CompanySettingsTabRoutes, CompanySettingsPath, PathParams } from "../route";
import { UserUtils } from "../../../app/data/user/userUtils";
import Tag from "../../../ui-components/molecules/tag/tag";
import { TagColor } from "../../../app/data/common/tagColor";
import {ReactComponent as LinkIcon} from "../../../images/new_window.svg";

const columnHelper = createColumnHelper<any>();

const CompanySettingsAccounts: React.FC<{}> = () => {
  const userState: UserState = useSelector(userSelector);
  const [accountId, setAccountId] = useState<string | undefined>();
  const history = useHistory();
  const params = useParams() as PathParams;

  const columns = [
    columnHelper.accessor("accountNumber", {
      id: "accountNumber",
      header: () => (
        <div className="text-center">
          Account #
        </div>
      ),
      cell: (cellProps) => (
        <div className="text-center">
          {cellProps.getValue()}
        </div>
      ),
      size: 120,
    }),
    columnHelper.accessor("name", {
      id: "name",
      header: "Account name",
      size: 240
    }),
    columnHelper.accessor("address.state", {
      id: "address.state",
      header: () => (
        <div className="text-center">
          State
        </div>
      ),
      cell: (cellProps) => (
        <div className="text-center">
          {cellProps.getValue()}
        </div>
      ),
      size: 60,
    }),
    columnHelper.accessor("payor", {
      id: "payor",
      header: () => (
        <div className="text-center">
          Payor
        </div>
      ),
      cell: (cellProps) => (
        <div className="text-center">
          {cellProps.getValue() ? "Yes" : "No"}
        </div>
      ),
      size: 90,
    }),
    columnHelper.display({
      id: "creditHoldColumn",
      header: "Credit Hold",
      cell: ({ row }: any) => (
        <div className="text-center">
          {!userState.isOnCreditHold && (
            <>
              No
            </>
          )}
          {userState.isOnCreditHold && (
            <>
              {userState.profile?.accountsOnCreditHold.find(accountNumber => accountNumber === row.getValue("accountNumber")) ? "Yes" : "No"}
            </>
          )}

        </div>
      ),
      size: 90,
    }),
    columnHelper.accessor("isAvailabilityHoursFilled", {
      id: "isAvailabilityHoursFilled",
      header: "Availability time",
      cell: (cellProps) => (
        <div onClick={(e) => {
          e.stopPropagation();
          history.push(`${Routes.company.general}${CompanySettingsTabRoutes.accounts}/${cellProps.row.original.accountNumber}/receiving-hours`)
        }}>
          {cellProps.getValue() === "AVAILABLE" ? (
            <Tag mods={{ color: TagColor.GREEN }}>
              AVAILABLE&nbsp;<LinkIcon />
            </Tag>
          ) : cellProps.getValue() === "MISSING" ? (
            <Tag mods={{ color: TagColor.GREY }}>
              MISSING&nbsp;<LinkIcon />
            </Tag>
          ) : cellProps.getValue() === "PARTIAL" ? (
            <Tag mods={{ color: TagColor.GREY }}>
              PARTIAL&nbsp;<LinkIcon />
            </Tag>
          ) : (
            <Tag mods={{ color: TagColor.GREY }}>
              N/A&nbsp;<LinkIcon />
            </Tag>
          )
          }
        </div>
      ),
      size: 120,
    }),
    columnHelper.accessor("isContactsFilled", {
      id: "isContactsFilled",
      header: "Contacts",
      cell: (cellProps) => (
        <div onClick={(e) => {
          e.stopPropagation();
          history.push(`${Routes.company.general}${CompanySettingsTabRoutes.accounts}/${cellProps.row.original.accountNumber}/contacts`)
        }}>
          {cellProps.getValue() === "AVAILABLE" ? (
            <Tag mods={{ color: TagColor.GREEN }}>
              AVAILABLE&nbsp;<LinkIcon />
            </Tag>
          ) : cellProps.getValue() === "MISSING" ? (
            <Tag mods={{ color: TagColor.GREY }}>
              MISSING&nbsp;<LinkIcon />
            </Tag>
          ) : cellProps.getValue() === "PARTIAL" ? (
            <Tag mods={{ color: TagColor.GREY }}>
              PARTIAL&nbsp;<LinkIcon />
            </Tag>
          ) : (
            <Tag mods={{ color: TagColor.GREY }}>
              N/A&nbsp;<LinkIcon />
            </Tag>
          )
          }
        </div>
      ),
      size: 120,
    }),
  ];

  const onAccountClick = (row: SubAccount) => {
    setAccountId(row.id);
    UserUtils.isCustomer(userState.profile) && history.replace(`${Routes.company.general}${CompanySettingsTabRoutes.accounts}/${row.accountNumber}`)
  };

  useEffect(() => {
    if (params[CompanySettingsPath.selectedItem]) {
      let allAccounts = userState.profile?.subAccounts!;
      let selectedAccount = allAccounts.find(acc => String(acc.accountNumber) === params[CompanySettingsPath.selectedItem]);
      selectedAccount && setAccountId(selectedAccount.id);
    }
  }, [params, userState.profile])

  return (
    <>
      <Table
        isLoading={false}
        columns={columns}
        data={userState.profile?.subAccounts}
        onRowClicked={onAccountClick}
        cursorPointer={true}
        rowHeight={66}
        minTableHeight={420}
        noResultsText="There are no accounts"
      />
      <AccountDetails
        id={accountId}
        show={!!accountId}
        onClose={() => {
          setAccountId(undefined);
        }} />
    </>
  );
};

export default CompanySettingsAccounts;
