import axios from "axios";
import api, { ApiResponse } from "./../api";
import { ProbillPreviewModel } from "./models";

export const REQUEST_ID: {
  [key: string]: string;
} = {
  GET_PROBILL_PREVIEW: "get_probill_preview",
};

class ProbillService {
  private source: any;
  private static instance: ProbillService;
  private constructor() {
    this.source = {};
  }
  static getInstance(): ProbillService {
    if (!ProbillService.instance) {
      ProbillService.instance = new ProbillService();
    }
    return ProbillService.instance;
  };

  public cancelRequest = (requestId: string) => {
    this.source[requestId] && this.source[requestId].cancel();
  }

  public getProbillPreview = async (
    probillNumber: number,
  ): Promise<ApiResponse<ProbillPreviewModel>> => {
    
    this.cancelRequest(REQUEST_ID.GET_PROBILL_PREVIEW);
    this.source[REQUEST_ID.GET_PROBILL_PREVIEW] = axios.CancelToken.source();
    return await api.get(`/probills/details/${probillNumber}`, { cancelToken: this.source[REQUEST_ID.GET_PROBILL_PREVIEW].token });
  };
};

export default ProbillService;
