import React from "react";
import { ReactComponent as Info } from "../../images/info.svg";
import "./stageBanner.scss";

const StageBanner: React.FC<{}> = (props) => {
  return process.env.REACT_APP_XGS_ENV !== "production"
  ? (
    <div className="xgs-stage-banner">
      <div className="xgs-stage-banner__icon">
        <Info color="white" />
      </div>
      <div>
        <div className="xgs-stage-banner__header">This is not a production environment!</div>
        <div className="xgs-stage-banner__text">Data may not match actual live environment. Features may not work as expected.</div>
      </div>
    </div>
  )
  : null;
};

export default StageBanner;