import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "../../../ui-components/table/table";
import {
  shipmentDetailsSelector,
  getDockLog
} from "../../../slices/shipment-details/shipmentDetailsSlice";
import ShipmentDetailsState from "../../../slices/shipment-details/ShipmentDetailsState";
import Loading from "../../../ui-components/loading/loading";

export interface ShipmentDockLogProps {
  invoiceNumber: number;
}

const columnHelper = createColumnHelper<any>();

const ShipmentDockLog: React.FC<ShipmentDockLogProps> = (props) => {
  const dispatch = useDispatch();
  const shipmentDetailsState: ShipmentDetailsState = useSelector(shipmentDetailsSelector);

  const columns = [
    columnHelper.accessor("date", {
      id: "date",
      header: () => (
        <div className="text-center">
          Date
        </div>
      ),
      cell: (cellProps) => (
        <div className="text-center">
          { cellProps.getValue().toUiDateFormat() }
        </div>
      ),
      size: 95,
    }),
    columnHelper.accessor("time", {
      id: "time",
      header: () => (
        <div className="text-center">
          Time
        </div>
      ),
      cell: (cellProps) => (
        <div className="text-center">
          { cellProps.getValue() }
        </div>
      ),
      size: 75,
    }),
    columnHelper.accessor("event", {
      id: "event",
      header: () => (
        <div className="text-center">
          Event
        </div>
      ),
      cell: (cellProps) => (
        <div className="text-center">
          { cellProps.getValue() }
        </div>
      ),
      size: 60,
    }),
    columnHelper.accessor("serviceCenter", {
      id: "serviceCenter",
      header: () => (
        <div className="text-center">
          Term #
        </div>
      ),
      cell: (cellProps) => (
        <div className="text-center">
          { cellProps.getValue().id } { cellProps.getValue().name }
        </div>
      ),
      size: 120,
    }),
    columnHelper.accessor("door", {
      id: "door",
      header: () => (
        <div className="text-center">
          Door
        </div>
      ),
      cell: (cellProps) => (
        <div className="text-center">
          { cellProps.getValue() }
        </div>
      ),
      size: 60,
    }),
    columnHelper.accessor("orderOfItem", {
      id: "orderOfItem",
      header: () => (
        <div className="text-center">
          Seq
        </div>
      ),
      cell: (cellProps) => (
        <div className="text-center">
          { cellProps.getValue() }
        </div>
      ),
      size: 60,
    }),
    columnHelper.accessor("rollNumber", {
      id: "rollNumber",
      header: "Roll #",
      size: 100
    }),
    columnHelper.accessor("trailer", {
      id: "trailer",
      header: () => (
        <div className="text-center">
          Trailer
        </div>
      ),
      cell: (cellProps) => (
        <div className="text-center">
          { cellProps.getValue() || "" }
        </div>
      ),
      size: 90,
    }),
    columnHelper.accessor("message", {
      id: "message",
      header: "",
      size: 180
    })
  ];

  const calcMinTableHeight = () => {
    if (!shipmentDetailsState.dockLog) return;
    if (shipmentDetailsState.dockLog.length === 2) return 150;    
    return (shipmentDetailsState.dockLog.length > 2) ? 254 : 100;
  };

  useEffect(() => {
    if (!props.invoiceNumber || shipmentDetailsState.dockLog) return;
    dispatch(getDockLog(props.invoiceNumber));
  }, [dispatch, props.invoiceNumber, shipmentDetailsState.dockLog]);

  return (
    <>
      {shipmentDetailsState.dockLogLoading && (
        <div className="xgs-invoice-documents__loading">
          <Loading isLoading={true} />
        </div>
      )}
      {shipmentDetailsState.dockLog && shipmentDetailsState.dockLog.length > 0 && (
        <div className="xgs-shipment-details__dock-log">
          <Table
            columns={columns}
            data={shipmentDetailsState.dockLog}
            infiniteScroll={true}
            infiniteScrollLoading={false}
            infiniteScrollHasNext={false}
            onInfiniteScroll={() => {}}
            rowHeight={75}
            minTableHeight={calcMinTableHeight()}
            strictMinTableHeight={true}
          />
        </div>
      )}
      {(!shipmentDetailsState.dockLog || shipmentDetailsState.dockLog.length === 0) && shipmentDetailsState.dockLogLoaded && (
        <div className="xgs-shipment-details__tabs__no-data">There is no dock log</div>
      )}
      {shipmentDetailsState.dockLogLoadingFailed && (
        <div className="xgs-shipment-details__tabs__error">
          {shipmentDetailsState.dockLogLoadingError || "Error while loading dock log."}
        </div>
      )}
    </>
  );
};

export default ShipmentDockLog;
