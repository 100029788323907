import React from "react";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<any>();

export const getLaneManagementColumns = () => [
  columnHelper.accessor("laneName", {
    id: "laneName",
    header: "Lane name",
    size: 170,
  }),
  columnHelper.accessor("origin", {
    id: "origin",
    header: "Origin",
    cell: (cellProps) => (
      <div className="xgs-lmi__trips__address-cell">
        {cellProps.row.original.originTerminalAirportcode} - {cellProps.row.original.originTerminalName} <span>{cellProps.row.original.originTerminalNumber}</span>
      </div>
    ),
    size: 140,
  }),
  columnHelper.accessor("destination", {
    id: "destination",
    header: "Destination",
    cell: (cellProps) => (
      <div className="xgs-lmi__trips__address-cell">
        {cellProps.row.original.destinationTerminalAirportcode} - {cellProps.row.original.destinationTerminalName} <span>{cellProps.row.original.destinationTerminalNumber}</span>
      </div>
    ),
    size: 140,
  }),
  columnHelper.accessor("relayCity", {
    id: "relayCity",
    header: "Relay",
    cell: (cellProps) => (
      <>
        {
          [cellProps.row.original.relayCity, cellProps.row.original.relayState, cellProps.row.original.relayZipcode]
            .filter(val => !!val)
            .join(", ")
        }
      </>
    ),
    size: 260,
  }),
  columnHelper.accessor("miles", {
    id: "miles",
    header: "Miles",
    size: 105,
  }),
  columnHelper.accessor("laneNotes", {
    id: "laneNotes",
    header: "Notes",
    size: 253,
  })
]