import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentContainer from "../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import Table from "../../../ui-components/table/table";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import { ServiceDisruptionProbillsState } from "../../../slices/dispatch-planning/service-disruption/serviceDisruptionState";
import { getServiceDisruptionProbills, serviceDisruptionProbillsSelector } from "../../../slices/dispatch-planning/service-disruption/serviceDisruptionProbillsSlice";
import getServiceDisruptionColumns from "./serviceDisruptionColumns";
import { ServiceDisruptionProbill } from "../../../app/data/service-disruption/models";
import { resetServiceDisruptionSelectedProbills, setServiceDisruptionSelectedProbills } from "../../../slices/dispatch-planning/service-disruption/serviceDisruptionSelectedProbillsSlice";
import CreateLateReasons from "./create-late-reasons/createLateReasons";
import { PAGE_IDS } from "../../../app/data/common/pageIds";
import "./serviceDisruption.scss";

const ServiceDisruption: React.FC<{}> = memo(() => {
  const dispatch = useDispatch();

  const serviceDisruptionState: ServiceDisruptionProbillsState = useSelector(
    serviceDisruptionProbillsSelector
  );
  const userState: UserState = useSelector(
    userSelector
  );

  useEffect(() => {
    const userTerminal = userState.activeTerminal?.id;
    onTerminalChanged(userTerminal);
    // eslint-disable-next-line
  }, [userState.activeTerminal])

  const onTerminalChanged = (userTerminal: number | null | undefined) => {
    dispatch(resetServiceDisruptionSelectedProbills());
    if (!userTerminal) return; // can not get the Probills without knowing the terminal
    dispatch(getServiceDisruptionProbills(userTerminal));
  };

  const selectedProbillsChanged = React.useCallback(
    (state: {
      selectedRowsData: ServiceDisruptionProbill[];
    }) => {
      const selectedProbill: ServiceDisruptionProbill[] = state.selectedRowsData.map(
        (selectedRow) => selectedRow
      );
      dispatch(setServiceDisruptionSelectedProbills(selectedProbill));
    },
    [dispatch]
  );

  return (
    <ContentContainer
    className="xgs-list xgs-list--full-width"
      mods={{ "full-width": true }}
      titleComponent={
        <ContentContainerToolbar
          pageId={PAGE_IDS.SERVICE_DISRUPTION}
          title="Service Disruption Management"
          mods={{ "full-width": true }}
        >
        </ContentContainerToolbar>
      }
    >
       <CreateLateReasons />
      {(!userState.activeTerminal?.id) ?
        <XGSErrorMessage>
          <div>
            You do not have assigned terminals.
            Email <a className="white-link" href="mailto:helpdesk@xgsi.com">helpdesk@xgsi.com</a> to request terminal assignment for your account.
          </div>
        </XGSErrorMessage>
        :
        <div className="xgs-service-disruption__table-wrapper">
          <Table
            isLoading={serviceDisruptionState.loading}
            columns={getServiceDisruptionColumns()}
            data={serviceDisruptionState.probills}
            ignoreCellClick="selection"
            cursorPointer={true}
            enableRowSelection={true}
            onSelectedRowsChange={selectedProbillsChanged}
            infiniteScroll={false}
            responsive={true}
            sorting={true}
            enableMultiSort={true}
            filters={true}
            autoResetSelectedRows={true}
          />
        </div>
      }
    </ContentContainer>
  );
});

export default ServiceDisruption;
