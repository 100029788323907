import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import binIcon from "../../images/bin_gray.svg";

const columnHelper = createColumnHelper<any>();

export const getAccessorialColumns = (onDelete: any, requestStarted: boolean) => [
  columnHelper.accessor("type", {
    id: "type",
    header: "Type",
    size: 165,
    cell: (cellProps) => (
      <>{cellProps.getValue().toLowerCase().capitalize()}</>
    )
  }),
  columnHelper.accessor("duration", {
    id: "duration",
    header: "Duration/Amount",
    size: 160,
    cell: (cellProps) => (
      <>
        {!!cellProps.getValue() ?
          (cellProps.getValue() + "  hrs") :
          ("$ " + cellProps.row.original.amount)}
      </>
    )
  }),
  columnHelper.accessor("reason", {
    id: "reason",
    header: "Reason",
    size: 175,
  }),
  columnHelper.accessor("notes", {
    id: "notes",
    header: "Notes",
    size: 200
  }),
  columnHelper.display({
    id: "delete",
    header: "",    
    cell: (cellProps) => {
      return (
        <>
          <img onClick={() => onDelete(cellProps.row.original.tripId, cellProps.row.original.exceptionId)} style={requestStarted ? {} : { cursor: "pointer" }} src={binIcon} alt="bin" />
        </>
      )
    },
    size: 30,
  })
]