import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormikProps } from "formik";
import ContentContainer from "../../../templates/content-container/contentContainer";
import XGSToggle from "../../../ui-components/xgs-toggle/xgs-toggle";
import PickupMap from "./pickupMap";
import DriversList from "./driversList";
import PickupList from "./pickupList";
import PickupListFilters from "./pickupListFilters";
import PickupDetails from "../../common/pickup-details/pickupDetails";
import Collapsible from "../../../ui-components/collapsible/collapsible";
import StopsLegend from "../../../ui-components/map/stops-legend/stopsLegend";
import Legend from "../../../ui-components/molecules/legend/legend";
import { PickupStatus } from "../../../app/data/common/route";
import { userSelector } from "../../../slices/user/userSlice";
import { getDriverById, pickupAssignmentSelector, updatePickup } from "../../../slices/pickup/pickupAssignmentSlice";
import { getPickupById, getPickupDetails, isGroup } from "../../../services/pickups";
import useWindowSize from "../../../hooks/useWindowsSize";
import { getHeightBeforeEndOfPage } from "../../../hooks/utils";
import { UpdatePickupModel } from "../../../app/data/pickup/models";
import { PAGE_IDS } from "../../../app/data/common/pageIds";

import "./pickupManagement.scss";

const mobileWidth = 768;

const PickupAssignment: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const userState = useSelector(userSelector);
  const pickupAssignmentState = useSelector(pickupAssignmentSelector);
  const [selectedPickupIds, setSelectedPickupIds] = useState<string[]>([]);
  const [selectedPickupGroupId, setSelectedPickupGroupId] = useState("");
  const [selectedDriverId, setSelectedDriverId] = useState<number | null>(null);
  const [scrollToDriverId, setScrollToDriverId] = useState<number | null>(null);
  const [statuses, setStatuses] = useState([PickupStatus.UNASSIGNED, PickupStatus.ASSIGNED]);
  const [expiredOnly, setExpiredOnly] = useState(false);
  const [pickupSearch, setPickupSearch] = useState("");
  const [pickupDetailsId, setPickupDetailsId] = useState<string>("");
  const [showAllRoutes, setShowAllRoutes] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [dispatchToolsOpen, setDispatchToolsOpen] = useState(true);
  const [pickupsListHeight, setPickupsListHeight] = useState(0);
  const [driverName, setDriverName] = useState("");
  const [forceResetStatuses, setForceResetStatuses] = useState(false);
  const [windowWidth, windowHeight] = useWindowSize();
  const filterFormRef = useRef<FormikProps<any>>(null);
  

  const clearSelectedPickup = () => {
    setSelectedPickupIds([]);
    setPickupDetailsId("");
    setSelectedDriverId(null);
    setScrollToDriverId(null);
  };

  const changeRoutesMode = (showAll: boolean) => {
    if (showAll) {
      setSelectedDriverId(null);
    }
    setShowAllRoutes(showAll);
  }

  const selectDriver = (id: number | null) => {
    if (id) {
      setShowAllRoutes(false);
    }
    setSelectedDriverId(id);
  }

  const showPickupDetails = (pickupId: string) => {
    let pickupObj = getPickupById(pickupAssignmentState.pickups, pickupId);
    pickupObj?.pickup.driverId && dispatch(getDriverById(pickupObj.pickup.driverId, (driverName) => setDriverName(driverName), true));
    setPickupDetailsId(pickupId);
  }

  const onUpdatePickup = (pickup: UpdatePickupModel) => {
    dispatch(updatePickup(pickup));
  }

  const pickupDetails = () => {
    let pickupObj = getPickupById(pickupAssignmentState.pickups, pickupDetailsId);
    const pickupDetails = getPickupDetails(pickupObj);
    return pickupDetails ? {...pickupDetails, driverName } : null;
  }

  const showPickup = (pickupNumber: string) => {
    setForceResetStatuses(true);
    filterFormRef.current?.setFieldValue("pickupSearch", pickupNumber);
    filterFormRef.current?.submitForm();    
  }

  useEffect(() => {
    clearSelectedPickup();
  }, [userState.activeTerminal]);

  useEffect(() => {
    if (!pickupAssignmentState.createdGroupId) return;
    const isGroupShown = pickupAssignmentState.pickups.findIndex(obj => (isGroup(obj) && obj.id === pickupAssignmentState.createdGroupId)) >= 0;
    setSelectedPickupGroupId(isGroupShown ? pickupAssignmentState.createdGroupId : "");
  }, [pickupAssignmentState.createdGroupId, pickupAssignmentState.pickups]);

  useEffect(() => {
    const fixedMobileHeight = 300;
    const height = getHeightBeforeEndOfPage(".xgs-pickup-assignment__orders",
      240,
      windowHeight,
      0);
    setPickupsListHeight(windowWidth > mobileWidth ? height : fixedMobileHeight);
  }, [windowWidth, windowHeight, dispatchToolsOpen]);

  return (
    <ContentContainer pageId={PAGE_IDS.PICKUP_ASSIGNMENT} title="Pickup Assignment" mods={{ 'full-width': true }}>
      <div className="xgs-pickup-assignment">
        <div className="xgs-pickup-assignment__row">
          <Collapsible 
          open={dispatchToolsOpen}
          onClick={() => setDispatchToolsOpen(!dispatchToolsOpen)}
          displayLabel={true}
          mods={{ "full-size": true }} 
          header={(
            <div className="xgs-pickup-assignment__collapsible-block-header">
              <div className="xgs-pickup-assignment__block-title">Dispatch Tools</div>
              {dispatchToolsOpen && (<div className="xgs-pickup-assignment__collapsible-block-header-controls">
                <div className="xgs-pickup-assignment__map-legend">
                  <Legend position={windowWidth <= mobileWidth ? "left" : "right"}>
                    <StopsLegend />
                  </Legend>
                  <div className="xgs-pickup-assignment__map-legend-title">Map Legend</div>
                </div>
                <XGSToggle
                  onChange={changeRoutesMode}
                  checked={showAllRoutes}
                  label="Show all routes"
                />
              </div>)}
            </div>
          )}>
            <div className="xgs-pickup-assignment__dispatch-tools">
              <div className="xgs-pickup-assignment__map">
                <PickupMap
                  pickupIds={selectedPickupIds}
                  driverId={selectedDriverId}
                  showAllRoutes={showAllRoutes}
                  onDriverSelect={(driverId) => {
                    selectDriver(driverId);
                    setScrollToDriverId(driverId);
                  }}
                />
              </div>
              <div className="xgs-pickup-assignment__drivers">
                <DriversList
                  pickupIds={selectedPickupIds}
                  groupId={selectedPickupGroupId}
                  selectedDriverId={selectedDriverId}
                  scrollToDriverId={scrollToDriverId}
                  onDriverSelect={(driverId) => selectDriver(driverId)}
                  onClickReschedule={() => {
                    showPickupDetails(selectedPickupIds[0]);
                    setShowReschedule(true);
                  }}
                />
              </div>
            </div>
          </Collapsible>
        </div>
        <div className="xgs-pickup-assignment__pickups">
          <div className="xgs-pickup-assignment__block-header">
            <div className="xgs-pickup-assignment__block-title">Orders List</div>
            <PickupListFilters
              formRef={filterFormRef}
              onSetExpiredOnly={(v) => {
                setExpiredOnly(v);
                clearSelectedPickup();
              }}
              onSetStatuses={(v) => {
                setStatuses(v);
                clearSelectedPickup();
                setForceResetStatuses(false);
              }}
              onSetPickupSearch={(v) => {
                setPickupSearch(v);
                clearSelectedPickup();
              }}
              forceResetStatuses={forceResetStatuses}
            />
          </div>
          <PickupList
            expiredOnly={expiredOnly}
            selectedPickupIds={selectedPickupIds}
            selectedPickupGroupId={selectedPickupGroupId}
            statuses={statuses}
            terminal={userState.activeTerminal?.id || null}
            pickupSearch={pickupSearch}
            onPickupSelect={(ids) => {
              setSelectedPickupIds(ids);
              setSelectedDriverId(null);
              setPickupDetailsId("");
              setDispatchToolsOpen(true);
            }}
            onGroupSelect={(id) => {
              setSelectedPickupGroupId(id);
              setDispatchToolsOpen(true);
            }}
            onShowPickupDetails={(id) => showPickupDetails(id)}
            height={pickupsListHeight}
            collapsed={dispatchToolsOpen}
          />
        </div>
        <PickupDetails
          isOpen={!!pickupDetailsId}
          pickup={pickupDetails()}
          onClose={() => {
            setPickupDetailsId("");
            setShowReschedule(false);
          }}
          onUpdatePickup={onUpdatePickup}
          showReschedule={showReschedule}
          isDriverLoading={pickupAssignmentState.requestStarted && pickupAssignmentState.requestCreator === "GET_DRIVER"}
          onShowPickup={showPickup}
        />
      </div>
    </ContentContainer>
  );
};

export default PickupAssignment;
