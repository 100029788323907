import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface Contact {
  id?: string;
  firstName: string;
  lastName: string;
  type: ContactType;
  title: string | null;
  email: string | null;
  phone: string | null;
}

export enum ContactType {
  OSD = "OSD",
  RECEIVING = "RECEIVING",
  SHIPPING = "SHIPPING",
  PAYOR ="PAYOR"
}

export const contactTypes = [
  ContactType.RECEIVING,
  ContactType.SHIPPING,
  ContactType.OSD,
  ContactType.PAYOR,
]

export const AccountContactModel = Yup.object({
  firstName: Yup.string().matches(/\S+/, validationMessages.notValid).max(128, "Maximum 128 characters").required(validationMessages.required),
  lastName: Yup.string().matches(/\S+/, validationMessages.notValid).max(128, "Maximum 128 characters").required(validationMessages.required),
  title: Yup.string().nullable().matches(/\S+/, validationMessages.notValid).max(60, "Maximum 60 characters"),
  phone: Yup.string()
    .nullable()
    .matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, validationMessages.notValid)
    .test("emailOrPhone", "Either email or phone is required", function(value) {
      const {email, phone} = this.parent;
      const isEmailProvided = email && email.trim() !== "";
      const isPhoneProvided = phone && phone.trim() !== "";
      return isEmailProvided || isPhoneProvided;
    }),
  email: Yup.string()
    .nullable()
    .max(60, "The value must be no more than 60 characters")
    .email(validationMessages.emailNotValid)
    .matches(/^(?!.*@xgsi\.com$).*$/i, "XGS email not allowed")
    .test("emailOrPhone", "Either email or phone is required", function(value) {
      const {email, phone} = this.parent;
      const isEmailProvided = email && email.trim() !== "";
      const isPhoneProvided = phone && phone.trim() !== "";
      return isEmailProvided || isPhoneProvided;
      
    }),
  type: Yup.string().required(validationMessages.required)
});