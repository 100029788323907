import React, { useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import ContentContainer from "../../../templates/content-container/contentContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoiceDetails,
  invoiceDetailsSelector,
  resetInvoiceDetails
} from "../../../slices/invoice/invoiceSlice";
import { InvoiceDetailsState } from "../../../slices";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import InvoiceDetailsHeader from "./header/invoiceDetailsHeader";
import InvoiceDetailsInfo from "./info/invoiceDetailsInfo";
import InvoiceDetailsDocuments from "./documents/invoiceDetailsDocuments";
import InvoiceDetailsTable from "./table/invoiceDetailsTable";
import InvoicePayment from "../invoice-payment/invoicePayment";
import FitToWindowContainer from "../../../templates/fit-to-window-container/fitToWindowContainer";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import { PathParams, InvoiceDetailsPath } from "./route";
import "./invoiceDetails.scss";

const InvoiceDetails: React.FC = () => {
  const userState: UserState = useSelector(userSelector);
  const invoiceDetailsState: InvoiceDetailsState = useSelector(invoiceDetailsSelector);
  const params = useParams() as PathParams;
  const dispatch = useDispatch();

  const invoiceId = useMemo(() => {
    return parseInt(params[InvoiceDetailsPath.invoiceNumber], 10);
  }, [params]);

  useEffect(() => {
    if (
      (userState.activeSubAccount?.id && invoiceId) ||
      invoiceDetailsState.needToReload
    ) {
      dispatch(getInvoiceDetails(userState.activeSubAccount?.id, invoiceId));

      return () => {
        dispatch(resetInvoiceDetails());
      };
    }
  }, [
    dispatch,
    userState.activeSubAccount,
    invoiceId,
    invoiceDetailsState.needToReload,
  ]);

  return (
    <>
      <ContentContainer titleComponent={<InvoiceDetailsHeader />}>
        <FitToWindowContainer>
          {invoiceDetailsState.loaded && (
            <>
              {invoiceDetailsState.invoiceDetails && (
                <InvoiceDetailsInfo {...invoiceDetailsState.invoiceDetails} />
              )}
              {invoiceId && (<InvoiceDetailsDocuments invoiceNumber={invoiceId} />)}
              <InvoiceDetailsTable
                items={invoiceDetailsState.invoiceDetails?.items}
                invoiceAmount={
                  invoiceDetailsState.invoiceDetails?.invoice.invoiceAmount
                }
              />
            </>
          )}
          {invoiceDetailsState.loadingFailed && (
            <XGSErrorMessage>{invoiceDetailsState.loadingError || "Error! Something went wrong."}</XGSErrorMessage>
          )}
        </FitToWindowContainer>
      </ContentContainer>
      <InvoicePayment />
    </>
  );
};

export default InvoiceDetails;
