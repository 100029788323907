import React from "react";
import ContentContainer from "../../../templates/content-container/contentContainer";
import BolList from "../common/list";
import { PAGE_IDS } from "../../../app/data/common/pageIds";

const CombinedList: React.FC<{}> = (props) => {
  return (
    <ContentContainer pageId={PAGE_IDS.BOL} title="BOL">
      <BolList defaultTypes={["BOL"]} />
    </ContentContainer>
  );
};

export default CombinedList;
