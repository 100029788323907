import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentContainer from "../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import "./activeRoutes.scss";
import RouteStops from "./route-stops/routeStops";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import { ActiveRoutesState, CreatePicklistFromRouteState, RequestRouteOptimizationState, RouteStopsState, UpdateRouteState } from "../../../slices/dispatch-planning/routes/routesState";
import { activeRoutesSelector, getActiveRoutes } from "../../../slices/dispatch-planning/routes/activeRoutesSlice";
import RouteMap from "./route-map/routeMap";
import Loading from "../../../ui-components/loading/loading";
import { getRouteStops, resetRouteStopsState, routeStopsSelector } from "../../../slices/dispatch-planning/routes/routeStopsSlice";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import { updateRoute, updateRouteSelector } from "../../../slices/dispatch-planning/routes/updateRouteSlice";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { requestRouteOptimization, requestRouteOptimizationSelector } from "../../../slices/dispatch-planning/routes/optimizeRouteSlice";
import { convertRouteToPicklist, createPicklistFromRouteSelector } from "../../../slices/dispatch-planning/routes/createPicklistFromRouteSlice";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import EditRouteDetails from "./edit-route-details/editRouteDetails";
import { PAGE_IDS } from "../../../app/data/common/pageIds";

const ActiveRoutes: React.FC<{}> = memo((props) => {
    const dispatch = useDispatch();
    const [terminal, setTerminal] = useState<number | null | undefined>();
    const [selectedRouteId, setSelectedRouteId] = useState<string>();
    const [selectedRoute, setSelectedRoute] = useState<any>(null);
    const [isHideRoutsList, setIsHideRoutsList] = useState<boolean>(false);
    const [isShowRouteNotLoadable, setIsShowRouteNotLoadable] = useState<boolean>(false);
    const [isShowEditRoute, setIsShowEditRoute] = useState<boolean>(false);

    const activeRoutesState: ActiveRoutesState = useSelector(
        activeRoutesSelector
    );
    const routeStopsState: RouteStopsState = useSelector(
        routeStopsSelector
    );
    const routesStopsState: RouteStopsState = useSelector(
        routeStopsSelector
    );
    const updateRouteState: UpdateRouteState = useSelector(
        updateRouteSelector
    );
    const requestRouteOptimizationState: RequestRouteOptimizationState = useSelector(
        requestRouteOptimizationSelector
    );
    const createPicklistFromRouteState: CreatePicklistFromRouteState = useSelector(
        createPicklistFromRouteSelector
    );
    const userState: UserState = useSelector(
        userSelector
    );
    useEffect(() => {
        const userTerminal = userState.activeTerminal?.id;
        onTerminalChanged(userTerminal);
        // eslint-disable-next-line
    }, [userState.activeTerminal]);

    const getSelectedRouteDetails = (routeId: any) => {
        if (activeRoutesState.activeRoutes && activeRoutesState.activeRoutes.length) {
            let selectedRouteDetails = null;
            activeRoutesState.activeRoutes.forEach(routeObj => {
                if (routeObj.routeId === routeId) selectedRouteDetails = { ...routeObj };
            });
            return selectedRouteDetails;
        } else { return null; }
    }
    const checkIsRouteLoadable = (statusFlag: string) => {
        if (statusFlag === "QUEUED" || statusFlag === "PROCESSING") {
            // user has to wait if the route is getting optimized
            return false;
        } else {
            return true;
        }
    }
    useEffect(() => {
        if (!selectedRouteId) return;
        let selectedRouteDetails: any = getSelectedRouteDetails(selectedRouteId);
        if (!selectedRouteDetails) return;
        setSelectedRoute(selectedRouteDetails);
        if (checkIsRouteLoadable(selectedRouteDetails.statusFlag)) {
            setIsShowRouteNotLoadable(false);
        } else {
            setIsShowRouteNotLoadable(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeRoutesState, selectedRouteId])

    const onTerminalChanged = (userTerminal: number | null | undefined) => {
        setTerminal(userTerminal);
        if (!userTerminal) return; // can not get the Probills without knowing the terminal
        dispatch(getActiveRoutes({ terminalNumber: userTerminal }));
    };
    const getFormattedDate = (date: number) => {
        let dateStr = `${date}`;
        return `${dateStr.slice(4, 6)}/${dateStr.slice(6)}/${dateStr.slice(0, 4)}`
    }
    const getFormattedTime = (time: number) => {
        let timeStr = `000000${time}`.slice(-6);
        let hours = +timeStr.slice(0, 2);
        let minutes = +timeStr.slice(2, 4);
        let modifier = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        let minutesStr = minutes < 10 ? '0' + minutes : minutes;
        let strTime = hours + ':' + minutesStr + ' ' + modifier;
        return strTime;
    }
    const loadRouteStops = (routeId: any) => {
        setSelectedRouteId(routeId);
        setIsShowEditRoute(false);
        let selectedRouteDetails: any = getSelectedRouteDetails(routeId);
        if (selectedRouteDetails && checkIsRouteLoadable(selectedRouteDetails.statusFlag)) {
            setIsShowRouteNotLoadable(false);
            dispatch(getRouteStops({ routeId }));
        } else {
            setIsShowRouteNotLoadable(true);
            dispatch(resetRouteStopsState());

        }
    }
    const loadEditRoute = (routeId: any) => {
        setSelectedRouteId(routeId);
        dispatch(resetRouteStopsState());
        let selectedRouteDetails: any = getSelectedRouteDetails(routeId);
        if (!selectedRouteDetails) return;
        if (selectedRouteDetails && checkIsRouteLoadable(selectedRouteDetails.statusFlag)) {
            setIsShowRouteNotLoadable(false);
            setIsShowEditRoute(true);
        } else {
            setIsShowRouteNotLoadable(true);
            setIsShowEditRoute(false);
        }
    }
    const refreshRoutes = () => {
        if (!terminal) return;
        setSelectedRouteId("");
        // setIsShowEditRoute(false);
        setIsShowRouteNotLoadable(false);
        dispatch(getActiveRoutes({ terminalNumber: terminal }));
    }
    const saveRouteEdits = () => {
        if (!routesStopsState.isRouteEditied) return;
        dispatch(updateRoute(
            { editedRoute: [...routesStopsState.stopsForDispaly] },
            { routeId: selectedRouteId, terminalNumber: (terminal) ? terminal : 0 },
            () => { toast.info("Route Edits saved successfully!") },
            (error) => { toast.error(error) }
        ))
    }
    const pushRouteForOptimization = () => {
        dispatch(requestRouteOptimization(
            { routeId: selectedRouteId, terminalNumber: (terminal) ? terminal : 0 },
            () => { toast.info("Route is queued for optmization!") },
            (error) => { toast.error(error) }
        ));
    }
    const createPicklist = () => {
        let currentDistance = routeStopsState.currentDistance;
        let currentDuration = routeStopsState.currentDuration;
        dispatch(convertRouteToPicklist(
            { routeId: selectedRouteId, currentDistance, currentDuration },
            { terminalNumber: (terminal) ? terminal : 0 },
            () => { toast.info(`Picklist is created for the Route ID- ${selectedRouteId}`) },
            (error) => { toast.error(error) }
        ));
    }
    const getRouteStateClass = (statusFlag: string) => {
        if (statusFlag === "OPTIMIZED") {
            return "route-status-done"
        } else if (statusFlag === "EDITED") {
            return "route-status-edited"
        } else if (statusFlag === "SUBMITTED" || statusFlag === "QUEUED") {
            return "route-status-submitted"
        } else if (statusFlag === "PROCESSING") {
            return "route-status-processing"
        } else {
            return "route-status-error"
        }
    }

    const getFormatedRouteDistance = (rawMeters: number) => {
        if (!rawMeters) return "0";
        let rawMiles = Math.floor(rawMeters * 0.000621371192);
        return `${rawMiles.toLocaleString('en', { useGrouping: true })} miles`;
    }

    const getFormatedRouteDurration = (rawSeconds: number) => {
        if (!rawSeconds) return "0";
        return `${Math.floor(rawSeconds / 3600)} hr ${Math.floor(rawSeconds % 3600 / 60)} min`;
    }

    const getRouteErros = () => {
        if (!selectedRoute || !selectedRoute.routeError) return;
        return <div className="">Error: {selectedRoute.routeError}</div>
    }

    const getRouteCost = () => {
        if (!selectedRoute) return;
        let optimizedDistance = getFormatedRouteDistance(selectedRoute.optimizedDistance || 0);
        let optimizedDuration = getFormatedRouteDurration(selectedRoute.optimizedDuration || 0);
        let currentDistance = '0';
        let currentDuration = '0';
        if (selectedRoute.statusFlag === "OPTIMIZED" && !routesStopsState.isRouteEditied) {
            currentDistance = optimizedDistance;
            currentDuration = optimizedDuration;
        } else {
            currentDistance = getFormatedRouteDistance(routeStopsState.currentDistance);
            currentDuration = getFormatedRouteDurration(routeStopsState.currentDuration);
        }
        return <div className="active-route-info-box__content">
            <div className="active-route-info-box__content__row">
                <div className="active-route-info-box__content__row__column">
                </div>
                <div className="active-route-info-box__content__row__column">
                    Distance
                </div>
                <div className="active-route-info-box__content__row__column">
                    Duration
                </div>
            </div>
            {(optimizedDistance && optimizedDistance !== '0') ?
                <div className="active-route-info-box__content__row">
                    <div className="active-route-info-box__content__row__column">
                        <span className="active-route-info-box__content__row__column__title">Optimized</span>
                    </div>
                    <div className="active-route-info-box__content__row__column">
                        {optimizedDistance}
                    </div>
                    <div className="active-route-info-box__content__row__column">
                        {optimizedDuration}
                    </div>
                </div>
                : ""}
            {(currentDistance && currentDistance !== '0') ?
                <div className="active-route-info-box__content__row">
                    <div className="active-route-info-box__content__row__column">
                        <span className="active-route-info-box__content__row__column__title">Current</span>
                    </div>
                    <div className="active-route-info-box__content__row__column">
                        {currentDistance}
                    </div>
                    <div className="active-route-info-box__content__row__column">
                        {currentDuration}
                    </div>
                </div>
                : ""}
        </div>
    }
    return (
        <ContentContainer
            titleComponent={
                <ContentContainerToolbar
                    pageId={PAGE_IDS.ACTIVE_ROUTES}
                    title="Routes"
                >
                </ContentContainerToolbar>
            }
        >
            <div
                className="active-routes-header"
            >
                <Button
                    theme={ButtonThemes.blue}
                    className="active-routes-refresh-button"
                    disabled={(!terminal)}
                    onClick={refreshRoutes}>
                    Refresh Routes
                </Button>
            </div>
            {(terminal && activeRoutesState.loaded && activeRoutesState.activeRoutes && activeRoutesState.activeRoutes.length) ?
                <div
                    className="toogle-routes-list"
                    onClick={() => { setIsHideRoutsList(!isHideRoutsList) }}
                >{(!isHideRoutsList) ? <><XGSIcon
                    icon={XGSIcons.faAngleDoubleLeft} /><span> Hide Routes</span></>
                    :
                    <><XGSIcon
                        icon={XGSIcons.faAngleDoubleRight} /><span> Show Routes</span></>}
                </div>
                : ""
            }
            {(!terminal) &&
                <XGSErrorMessage>
                    <div>
                        You do not have assigned terminals.
                        Email <a className="white-link" href="mailto:helpdesk@xgsi.com">helpdesk@xgsi.com</a> to request terminal assignment for your account.
                    </div>
                </XGSErrorMessage>
            }
            {(terminal) &&
                <>
                    {activeRoutesState.loading && <div><Loading isLoading={true} /> Loading Routes...</div>}
                    {activeRoutesState.loadingFailed && <div>Error in getting data</div>}
                    {(activeRoutesState.loaded && activeRoutesState.activeRoutes && !activeRoutesState.activeRoutes.length) ?
                        <span>No Routes to Diplay, please visit <Link
                            to="/dispatch/route/plan"
                        >
                            Plan Route
                        </Link> to create a new Route</span>
                        : ""}
                    {(activeRoutesState.loaded && activeRoutesState.activeRoutes && activeRoutesState.activeRoutes.length) ?
                        <div className="routes-container">
                            {!isHideRoutsList &&
                                <div className="routes-container-sub routes-list-container">
                                    {activeRoutesState.activeRoutes.map((routeObj, routeIdx) => <div
                                        key={`${routeObj.routeId}_${routeIdx}`}
                                        className={`route-box${(routeObj.routeId === selectedRouteId) ? " selected-route" : ""}`}
                                    >
                                        <div className="route-box-header">
                                            <div className="route-box-name"><b>{routeObj.routeName}</b></div>
                                            
                                            <div className="route-box-name-icon route-action"
                                                onClick={() => { loadEditRoute(routeObj.routeId) }}
                                            ><b>
                                                    <XGSIcon
                                                        icon={XGSIcons.faEdit} />
                                                </b></div>
                                        </div>
                                        <div className="route-box-header route-action"
                                            onClick={() => { loadRouteStops(routeObj.routeId) }}
                                        >
                                            <div className="route-box-id">{routeObj.routeId}</div>
                                            <div className={`route-box-status ${getRouteStateClass(routeObj.statusFlag)}`}>{routeObj.statusFlag}</div>
                                        </div>
                                        <div className="route-box-header">
                                            <div className="route-box-trailer">
                                                <span className="route-box-icon">
                                                    <XGSIcon
                                                        icon={XGSIcons.faTruck} />
                                                </span>
                                                {routeObj.trailerNumber}</div>
                                            <div className="route-box-stops-count">
                                                <span className="route-box-icon">
                                                    <XGSIcon
                                                        icon={XGSIcons.faMapPin} />
                                                </span>{routeObj.stopCount}</div>
                                        </div>
                                        <div className="route-box-route-window">
                                            <div className="route-box-ts">
                                                <div className="route-box-date">{getFormattedDate(routeObj.startDate)}</div>
                                                <div className="route-box-time">{getFormattedTime(routeObj.startTime)}</div>
                                            </div>
                                            <div className="route-box-ts">
                                                <div className="route-box-date">{getFormattedDate(routeObj.endDate)}</div>
                                                <div className="route-box-time">{getFormattedTime(routeObj.endTime)}</div>
                                            </div>
                                        </div>
                                        <div className="route-box-header">
                                            <div className="route-box-weight">
                                                <span className="route-box-icon">
                                                    <XGSIcon
                                                        icon={XGSIcons.faBalanceScale} />
                                                </span>
                                                {routeObj.routeWeight.toLocaleString('en', { useGrouping: true })}/{routeObj.trailerCapacity.toLocaleString('en', { useGrouping: true })}
                                                <span className="route-box-weight-unit">{`(lbs)`}</span>
                                            </div>
                                        </div>
                                    </div>)}

                                </div>
                            }
                            <div
                                className={`route-stops-container${isHideRoutsList ? " route-stops-container-expanded" : ""}`}

                            >
                                {(!routeStopsState.loading
                                    && !(routeStopsState.routeStops && routeStopsState.routeStops.length)
                                    && !isShowRouteNotLoadable
                                ) ?
                                    <div className="route-info-msg">
                                        <span className="route-info-msg-icon"><XGSIcon
                                            icon={XGSIcons.faExclamationCircle} /></span>Please select a Route to view Stop Details</div>
                                    : ""}

                                {(isShowRouteNotLoadable) ?
                                    <div className="route-info-msg">
                                        <span className="route-info-msg-icon"><XGSIcon
                                            icon={XGSIcons.faExclamationCircle} /></span>Please wait while the route is getting optimized, hit refresh button after 30 seconds to see the latest status.
                                    </div> :
                                    <RouteStops />
                                }
                            </div>
                            <div className={`routes-container-sub route-map-container${isHideRoutsList ? " route-map-container-expanded" : ""}`}>
                                {(routesStopsState.loaded && routeStopsState.stopsForDispaly && routeStopsState.stopsForDispaly.length) ? <div>
                                    <div className="active-route-controls">
                                        <Button
                                            theme={ButtonThemes.blue}
                                            disabled={(!routesStopsState || !routesStopsState.isRouteEditied || !terminal)}
                                            spinner={updateRouteState.loading}
                                            onClick={saveRouteEdits}>
                                            Save Changes
                                        </Button>
                                        <Button
                                            theme={ButtonThemes.blue}
                                            disabled={(routesStopsState && routesStopsState.isRouteEditied) || (selectedRoute && selectedRoute.statusFlag !== "EDITED")}
                                            spinner={requestRouteOptimizationState.loading}
                                            onClick={pushRouteForOptimization}>
                                            Optimize
                                        </Button>
                                        <Button
                                            theme={ButtonThemes.blue}
                                            disabled={(routesStopsState && routesStopsState.isRouteEditied) || (selectedRoute && (selectedRoute.statusFlag === "PROCESSING" || selectedRoute.statusFlag === "ERROR"))}
                                            spinner={createPicklistFromRouteState.loading}
                                            onClick={createPicklist}>
                                            Create Picklist
                                        </Button>
                                    </div>
                                    <div className="active-route-info-box">
                                        <div className="active-route-info-box__title">Route Info</div>
                                        {getRouteErros()}
                                        {getRouteCost()}
                                    </div>
                                    <div className="active-route-map">
                                        <RouteMap
                                            stops={routeStopsState.stopsForDispaly}
                                            terminalLocation={routeStopsState.terminalLocation} />
                                    </div>
                                </div> : ""
                                }
                            </div>
                        </div>
                        : ""}
                </>
            }
            {(isShowEditRoute && selectedRoute && selectedRoute.statusFlag) &&
                <EditRouteDetails
                    show={isShowEditRoute}
                    routeDetails={selectedRoute}
                    onClose={setIsShowEditRoute}
                />
            }
        </ContentContainer>
    );
});

export default ActiveRoutes;