import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import {
  AnnouncementType,
  EmergencyAlertResponseModel,
  EmergencyAlertRequestModel,
  EmergencyAlertStatusType
} from "../../app/data/emergency-alert/models";
import EmergencyAlertService from "../../app/data/emergency-alert/emergencyAlertService";
import { initialEmergencyAlertState } from "./EmergencyAlertState";

const emergencyAlertService = EmergencyAlertService.getInstance();

export const emergencyAlertSlice = createSlice({
  name: "emergencyAlert",
  initialState: initialEmergencyAlertState,
  reducers: {
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestError = "";
      state.requestCreator = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestError = payload;
    },
    resetErrors: (state) => {
      state.requestFailed = false;
      state.requestError = "";
    },
    storeEmergencyAlert: (state, { payload }) => {
      if (!payload || payload.length === 0) return;
      const draftAlert = payload.find((alert: EmergencyAlertResponseModel) => alert.status === "DRAFT");
      state.draftEmergencyAlert = draftAlert;
      const publishedAlert = payload.find((alert: EmergencyAlertResponseModel) => alert.status === "PUBLISHED");
      state.publishedEmergencyAlert = publishedAlert || null;
    },
    storeDraftEmergencyAlert: (state, { payload }) => {
      if (!payload) return;
      state.draftEmergencyAlert = payload;
    },
    updateEmergencyAlert: (state, { payload }) => {
      if (payload === "UNPUBLISHED") {
        state.publishedEmergencyAlert = null;
      } else if (payload === "PUBLISHED" && state.draftEmergencyAlert) state.publishedEmergencyAlert = {
        ...state.draftEmergencyAlert,
        status: "PUBLISHED"
      };
    }
  }
});

export const {
  requestStarted,
  requestSucceed,
  requestFailed,
  resetErrors,
  storeEmergencyAlert,
  storeDraftEmergencyAlert,
  updateEmergencyAlert
} = emergencyAlertSlice.actions;

export const emergencyAlertSelector = (state: IState) => state.emergencyAlert;

export const getEmergencyAlert = (
  onSuccess: (arg: EmergencyAlertResponseModel | null) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET"));
  const response = await emergencyAlertService.getEmergencyAlert();
  if (response.ok()) {
    dispatch(storeEmergencyAlert(response.data));
    dispatch(requestSucceed());
    onSuccess(response.data.find((alert: EmergencyAlertResponseModel) => alert.status === "DRAFT") || null);
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const saveDraftEmergencyAlert = (
  request: EmergencyAlertRequestModel,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("SAVE"));
  const response = await emergencyAlertService.saveEmergencyAlert(request);
  if (response.ok()) {
    dispatch(storeDraftEmergencyAlert(request));
    dispatch(requestSucceed());
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const changeEmergencyAlertStatus = (
  status: EmergencyAlertStatusType,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("SET_STATUS"));
  const response = await emergencyAlertService.changeEmergencyAlertStatus(status);
  if (response.ok()) {
    dispatch(updateEmergencyAlert(status));
    dispatch(requestSucceed());
    onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const pushEmergencyAlertToAnnouncements = (
  recipientType: AnnouncementType[],
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("PUSH_TO_ANNOUNCEMENTS"));
  const response = await emergencyAlertService.pushEmergencyAlertToAnnouncements(recipientType);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const emergencyAlertReducer = emergencyAlertSlice.reducer;
export default emergencyAlertReducer;
